import React, { Component } from "react";
import {OperationThumbnail} from './thumbnail';
import {ModalInfo} from './modal/info'
import {Select, MultipleSelect,DatePicker, DateStartEndPicker, ModalLarge, CustomButton} from 'widget';
import {Context} from 'context'
import {CategoryThumbnailList} from 'banque/widget/category/list'
import {FilterCategory} from '../category/filter'
import {FilterList} from '../list/list'
import {OperationFilter} from './filter'
import {CategorySelect} from 'banque/widget/category/select';
import {Operation, QuerySet} from 'model/stats/queryset';
import {ExpressionList, ExpressionListModal} from 'banque/widget/expression/expression'
import { ListManager } from "model/manager";
import { AppCommon, modal_register } from 'model/app/app_common';

ListManager.register(Operation, "operations")

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

function get_accounts(){
    var ret = []
    if(Context.data.account!=undefined){
        ret.push(Context.data.account)
    }
    if(Context.data.accounts!=undefined){
        for(var account of Context.data.accounts.get_array()) ret.push(account.id);
    }
    return ret
}

class OperationThumbnailList extends Component {

    constructor(props) {
        super(props);
        var selected = {};
        
        this.props.operations.foreach(x => selected[x.id]=false)
        this.mounted = false;
        this.modal_info = React.createRef()

        this.state = {
            modal_info: false,
            modal_categories: false,
            selected : selected,
            use_filter: null,
            use_filter_list: null,
            sort: null,
            filters: this.props.query || Context.data.query
        };
        this.sort("-date")
    }

    componentDidMount(){
        this.mounted = true;
    }

    sort(key){
        if(key && key.length){

            if(this.state.sort==key){
                key="-"+key;
            }
            else if(this.state.sort==key){
                key=key.substr(1)
            }
            var reverse = false;
            if(key[0]=="-"){
                reverse = true;
                key=key.substr(1);
            }

            this.props.operations.sort(key, reverse);
            if(this.mounted) this.setState({sort: (reverse?"-":"")+key})
        }
    }

    edit_operation(data){
        this.props.operations.edit(data);
        this.setState({
            modal_info: false
        })
    }

    remove_operation(data){
        this.props.operations.remove(data);
        this.setState({
            modal_info: false
        })
    }

    on_close_info_modal(){
        window.on_close_info_modal = this;
        this.setState({
            modal_info: false
        });
    }

    on_filter(data, is_simple=false){
        this.state.is_simple = is_simple;
        this.setState({use_filter: data})
    }

    on_filter_list(data){
        this.setState({use_filter_list: data})
    }

    close_filter(){
        this.setState({use_filter: null})
    }

    close_filter_list(){
        this.setState({use_filter_list: null})
    }

    show_info(props){
        this.modal_info.current.set_operation(props)
       this.setState({
            modal_info: props
       });
    }

    on_select(id){
        this.state.selected[id]=true;
        this.setState({});
    }

    on_unselect(id){
        this.state.selected[id]=false;
        this.setState({});
    }

    select_all(){
        for(var i in this.state.selected){this.state.selected[i]=true;}
        this.forceUpdate();
    }

    unselect_all(){
        for(var i in this.state.selected){this.state.selected[i]=false;}
        this.forceUpdate();
    }

    get_selected(){
        var sel = [];
        for(var i in this.state.selected){
            if(this.state.selected[i]) sel.push(i);
        }
        return sel;
    }

    get_selected_operations(){
        // this.state.selected est un dict,
        // keys: les clés (id) dont les valeur sont à vrai
        var keys = Object.keys(this.state.selected || {}).filter(x => this.state.selected[x])

        // les objets operation associés
        var selecteds = this.props.operations.select(keys)

        return selecteds
    }

    sum_selected(){
        var x = this.get_selected_operations().reduce((p, c) => p+c.montant, 0);
        return Math.round(x*100)/100;
    }

    _show_sort_icon(key){
        if(key == this.state.sort){
            return (<i className="bi bi-caret-up operation-table-sort-indicator"></i>)
        }else if("-"+key == this.state.sort){
            return (<i className="bi bi-caret-down operation-table-sort-indicator"></i>)
        }
        return null;
    }

    refresh(x){
        var selected = {};
        this.props.operations.foreach(x => selected[x.id]=false)
        this.setState({selected: selected})
    }

    render() {
        var liste = [];
        this.props.operations.foreach(x => liste.push(
            <OperationThumbnail {...x} parent={this} max_libelle_length={this.props.max_libelle_length} key={x.id}
            selected={this.state.selected[x.id]} />))

        var selected = this.get_selected()
        var selected_ops = this.get_selected_operations()
        var summary = selected.length?(
            <div className="operation-summary">
                <h4>{selected.length} opérations</h4>
                <div className="operation-summary-info">Somme : {this.sum_selected()} €</div>
                <div className="operation-summary-info">Moyenne : {Math.round(this.sum_selected()/selected.length*100)/100} €</div>
                <div>
                    <a className="" onClick={this.select_all.bind(this)}>Tout sélectionner</a>
                    <a className="" onClick={this.unselect_all.bind(this)}>Tout desélectionner</a>
                </div>
            </div>):null;

        var filter_modal = null, filter_list_modal = null;
        
        if(this.state.use_filter!=null){
            filter_modal=(<FilterCategory 
                                onClose={this.close_filter.bind(this)} 
                                operation={this.state.use_filter} 
                                selected={selected_ops.length?selected_ops:null}
                                parent={this} simple={this.state.is_simple} />)
        }

        if(this.state.use_filter_list!=null){
            filter_list_modal=(<FilterList 
                                onClose={this.close_filter_list.bind(this)}
                                operation={this.state.use_filter_list} 
                                parent={this} 
                                selected={selected_ops.length?selected_ops:null}
                                simple={true} />)
        }


        var heading = [];
        var accounts = get_accounts();
        for(var i in accounts){
            var account = accounts[i];
            //heading.push(<h5 key={"h5"+i}>{account.name} : {account.solde} €</h5>)
        }
        
        return (
            <div>
            <OperationFilter disableFilter={this.props.disableFilter} value={this.state.filters} onValidate={this.refresh.bind(this)} operations={this.props.operations}/>
            {filter_modal}
            {filter_list_modal}
            {summary}
            {heading}
            <table className="table table-striped">
                <thead>
                    <tr className="operation-header">
                        <th scope="col"></th>
                        <th scope="col">
                            <a onClick={this.sort.bind(this, "date")}>{this._show_sort_icon("date")}Date</a>
                        </th>
                        <th scope="col">
                            <a onClick={this.sort.bind(this, "libelle")}>{this._show_sort_icon("libelle")}Libellé</a>
                        </th>
                        <th scope="col">
                            <a onClick={this.sort.bind(this, "montant")}>{this._show_sort_icon("montant")}Montant</a>
                        </th>
                        <th scope="col">
                            <a onClick={this.sort.bind(this, "category")}>{this._show_sort_icon("category")}Catégories</a>
                        </th>
                        <th scope="col">
                            <a onClick={this.sort.bind(this, "action")}>Actions</a>
                        </th>
                    </tr>
                </thead>
                <tbody>
                {liste}
                </tbody>
          </table>
          <ModalInfo operation={this.state.modal_info}
                    parent={this}
                    ref={this.modal_info}
                    onClose={this.on_close_info_modal.bind(this)}
                    />
          </div>)
    }
}


function OperationThumbnailListModal(props){
    var ops = new ListManager(props.operations, Operation);

    return (<ModalLarge
        isOpen={true} >
        
        <div className="modal-header">
            <h5>{props.title}</h5>
            <button type="button" className="btn-close" onClick={props.onClose}></button>
        </div>
        <div className="modal-body">
            <OperationThumbnailList operations={ops} disableFilter={props.disableFilter} query={props.query} />
        </div>
        <div className="modal-footer">
            <CustomButton onClick={props.onClose}>Fermer</CustomButton>
        </div>
    </ModalLarge>)
}

function global_modal(data, key, onclose){
    if(Array.isArray(data)){
        data = {
            operations: data,
            account: get_accounts()[0].id
        }
    }
    return (<OperationThumbnailListModal 
        onClose={onclose}
        key={key}
        disableFilter={true}
        {...data}
        />)
}

modal_register("operations", global_modal)


export {
    OperationThumbnailList, OperationThumbnailListModal
};
import React, { Component } from "react";
import {CategoryThumbnail} from './thumbnail';
import {CustomButton, Validation, ModalMedium} from 'widget'
import './css/category.css'
import {Api} from "banque/api/api"

import {CreateCategoryModal} from "banque/widget/category/create"

class CategoryThumbnailList extends Component {

  constructor(props) {
    super(props);
    this.state = {
        categories: this.props.categories || window.data.categories,
        show_modal: null,
        remove: null,
        current_category: {}
    };
  }

/*
  on_change(data){
     for(var i in this.state.categories){
        if(this.state.categories[i].id == data.id){
            Object.assign(this.state.categories[i], data);
            this.setState({})
            break;
        }
     }
  }*/

    on_edit(data){
        this.setState({show_modal: true, current_category: data.state})
    }

    on_send(data, x ,y, z){
        var self = this;
        if(data.id && data.id != ""){
            var cat = this.state.categories.get(data.id);
            if(cat){
                Api.edit_category(data, function(_){
                        self.state.categories.edit(cat);
                        self.state.show_modal=false;
                        self.setState({});
                    })
            }
        }else{
            Api.create_category(data, function(data){
                self.state.show_modal=false;
                if(data && data.create && data.create.category){
                    for(var i in data.create.category){
                        self.state.categories.add(data.create.category[i]);
                    }
                }
                self.setState({});

            })
        }

    }

    on_remove(data){
        this.setState({remove: data})
    }

    on_remove_validated(){
        var data = this.state.remove;

        var self = this;
        Api.remove_category(data, function(data){
            self.state.show_modal=false;
            if(data && data["delete"] && data["delete"].category){
                var cats = data["delete"].category;
                for(var i=0; i<cats.length; i++){
                    self.state.categories.remove(cats[i])
                }
            }
            self.setState({remove: null});
        })
    }

    on_remove_cancel(){
        this.setState({remove: null})
    }

    add_category(){
        this.setState({show_modal: true, current_category: {}})
    }

    on_modal_close(){
        this.setState({show_modal: false, current_category: {}})
    }

  render() {
    var liste = [];
    if(Array.isArray(this.state.categories)){
        for(var i in this.state.categories){
            liste.push(<CategoryThumbnail {...this.state.categories[i]} parent={this} key={this.state.categories[i].id} />)
        }
    }else{
        this.state.categories.foreach(x => liste.push(<CategoryThumbnail {...x} parent={this} key={x.id} />))
    }






    var validate_remove = this.state.remove!=null?(<Validation
            title="Supprimer"
            onCancel={this.on_remove_cancel.bind(this)}
            onValidate={this.on_remove_validated.bind(this)}> Voulez vous vraiment supprimer cette catégorie ?
            </Validation>):null;
    return (
        <div>
        <CustomButton onClick={this.add_category.bind(this)} > Ajouter une catégorie </CustomButton>
        {this.state.show_modal?<CreateCategoryModal value={this.state.current_category} parent={this} show={this.state.show_modal} onClose={this.on_modal_close.bind(this)}/>:null}
        {validate_remove}
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Nom</th>
              <th scope="col">Description</th>
              <th scope="col">Priorité</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
          {liste}
          </tbody>
      </table>
      </div>)
  }
}


function CategoryManagerModal(props){
    return (
        <ModalMedium
        isOpen={props.isOpen!=undefined?props.isOpen:true}
        onClose={props.onClose}
        >
            <div className="modal-header">
                <h5>Gérer les catégories</h5>
                <button type="button" className="btn-close" onClick={props.onClose}></button>
            </div>
            <div className="modal-body">
                <CategoryThumbnailList />
            </div>

            <div className="modal-footer">
                <CustomButton onClick={props.onClose}>Fermer</CustomButton>
            </div>
        </ModalMedium>
    );
}


export {
    CategoryThumbnailList,
    CategoryManagerModal
};
import React, { Component } from "react";
import { Form, Field, Entry, Integer, Float, Button, CustomButton, Modal, ModalSmall } from "widget";
import "./css/create.css";
import {ModalInfo} from "banque/widget/operation/modal/info";
import {Context} from 'context'
import {PatternEditor} from "./create"
import {Api} from "banque/api/api"

class FilterCategory extends Component {
    constructor(props){
        super(props);
        this.state = {
            category: null,
            value: {}
        }
    }

    on_choose(cat){
        var self = this;
        if(this.props.simple){
            Api.operation_add_category(this.props.operation.id, cat.id, function(data){
                self.props.parent.close_filter();
                Context.data.operations.edit(data);
            })
        }else{
            this.state.category = cat;
            this.setState({
                category: cat,
                value: {pattern: this.get_regex(this.props.operation)}
            })
        }
        

    }

    on_close_pattern(){
        this.setState({
            category: null,
            value: {}
        })
    }


    get_regex(op){
        var str = op.libelle;
         var last_space = str.lastIndexOf(" ");
         if(last_space>=0){
            str = str.substr(0, last_space)
            return str.trim()
         }
         return str
    }

    set_value(data){
        var self = this;
        var cat = Context.data.categories.get(this.state.category.id);
        if(cat!=null){
            cat.pattern_set.push(data);
            Api.edit_category(cat, function(_){
                self.setState({category: null})
            })
        }else{
            console.error("Erreur la catégorie "+this.state.category.id+" est introuvable", this.state.category)
        }
    }



    render(){
        var categories = [];
        var pattern_editor = null;
        if(this.state.category!=null){
            pattern_editor=(<ModalSmall
            title="Nouveau pattern"
            onClose={this.on_close_pattern.bind(this)}
            isOpen={true}
            >
                <div className="modal-header">
                    <h5>Créer un pattern</h5>
                    <button type="button" className="btn-close" onClick={this.on_close_pattern.bind(this)}></button>
                </div>
                <div className="modal-body">
                    <PatternEditor value={this.state} parent={this} category={this.state.category.id} />
                </div>

                <div className="modal-footer">
                    <CustomButton onClick={this.on_close_pattern.bind(this)}>Fermer</CustomButton>
                </div>

            </ModalSmall>

               )
        }

        Context.data.categories.foreach(x =>
            categories.push((<tr key={x.id}>
                <td>{x.name}</td>
                <td><i className="bi bi-plus filter-liste-icons" onClick={this.on_choose.bind(this, x)}/></td>
            </tr>))
        )

        return (
            <ModalSmall
            title="Choisir une catégorie"
            onClose={this.props.onClose}
            isOpen={true}
            >


            <div className="modal-header">
                <h5>Choisir une catégorie</h5>
                <button type="button" className="btn-close" onClick={this.props.onClose}></button>
            </div>
            <div className="modal-body">
                {pattern_editor}
                <table className="table table-striped">
                    <tbody>
                        {categories}
                    </tbody>
                </table>
            </div>
              <div className="modal-footer">
                 <CustomButton onClick={this.props.onClose}>Annuler</CustomButton>
              </div>

            </ModalSmall>
        )

    }

}

export {
    FilterCategory
};
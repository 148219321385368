import { Context } from "context";
import { Component } from "react";
import { MultipleSelect, Select } from "./select";


class AccountSelect extends Component {
    constructor(props){
        super(props);

    }

    _get_options(){
        var ret = {}
        for(var acc of Context.data.accounts.get_array()){
            ret[acc.id] = acc.name
        }
        return ret;
    }

    render(){
        if(this.props.simple){
            return (
                <Select options={this._get_options()}
                                selected={this.props.selected || this.props.value}
                                className={this.props.className || "btn-category"}
                                {...this.props} />
            )
        }else{
            var val = (this.props.value===undefined?(this.props.selected===undefined?[]:this.props.selected):this.props.value)
            return (
                <MultipleSelect options={this._get_options()}
                                selected={val}
                                className={this.props.className || "btn-category"}
                                {...this.props} />
            )
        }
        
    }

}

export {
    AccountSelect
}

import { Api } from "banque/api/api";
import { GraphEditor } from "banque/widget/graph/editor";
import { GraphThumbnail, GraphTumbnailOption } from "banque/widget/graph/thumbnail";
import React from "react";
import { Button } from "widget";
import { dmyDate } from "widget/common";
import { Tile } from "../tile";


class TileGraph extends Tile {

    init(){
        this.graph_ref = React.createRef();
    }

    on_date_left(){
        this._on_date_change(this.state.offset+1)
    }

    on_date_right(){
        this._on_date_change(this.state.offset-1)
    }

    _on_date_change(offset){
        var self = this;
        this.state.offset = offset;
        var g = Object.assign({}, this.props.content.graph)
        delete g.operations;
        Api.graph_translate(g, offset, function(data){
            self.props.content.graph = data;
            self.graph_ref.current.set_graph(data, offset, false);
            self.setState({})
        })
    }

    _get_date_selector(){
        var [start, end] = this.props.content.graph.range;
        var left = null, right = null;
        if(start){
            start = dmyDate(start);
            left = (<Button className="graph-thumbnail-date-select-button" onClick={this.on_date_left.bind(this)}>
                <i className="bi bi-chevron-left"></i>
            </Button>);
        }else{
            start = "-";
        }
        if(end && left){
            end = dmyDate(end);
            right = (<Button className="graph-thumbnail-date-select-button" onClick={this.on_date_right.bind(this)}>
                <i className="bi bi-chevron-right"></i>
            </Button>);
        }else{
            end = "-";
            left = null;
        }
        
        
        
        return (<div key="dates">
            {left}
            <span>du {start} au {end}</span>
            {right}
        </div>)
    }


    render_options(){
        return [this._get_date_selector(), (<GraphTumbnailOption
            parent={this} 
            key={"options"}
            headless={true}
            type={this.props.content.graph.type} 
            value={this.props.content.graph.options}
            force_close={false}
            options={this.props.content.graph.options}/>)]
    }

    static render_editor_content(editor){
        return (
            <GraphEditor
                holder = {editor.props.holder}
                value={editor.state.value.graph} 
                hideName={true}
                hideButton={true}
                onChange={editor.on_change.bind(editor, "graph")}
                onClose={editor.props.onClose}/>
        )
    }

    render_content(){
        return (
            <GraphThumbnail
                    holder={this} 
                    parent={this} 
                    value={this.props.content.graph} 
                    headless={true}
                    update={this.props.content.update}
                    ref={this.graph_ref}
                    />)
    }

    on_refresh(graph_modif){
        var data = Object.assign(this.props.content.graph, graph_modif || {})
        this.graph_ref.current.set_graph(data);
    }

    static val(value){
        var v = Object.assign({},value);
        if(v.type==Tile.TYPE_GRAPH){
            var graph = {};
            for(var [key, value] of Object.entries(v.graph)){
                const fields = ["id", "account", "content", "name", "type", "multiple", "user_id", "options", "period"]
                if(fields.indexOf(key)>=0){
                    graph[key] = value;
                }
            }
            v.graph = graph;
        } else {
            v.graph = null;
        }
        return v;
    }

}

Tile.register_tile(Tile.TYPE_GRAPH, {
    classe: TileGraph,
    label: "Graphs"
})

export {
    TileGraph
}
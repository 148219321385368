import { CategorySelectIncludeExclude } from "banque/widget/category/select";
import { MultipleOperationTypeSelect } from "banque/widget/operation/filter";
import { cast_float_montant } from "model/common/utils"
import { CheckBox, Icon, Select } from "widget";
import { CategoryIncludeExcludeField, CheckBoxField, IntField, MultipleAccountField, OperationTypeIncludeExcludeField, SimpleAccountField } from "widget/autorenderer/autorenderer";
import { Filters } from "../utils/filter";
import { Info } from "./info"




class InfoRestant extends Info {

    filter(data){
        data = Filters.current_month(true, data);
        data = Filters.virtual(this._config.virtual, data);
        data = Filters.operation_types(this._config.operation_types, data);
        return Filters.categories(this._config.filter_categories, data);
    }

    init(data){
        var revenu = this._config.revenu || 0;

        var montant = cast_float_montant(data.sum("montant"));
        this.set_operations(data);
        var val = cast_float_montant(revenu+montant);
        if(revenu+montant>0){
            this.content = <span style={{color: "#00ff00"}}>{val} €</span>
        }else{
            this.content = <span style={{color: "#ff0000"}}>{val} €</span>
        }
        this.title = "Montant du mois restant"+(!this._config.virtual?" (hors provisions)":"");
        this.sub_title = (-montant)+"€ dépensé ce mois-ci";
    }

    get_icon(){
        return <div><Icon icon="euro" style={{fontSize: "60pt"}}/></div>
    }

    static get_options(){
        return [
            new MultipleAccountField("Compte", "Compte", null),
            new IntField("revenu", "Revenu / mois", 0),
            new CheckBoxField("virtual", "Opération virtuelles", true),
            new CategoryIncludeExcludeField("filter_categories", "Catgégories", {}),
            new OperationTypeIncludeExcludeField("operation_types", "Types d'opération", {}),
        ];
    }
}

Info.register("restant", {
    classe: InfoRestant,
    label: "Restant"
})

export {
    InfoRestant
}
import { Dashboard } from "banque/widget/dashboard/dashboard";
import { DashboardMenu } from "banque/widget/dashboard/menu";
import { Context } from "context";
import { page_register } from "model/app/app_common";
import { ListManager } from "model/manager";
import React, { Component } from "react";
import { ReactiveComponent } from "widget/base/base";
import "./dashboard.css"

class DashboardDivider extends Component{
    constructor(props){
        super(props);
        this.state = {

        }
    }
    render(){
        return <div className="dashboards-divider"></div>
    }
}


class DashboardList extends ReactiveComponent {
    constructor(props){
        super(props);
        var dash = Context.data.dashboards;
        var curr = dash;
        while(curr.type=="node"){
            curr = curr.content[0]
        }

        this.state = {
            current: null,
            dashboards: dash,
            current: curr.content,
            fit_screen: null
        }
        this.current_dashboard = React.createRef();
    }


    on_menu_click(data){
        this.state.current = data;
        this.setState({})
    }

    set_fit_screen(x){
        this.state.fit_screen=x;
        this.setState({})
    }

    on_remove(data){
        if(this.state.current && data.id==this.state.current.id){
            this.state.current = null;
            this.setState({})
        }
    }

    render_mobile(dashboard){

        return (<div className="dashboard-list">
                {dashboard}
            </div>
        );
    }

    render_desktop(dashboard){
        return (<div className="dashboard-list">
                <DashboardMenu 
                    dashboards={this.state.dashboards} 
                    parent={this}
                    onClick={this.on_menu_click.bind(this)}/>
                <DashboardDivider />
                {dashboard}
                
            </div>
        );
    }


    render(){
        var dashboard = this.state.current?(<Dashboard 
            dashboard={this.state.current} 
            parent={this} 
            key={this.state.current.id}
            ref={this.current_dashboard}/>):null
        if(this.state.fit_screen){
            return (<div className="dashboard-list">
            {dashboard}
            </div>)
        }else{
            return this.smart_render(dashboard)
        }
        
    }
}

page_register("dashboards",{
    page: (() => <DashboardList {...Context.data} />)
  })


export {
    DashboardList
}
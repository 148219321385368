import React, { Component } from "react";
import {CustomButton} from "widget/base/button";
import {Entry, Password, Hidden} from "widget";

import './list_accounts.css'

import {AccountThumbnailList} from 'banque/widget/account/list'
import { Context } from "context";
import { page_register } from "model/app/app_common";

class ListAccounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    var error = window.data.error?(<p>{window.data.error}</p>):null;

    return (<AccountThumbnailList {...this.props}/>)
  }

}

page_register("list_accounts",{
  page: (() => <ListAccounts {...Context.data} />)
})


export { ListAccounts };
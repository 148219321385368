import { Api } from "banque/api/api";
import { show_error, show_validation } from "model/app/app_common";
import React, { Component } from "react";
import { CustomButton, Entry, ModalMedium, Select, ModalCloseButton, Integer, Button, FormRenderer } from "widget";
import { AutoOptions, CheckBoxField, IntField } from "widget/autorenderer";
import { AbsComponent } from "widget/base/base";
import { GraphEditor } from "../graph/editor";
import { GraphThumbnail, GraphTumbnailOption } from "../graph/thumbnail";
import "./css/tile.css"



const TileEditorOptions = [
    new CheckBoxField("interactive", "Interractif", false),
    new CheckBoxField("fullscreen", "fullscreen", true)
]

const TileEditorPlacement = [
    new IntField("offset", "Décalage / 12", 0),
    new IntField("width", "Taille / 12", 3),
]

class Tile extends Component {
    static _tiles_registered = {}
    static TYPE_GRAPH="graph"
    static TYPE_TEXT="text"
    constructor(props){
        super(props);
        this.state={
            is_fit_size: false,
            is_full_screen: false,
            is_edit: false,
            is_show_options: false,
            offset: 0
        }
        this.init()
    }

    static register_tile(type, obj){
        Tile._tiles_registered[type]=obj;
    }

    static get_tile(data){
        if(data.type) data= data.type;
        var classe = Tile._tiles_registered[data];
        if(classe === undefined){
            console.error("Tile '"+data+"' inconnu", "Le tile '"+data+"' est inconnu")
            return {
                classe: null
            };
        }
        return classe
    }

    static get_tiles_labels(data){
        var ret = {}
        for(var [key, obj] of Object.entries(Tile._tiles_registered)){
            ret[key] = obj.label || key;
        }
        return ret;
    }

    init(){

    }

    _class_names(){
        if(this.props.fit_screen){
            return  "tile-border col-md-12 "
        }

        var place = Object.assign({
            offset: 0,
            width: 6
        }, 
            this.props.content.placement || {})
        var ret = "tile-border col-md-"+place.width+" ";
        if(place.offset){
            ret+="offset-md-"+place.offset;
        }
        return ret;
    }

    clean_update(){
        this.props.content.update = false;
    }


    on_edit(){
        this.state.is_edit = true;
        this.setState({})
    }

    on_remove(){
        var self = this;
        show_validation("Êtes vous sur ?", "Voulez vous vraiment supprimer la tuile '"+
        self.props.content.name+"' ?", function(){
                Api.dashboard_tile_remove(self.props.dashboard, self.props.content.id, function(){
                    self.props.parent.on_remove_tile(self.props.content)
                })
            })
    }

    on_toggle_fullscreen(){
        this.state.is_full_screen = !this.state.is_full_screen;
        this.setState({})
    }

    on_toggle_fit_size(){
        this.state.is_fit_size = !this.state.is_fit_size;
        this.props.parent.set_fit_screen(this.state.is_fit_size?this.props.content:null);
    }

    on_toggle_show_options(){
        this.state.is_show_options = !this.state.is_show_options;
        this.setState({})
    }

    on_tile_change(value){
        Object.assign(this.content, value || {})
        this.setState({})
    }

    on_edit_close(){
        this.state.is_edit = false;
        this.setState({})
    }
    

    render_modals(){
        return (<TileEditorModal 
            holder = {this}
            dashboard = {this.props.dashboard}
            value={this.props.content} 
            parent={this.props.parent}
            key={"tile_editor_1"}
            onChange={this.on_tile_change.bind(this)}
            onClose={this.on_edit_close.bind(this)} />)
    }

    render_options(){
        return null;
    }

    render_content(){
        return null;
    }
    


    render(){
        var actions = [];
        var options = null;
        var modals = null;
        if(this.props.content.options && this.props.content.options.interactive){
            actions.push(<a  key={"tha_interactive"} className="tile-header-action"  onClick={this.on_toggle_show_options.bind(this)}>
                    <i className="bi bi-gear"/></a>)
        }

        if(this.props.content.options && this.props.content.options.fullscreen){
            
            if(this.state.is_full_screen){
                actions.push(<a  key={"tha_fit_screen"} className="tile-header-action"  onClick={this.on_toggle_fit_size.bind(this)}>
                    <i className="bi bi-fullscreen-exit"/></a>)
                actions.push(<a  key={"tha_fullscreen"} className="tile-header-action"  onClick={this.on_toggle_fullscreen.bind(this)}>
                    <i className="bi bi-fullscreen"/></a>)
            }else{
                actions.push(<a  key={"tha_fullscreen"} className="tile-header-action"  onClick={this.on_toggle_fit_size.bind(this)}>
                    <i className="bi bi-aspect-ratio"/></a>)
            }
        }

        if(this.state.is_edit){
            modals = this.render_modals()
        }

        if(this.state.is_show_options){
            options = this.render_options();
        }

        return (<div className={this._class_names()}>
            <div className="tile">
                {modals}
                <div className="tile-header">
                    <span>{this.props.content.name}</span>
                    <li className="nav-item dropdown tile-header-action">
                        <a className="nav-link" href="#"  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-list"/>
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><a className="dropdown-item" onClick={this.on_edit.bind(this)}>Editer</a></li>
                            <li><a className="dropdown-item" onClick={this.on_remove.bind(this)}>Supprimer</a></li>
                        </ul>
                    </li>
                    {actions}
                </div>
                <div className="tile-content">
                    {options}
                    {this.render_content()}
                </div>
                
            </div>
        </div>)
    }

    static render_editor_content(editor){
        return null;
    }
}


class TileEditor extends AbsComponent {
    constructor(props){
        super(props);
        var value = props.value || {};
        this.state = {
            value: {
                id: value.id || null,
                name: value.name || "",
                type: value.type || "graph",
                placement: value.placement || {},
                content: value.content || {},
                options: value.options || {},
                order: value.order || 0,
                graph: value.graph || {}
            }
        }
        this.options = new AutoOptions(TileEditorOptions)
        this.placement = new AutoOptions(TileEditorPlacement)
    }

    val(){
        var tile = Tile.get_tile(this.state.value.type)
        if(tile.classe && tile.classe.val) {
            return tile.classe.val(this.state.value)
        }
        var v = Object.assign({},this.state.value);
        return v;
    }

    on_validate(on_close, send=true){
        var self = this;
        var content = this.val();
        if(send){
            Api.dashboard_tile_update_or_create(this.props.dashboard, content, function(tile){
                self.state = tile;
                self.props.parent.on_update_tile(tile);
                on_close && on_close();
                self.setState({});
            })
        }else{
            this.on_local_validate(false);
            on_close && on_close();
            self.setState({});
        }
    }

    on_change(key, value, root=null){
        console.error("========", key, value, root);
        (root || this.state.value)[key] = value;
        this.setState({})
        this.props.onChange && this.props.onChange(this.val())
    }

    render(){
        var tiles_types = Tile.get_tiles_labels();
        var tile = Tile.get_tile(this.state.value.type);
        if(tile.classe == null) return null;
        var content = tile.classe.render_editor_content(this);
        return (<div>
            <div className="tile-editor-field">
                <span className="col-auto field-label">
                    <label className="col-form-label">Nom de la tuile</label>
                </span>
                <Entry value={this.state.value.name} onChange={this.on_change.bind(this, "name")}/>
            </div>
            <div className="tile-editor-field">
                <span className="col-auto field-label">
                    <label className="col-form-label">Type de tuile</label>
                </span>
                <Select selected={this.state.value.type} options={tiles_types} onChange={this.on_change.bind(this, "type")}/>
            </div>

            <div className="tile-editor-field">
                <span className="col-auto field-label">
                    <label className="col-form-label">Ordre</label>
                </span>
                <Integer value={this.state.value.order}  onChange={this.on_change.bind(this, "order")}/>
            </div>
            
            <div className="tile-editor-field">
                <span className="col-auto field-label">
                    <label className="col-form-label">Options</label>
                </span>
                <div className="tile-editor-sub">
                     <FormRenderer fields={TileEditorOptions} value={this.state.value.options}  onChange={this.on_change.bind(this, "options")} />
                </div>
            </div>
            
            <div className="tile-editor-field">
                <span className="col-auto field-label">
                    <label className="col-form-label">Placement</label>
                </span>
                <div className="tile-editor-sub">
                    {this.placement.render(this.state.value.placement, this.on_change.bind(this, "placement"))}
                </div>
            </div>
            <div  className="tile-editor-field">

                <span className="col-auto field-label">
                    <label className="col-form-label">{tile.label}</label>
                </span>
                <div className="tile-editor-sub">
                    {content}
                </div>
            </div>
            
        </div>);
    }


}


class TileEditorModal extends Component {
    constructor(props){
        super(props);
        this.tile = React.createRef();
    }

    on_validate(){
        this.tile.current.on_validate(this.props.onClose)
    }

    on_local_validate(){
        this.props.holder.on_refresh(this.props.value)
    }   

    render(){
        var label = "Créer";
        var buttons = []
        if(this.props.value.id){
            label = "Modifier et enregistrer";
            //buttons.push(<CustomButton key={"modify"}  onClick={this.on_local_validate.bind(this)}>Modifier</CustomButton>)
        }else{

        }
        return (
            <ModalMedium
            isOpen={this.props.isOpen!=undefined?this.props.isOpen:true}
            onClose={this.props.onClose}
            >
                <div className="modal-header">
                    <h5>Edition du tile</h5>
                    <button type="button" className="btn-close" onClick={this.props.onClose}></button>
                </div>
                <div className="modal-body">
                    <TileEditor 
                        parent={this.props.parent}
                        dashboard={this.props.dashboard}
                        holder={this.props.holder} value={this.props.value} ref={this.tile} />
                </div>
    
                <div className="modal-footer">
                    <CustomButton  onClick={this.on_validate.bind(this)}>{label}</CustomButton>
                    {buttons}
                    <ModalCloseButton onClick={this.props.onClose}>Fermer</ModalCloseButton>
                </div>
            </ModalMedium>
        );
    }
}


export {
    Tile,
    TileEditorModal
}
class Filters {
    static current_month(enable, data){
        if(!enable) return data;
        var date_start = new Date();
        date_start = new Date(date_start.getFullYear(), date_start.getMonth(), 1, 0, 0, 0, 0);
        return data.filter(x => (x.get_date() >= date_start));
    }
    static categories(filtered_categories, data){
        return data.filter_categories(filtered_categories.categories || [], filtered_categories.include);
    }
    static virtual(disable_virtual, data){
        if(disable_virtual) return data;
        return data.filter(x => !x.virtual);
    }
    static operation_types(operation_types, data){
        if(operation_types===undefined) return data;
        return data.filter_operation_types(operation_types.operation_types || [], operation_types.include);
    }
}


export {
    Filters
}
const { ListManager } = require("model/manager");

export class Operation {
    constructor(x){
        for(const [key, val] of Object.entries(x)){
            this[key]=val;
        }
        this.category = this.category.length?this.category[0]:null;
        this._date = new Date(Date.parse(this.date.replaceAll("-","/")))
    }

    get_date(){
        return this._date
    }

}

ListManager.register(Operation, "operations")
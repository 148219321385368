const { data } = require("jquery");
const { Component } = require("react");
const { Line } = require("react-chartjs-2");
const { IntField, CategoryIncludeExcludeField, SelectField } = require("widget/autorenderer");
const { OperationTypeIncludeExcludeField } = require("widget/autorenderer/autorenderer");
const { DataEntry } = require("../queryset");
const { Value, Graph, colors_alpha } = require("./base");
const { HabitudesUtils } = require("./habitudes");


class Objectifs extends Graph {
    init(){
    }

    on_click(graph, evt){
        var x = this.get_dataset_by_event(evt);
        if(x===null) return
        graph.props.parent.show_operations(x.operations)
    }


    compute_data(){
        var datasets = [];
        var start = new Date()
        var produit = this.options.produit.indexOf("produits")>=0;
        var charges = this.options.produit.indexOf("charges")>=0;
        var end_of_month = new Date(start)
        end_of_month.setMonth(end_of_month.getMonth()+1);
        end_of_month.setDate(0)
        var nb_jours = end_of_month.getDate();
        var operations = []
        var suffix = "/"+(""+(start.getMonth()+1)).padStart(2,0)+"/"+start.getFullYear();
        
    
        var habitudes = this.get_utils(HabitudesUtils);
        var start = habitudes.habitudes.start
        
        var acc = parseInt(this.options.start)+ this.options.revenu;
        for(var i=0; i<nb_jours; i++){
            var date = (""+(i+1)).padStart(2,0)+suffix;
            var val = new Value(acc, [], date);
            acc+=parseInt(this.options.daily);
            operations[i] = val;
        }

        datasets.push({
            label:  "Objectifs",
            data: operations,
            backgroundColor: colors_alpha[3],
            borderColor: colors_alpha[3],
            tension: 0.3
        })
        var data = habitudes.get_reality();
        datasets.push({
            label:  "Réalité",
            data: habitudes.get_reality(),
            backgroundColor: colors_alpha[4],
            borderColor: colors_alpha[4],
            tension: 0.3
        })


        var acc_data = habitudes.mixup_data().acc_data;
        acc_data[start.getDate()-1] = data.length?data[start.getDate()-1].value:0;
        data = habitudes.mixup_data(acc_data, start.getDate(), null, 0).data
        datasets.push({
            label:  "Pévisions",
            data: data,
            backgroundColor: colors_alpha[5],
            borderColor: colors_alpha[5],
            tension: 0.3
        })
        console.log("datasets", datasets);
        return {
            datasets: datasets
        };
    }
}

class ObjectifsGraph extends Component {
    render() {
        return (
          <div>
            <Line id={"graph-"+this.props.graph.id}
                options ={{
                responsive: true, 
                scales: {  x: { stacked: true }, y: { stacked: false}},
                onClick: this.props.graph.on_click.bind(this.props.graph, this),
                parsing: {yAxisKey: 'value', xAxisKey: 'label'},
              }}
              data={this.props.data}
            />
          </div>
        );
      }
}



Graph.register("objectifs", {
    data: Objectifs,
    label: "Objectifs",
    graph: ObjectifsGraph,
    options: [
        new IntField("revenu", "Revenu", 0),
        new SelectField("produit", "Produit/charges", {
            charges: "Charges",
            charges_produits: "Produits et charges",
        }, "charges_produits"),
        new CategoryIncludeExcludeField("filter_categories", "Catégories", {
            categories: [], include: false
        }),
        new IntField("start", "Charges d'initilisation", -100),
        new IntField("daily", "Charges journlières", -50),
        new OperationTypeIncludeExcludeField("operation_types", "Types d'opération", {}),
    ]
})
import { Options } from "banque/model/options";
import { CategorySelectIncludeExclude } from "banque/widget/category/select";
import { OperationTypeSelectIncludeExclude } from "banque/widget/operation/filter";
import { Context } from "context";
import { show_error } from "model/app/app_common";
import { Component } from "react";
import { AccountSelect } from "widget";
import { Entry, Float, Integer } from "widget/base/entry";
import { Select } from "widget/base/select";


class OptionField {
    constructor(name, type, label, data, default_value=null, handlers = null){
        this.name = name;
        this.type = type;
        this.label = label;
        this.data = data;
        this.default_value = default_value
        this._on_change = null;
        this._cast_from_js = x => x;
        this._cast_to_js= x => x;
        this._elem = null;
        this._args = {};
        this._content = null;
        this._key_label = "placeholder";
        this._key_value = "value";
        this._key_data = null;
        this._handlers = handlers || {};
    }

    set_on_change(onChange){
        this._on_change = onChange
    }


    on_change(value){
        if(this._on_change){
            value = this._cast_to_js(value)
            this._on_change(this.name, value);
            if(this.handlers && this.handlers.onChange) this.handlers.onChange(this.name, value)
        }
    }

    set_value(val){
        this.on_change(val)
    }

    render(value){

        value = this._cast_from_js(value);
        return this.do_render(value);
    }

    do_render(value){
        var Elem = this._elem;
        if(!Elem){
            console.error("Le champ auto '"+this.name+"' n'a pas de type React dans elem");
            return null;
        }
        if(this._key_data) this._args[this._key_data] = this.data;
        if(this._key_value) this._args[this._key_value] = value;
        if(this._key_label) this._args[this._key_label] = this.label;
        this._args.key = this.type+"-"+this.name;
        this._args.onChange = this.on_change.bind(this);
        if(this.label){
            return (
                <div key={this.name} style={{display: "flex"}}><span style={{
                    paddingTop : "5px",
                    paddingRight : "10px",
                    fontSize : "12pt"

                }}>{this.label}</span><Elem  {...this._args}></Elem></div>
            )
        }else{
            return (<Elem key={this.name}  {...this._args}></Elem>)
        }
        
    }
}

class SelectField extends OptionField {
    constructor(name, label, data, default_value, hanlders){
        super(name, "select", label, data, default_value || Object.keys(data)[0], hanlders);
        this._key_value = "selected";
        this._elem = Select;
        this._key_data = "options";
    }
}

class CategoryIncludeExcludeField extends OptionField {
    constructor(name, label, default_value, hanlders){
        super(name, "category", label, {}, default_value || {}, hanlders);
        this._elem = CategorySelectIncludeExclude;
    }
}

class OperationTypeIncludeExcludeField extends OptionField {
    constructor(name, label, default_value, hanlders){
        super(name, "operation_type", label, {}, default_value || {}, hanlders);
        this._elem = OperationTypeSelectIncludeExclude;
    }
}


class BoolField extends OptionField {
    constructor(name, label, default_value, hanlders){
        super(name, "bool", label, {"1": label+": Oui", "0" : label+": Non"}, default_value || false, hanlders);
        this._cast_from_js = x => (x?"1":"0");
        this._cast_to_js = x => ((x=="1")?true:false);
    }
}

function cast_(x){
    return (x.target!==undefined)?x.target.checked:x
}




class CheckBoxField extends OptionField {
    constructor(name, label, default_value, hanlders){
        super(name, "bool", label, null, default_value || false, hanlders);
        //this._cast_to_js = x => x.target.checked
        this._cast_to_js = cast_
    }

    do_render(value){
        return (
            <div className="form-check" key={this.type+"-"+this.name}>
                <input className="form-check-input" type="checkbox" checked={value} onChange={this.on_change.bind(this)} />
                <label className="form-check-label" >
                    {this.label}
                </label>
            </div>
        )
    }
}



class IntField extends OptionField {
    constructor(name, label, default_value, hanlders){
        super(name, "int", label, null, default_value || 0, hanlders);
        this._cast_from_js = x => parseInt(x);
        this._cast_to_js = x => parseInt(x);
        this._elem = Integer;
    }
}


class StringField extends OptionField {
    constructor(name, label, default_value, hanlders){
        super(name, "string", label, null, default_value || "", hanlders);
        this._elem = Entry;
    }
}

class FloatField extends OptionField {
    constructor(name, label, default_value, hanlders){
        super(name, "int", label, null, default_value || 0, hanlders);
        this._cast_from_js = x => parseFloat(x);
        this._cast_to_js = x => parseFloat(x);
        this._elem = Float;
    }
}

class FormField extends OptionField {
    constructor(name, label, fields, default_value, hanlders){
        super(name, "form", label, null, default_value || {}, hanlders);
        this._elem = FormField;
        this._fields = fields;

        this._args.fields = this._fields;
    }
}

class SimpleAccountField extends OptionField {
    constructor(name, label,  default_value, hanlders){
        super(name, "simple_account", label, null, default_value || {}, hanlders);
        this._elem = AccountSelect;
        this._args.simple = true;
    }
}


class MultipleAccountField extends OptionField {
    constructor(name, label, default_value, hanlders){
        super(name, "multiple_account", label, null, default_value || {}, hanlders);
        this._elem = AccountSelect;
        this._args.simple = false;
    }
}


class AutoOptionsRenderer extends Component {

    constructor(props){
        super(props);
        this.state = {}
        this.onchange = null;
        this.fields={}
        
    }


    on_change(key, value) {
        value = this.fields[key]._cast_to_js(value)
        this.state[key] = value;
        this.setState({})
        this.props.onFieldChange && this.props.onFieldChange(key, value)
        this.props.onChange && this.props.onChange(this.val())
    }

    val(){
        var ret = {};
        for(var field of this.props.fields){
            ret[field.name] = this.state[field.name];
        }
        return ret;
    }

    render(){
        var ret = [];
        var value = this.props.value || {};
        for(var field of this.props.fields){
            if(value[field.name]===undefined){
                if(field.default_value===undefined){
                    this.state[field.name] = "";
                }else{
                    this.state[field.name] = field.default_value;
                }
            }else{
                this.state[field.name] = value[field.name]
            }   
            this.fields[field.name] = field;
            field.set_on_change(this.on_change.bind(this));
            ret.push(field.render(this.state[field.name]));
        }
        return (<div>
            {ret}
        </div>);
    }
}

class AutoOptions {
    constructor(fields){
        this.fields = fields;
    }

    render(value, onChange, onFieldChange){
        return <AutoOptionsRenderer onChange={onChange} onFieldChange={onFieldChange}  fields={this.fields} value={value}></AutoOptionsRenderer>
    }
}

const FormRenderer = AutoOptionsRenderer 


function from_json(data, out=null, filter=null){
    out = out || {}
    if(data.name && filter && data.name.indexOf(filter) && filter.indexOf(data.name)) return out
    if(data.type == "container"){
        for(var opt of data.options){
            from_json(opt, out, filter)
        }
    }else{
        var elem = null;
        switch(data.type){
            case "int":
                elem = elem || IntField;
            case "float":
                elem = elem || FloatField;
            case "bool":
                elem = elem || CheckBoxField;
                out[data.name] = new elem(data.name, data.description, data.default_value)
                break
            case "select":
                out[data.name] = new SelectField(data.name, data.description, data.options,
                    data.default_value)
                break
        }
    }
    return out;
}


function get_options_descriptions(filter=null){

    var ret =  from_json(Options, {}, filter);
    for(var [name, op] of Object.entries(ret)){
        var val = Context.user.preferences[name];
        op.default_value = (val!==undefined)?val:op.default_value;
    }
    return ret
}

function get_options_descriptions_list(filter=null){
    return Object.values(get_options_descriptions(filter));
}

window.get_description = get_options_descriptions;

function get_option(opt){
    var ret = Context.user.preferences[opt];
    if(ret === undefined){
        ret = get_options_descriptions_list(opt);
        if(ret.length==1) ret = ret[0].default_value;
        else{
            show_error("Option inconnue", "Erreur l'option '"+opt+"' n'est pas définie")
        }
    }
    return ret
}
window.get_option = get_option;

export {
    get_options_descriptions, get_options_descriptions_list, get_option,
    AutoOptions, AutoOptionsRenderer, BoolField, CheckBoxField, SelectField, CategoryIncludeExcludeField,
    IntField, FloatField, StringField, FormRenderer, FormField, SimpleAccountField, MultipleAccountField,
    OperationTypeIncludeExcludeField
}
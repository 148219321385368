const Options =  {
        "type": "container",
        "description": "",
        "name": "",
        "options": [
          {
            "type": "container",
            "description": "Les options relatives \u00e0 l'interface",
            "name": "ui",
            "options": [
              {
                "type": "container",
                "description": "Les options des graphs",
                "name": "ui.graphs",
                "options": [
                  {
                    "type": "bool",
                    "default_value": false,
                    "description": "D\u00e9plier les graphs",
                    "name": "ui.graphs.graphs_collapsed"
                  },
                  {
                    "type": "select",
                    "default_value": 1,
                    "description": "Nombre de graph par ligne",
                    "name": "ui.graphs.graphs_per_line",
                    "options": {
                      "1": 1,
                      "2": 2,
                      "3": 3,
                      "4": 4
                    }
                  }
                ]
              }
            ]
          }
        ]
      }  
      

export { Options }
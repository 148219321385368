
class Period {
    as_date(){
        
    }

    next(){

    }

    compare(other){
        var a = this.as_date().getTime(), b=other.as_date().getTime();
        return a-b
    }

    static from_date(date){
        
    }

    pretty_string(){
        var x = this.as_date();
        var y = x.getFullYear()
        var m = (""+(x.getMonth()+1)).padStart(2,0)
        var d = (""+x.getDate()).padStart(2,0)
        return d+"/"+m+"/"+y
    }
}





class Day extends Period{
    constructor(year, month, day){
        super();
        this.year = year;
        this.month = month;
        this.day = day;
    }

    as_date(){
        return new Date(Date.parse(this.year+"/"+this.month+"/"+this.day));
    }

    next(){
        var date = this.as_date();
        date.setDate(date.getDate()+1);
        return new Day(date.getFullYear(), date.getMonth()+1, date.getDate())
    }

    static from_date(date){
        return new Day(date.getFullYear(), date.getMonth()+1, date.getDate())
    }

    toString(){return "Day,"+this.year+","+(""+this.month).padStart(2,0)+","+(""+this.day).padStart(2,0);}

    pretty_string(){
        return (""+this.day).padStart(2,0)+"/"+(""+this.month).padStart(2,0)+"/"+this.year   
    }

    equals(x){
        return this.year == x.year && this.month == x.month && this.day == x.day;
    }
}



class BadDateException{

}


class Month extends Day{
    constructor(year, month){
        super(year, month, 1);
    }

    next(){
        var date = this.as_date();
        date.setMonth(date.getMonth()+1);
        return new Month(date.getFullYear(), date.getMonth()+1)
    }

    static from_date(date){
        return new Month(date.getFullYear(), date.getMonth()+1)
    }

    toString(){return "Month,"+this.year+","+(""+this.month).padStart(2,0);}

    equals(x){
        return this.year == x.year && this.month == x.month;
    }

    
}

class Year extends Day{
    constructor(year){
        super(year, 1, 1);
    }

    next(){
        return new Year(this.year+1)
    }

    static from_date(date){
        return new Month(date.getFullYear())
    }
    toString(){return "Year,"+this.year;}


    equals(x){
        return this.year == x.year;
    }
}

class Week extends Period {
    constructor(year, week){
        super();
        this.week = week;
        this.date = new Date(year, 0, 1);
        var day = (7+this.date.getDay())%7;
        this.date.setDate(1-day+7*week)
        this.year = this.date.getFullYear();
    }

    as_date(){
        return  new Date(this.date.getTime());
    }

    next(){
        return new Week(this.year, this.week+1);
    }

    static from_date(date){
        var week = new Week(date.getFullYear(), 0);
        var day = week.date.getTime()
        var my_day = date.getTime()
        return new Week(date.getFullYear(), parseInt((my_day-day)/(1000*3600*24*7)))
    }
    toString(){return "Week,"+this.year+","+this.week;}

    equals(x){
        return this.year == x.year && this.week == x.week;
    }

}

const CLASSES = {
    "year" : Year,
    "month" : Month,
    "week" : Week,
    "day" : Day
}

function parse_period(period, date){
    var date = new Date(Date.parse(date));
    return CLASSES[period].from_date(date)
}

function parse_str_period(content){
    var splited = content.split(",").map( (x, i) => (i?parseInt(x):x))
    var classe = CLASSES[splited[0].toLowerCase()];
    return new classe(...splited.slice(1))
}
    
function pretty_str(x){
    if(x){
        x = parse_str_period(x).pretty_string();
    }
    return x

}


export {
    Day, Week, Month, Year, parse_period, parse_str_period, pretty_str, BadDateException
}

import {Bar, Line} from 'react-chartjs-2';
import { Context } from 'context';
import { Day, parse_str_period, pretty_str } from 'model/date';
import { CategoryIncludeExcludeField, IntField, SelectField } from 'widget/autorenderer';
import { ComputeUtils } from './utils/base';
import { cast_float_montant } from 'model/common/utils';
import { OperationTypeIncludeExcludeField } from 'widget/autorenderer/autorenderer';
const { Component } = require("react");
const { DataEntry, Operation, QuerySet, DataSet } = require("../queryset");
const { Graph, colors_alpha, Value } = require("./base");


class HabitudesUtils extends ComputeUtils {
    /**
     *  options: {
     *      produit: (produits, charges, produits_charges),
     *      filter_categories: {categories: [id1, id2]}
     *  }
     */
    post_init(){
        this.habitudes = this._calc_habitudes();
        this.revenu = (this.options.revenu || 0);
    }

    _calc_habitudes(){
        var start = this.get_today();
        var nb_jours = this.get_nb_jour_in_month();
        var operations = {}
        var suffix = "/"+(""+(start.getMonth()+1)).padStart(2,0)+"/"+start.getFullYear();
        var monthes = {}
        var current_month = []
        var produit = this.options.produit.indexOf("produits")>=0;
        var charges = this.options.produit.indexOf("charges")>=0;
        for(var i=0; i<nb_jours; i++) operations[i+1] = new DataEntry( (""+(i+1)).padStart(2,0)+suffix);
        for(var op of this.operations.data){
            op = this.filter_operation(op)
            if(op==null) continue;
            if(op.montant>0 && !produit) continue;
            if(op.montant<0 && !charges) continue;
            var date = op._date.getDate();
            var mois = "/"+(""+(op._date.getMonth()+1)).padStart(2,0)+"/"+op._date.getFullYear()
            if(mois == suffix){
                current_month.push(op);
            }else{
                monthes[mois] = true;
                if(date>nb_jours) continue;
                operations[date].add(op)
            }
        }
        var nb_mois = Object.keys(monthes).length

        return {
            nb_mois: nb_mois,
            suffix: suffix,
            nb_jours: nb_jours,
            operations: operations,
            current_month: current_month,
            start: start,
        }
    }

    get_param_value(param, day, default_value=0){
        if(Array.isArray(param)){
            return param[day-1]
        }else if(param === null){
            return default_value
        }else if(typeof param == "function"){
            return param(day);
        }
        else {
            return param;
        }
    }

    mixup_data(additional_data=null, start=null, end=null, start_amount=null){
        var data = [], acc_data=[]
        var current = (start_amount===null)?this.revenu:0;
        for(var [day, values] of Object.entries(this.habitudes.operations)){
            if(start && day<start) continue; 
            if(end && day>end) continue; 
            var value = Value.from_operations(values);
            value.label = (""+day).padStart(2,0)+this.habitudes.suffix;
            var tmp = this.get_param_value(additional_data, day, value.value/this.habitudes.nb_mois);
            acc_data.push(tmp)
            value.value = current+tmp;
            data.push(value)
            current = cast_float_montant(value.value);
        }
        return {
            data: data,
            acc_data: acc_data
        }
    }

    get_reality(){
        var current_month = new QuerySet(this.habitudes.current_month)
        var data = []
        var acc = this.revenu;

        var first_month = new Date()
        first_month.setDate(1)
        for(var [day, values] of current_month.group_by_day().as_array({
            date_start: first_month,
            date_end: new Date(),
            interpolation: DataSet.ZERO,
            extrapolation_start: DataSet.ZERO,
            extrapolation_end: DataSet.ZERO,
        })){
            var ps = pretty_str(day)
            var day = parseInt(ps.split("/")[0])
            var value = Value.from_operations(values.data.filter(this.filter_operation.bind(this)));
            value.label = ps;
            value.value = cast_float_montant(acc+(value.value));
            data.push(value)
            acc = value.value;
        }

        return data
    }
    
}

class Habitudes extends Graph {
    init(){
    }

    on_click(graph, evt){
        var x = this.get_dataset_by_event(evt);
        if(x===null) return
        graph.props.parent.show_operations(x.operations)
    }

    compute_data(){
        var datasets = [];
        var habitudes = this.get_utils(HabitudesUtils)
        var start = habitudes.habitudes.start;

        var {data, acc_data} = habitudes.mixup_data()
        datasets.push({
            label:  "Prévision générale",
            data: data,
            backgroundColor: colors_alpha[3],
            borderColor: colors_alpha[3],
            tension: 0.3
        })

        data = habitudes.get_reality()
        datasets.push({
            label:  "Réalite",
            data: data,
            backgroundColor: colors_alpha[4],
            borderColor: colors_alpha[4],
            tension: 0.3
        })

        
        
        acc_data[data.length-1] = data.length?data[data.length-1].value:0;
        data = habitudes.mixup_data(acc_data, start.getDate(), null, 0).data
        datasets.push({
            label:  "Prévision du mois",
            data: data,
            backgroundColor: colors_alpha[5],
            borderColor: colors_alpha[5],
            tension: 0.3
        })
        

        var data = {
            datasets: datasets
        }
        return data;
    }
}

class HabitudesGraph extends Component {
    render() {
        return (
          <div>
            <Line id={"graph-"+this.props.graph.id}
                options ={{
                responsive: true, 
                scales: {  x: { stacked: true }, y: { stacked: false}},
                onClick: this.props.graph.on_click.bind(this.props.graph, this),
                parsing: {yAxisKey: 'value', xAxisKey: 'label'},
              }}
              data={this.props.data}
            />
          </div>
        );
      }
}


Graph.register("habitudes", {
    data: Habitudes,
    label: "Habitude",
    graph: HabitudesGraph,
    options: [
        new IntField("revenu", "Revenu", 0),
        new SelectField("produit", "Produit/charges", {
            charges: "Charges",
            produits: "Produits",
            charges_produits: "Produits et charges",
        }, "charges_produits"),
        new CategoryIncludeExcludeField("filter_categories", "Catégories", {
            categories: [], include: false
        }),
        new OperationTypeIncludeExcludeField("operation_types", "Types d'opération", {}),
    ]
})

export {HabitudesUtils}
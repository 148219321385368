import React, { Component } from "react";
import { Form, Field, Entry, Integer, Float, Button, CustomButton, Modal, ModalSmall } from "widget";
import "./css/create.css";
import {ModalInfo} from "banque/widget/operation/modal/info";
import {Context} from 'context'
import {Api} from 'banque/api/api'



class PatternEditor extends Component {
    constructor(props){
        super(props);
        var value = props.value || {}
        this.state = {
            id: value.value.id || "",
            pattern: value.value.pattern || "",
            min_date: value.value.min_date || "",
            max_date: value.value.max_date || "",
            min_amount: value.value.min_amount || "",
            max_amount: value.value.max_amount || "",
            show_result_test_modal: null
        }
    }

    on_min_date_change(value, formatted){
    }

    on_max_date_change(value, formatted){
    }

    on_test(){
        var self = this;
        Api.test_pattern(this.val(), function(data){
            self.setState({show_result_test_modal: data})
        })
    }

    on_set_min_amount(value){
        this.state.min_amount = value;
        this.props.parent.state.value.min_amount = value;
        //this.props.parent.setState({})
        this.setState({})
    }

    on_set_max_amount(value){
        this.state.max_amount = value;
        this.props.parent.state.value.max_amount = value;
        //this.props.parent.setState({})
        this.setState({})
    }

    on_set_pattern(value){
        this.state.pattern = value;
        this.props.parent.state.value.pattern = value;
        //this.props.parent.setState({})
        this.setState({})
    }

    val(){
        return {
            id: this.state.id,
            pattern: this.state.pattern,
            min_date: this.state.min_date,
            max_date: this.state.max_date,
            min_amount: this.state.min_amount,
            max_amount: this.state.max_amount,
        }
    }

    on_set_value(){
        var self = this;
        if(this.props.onValidate){
            this.props.parent && this.props.parent.on_close_edit()
            return this.props.onValidate(this.val());
        }
        if(this.props.value.value.id){
            Api.update_pattern(this.props.category, this.val(), function(data){
                data.i = self.props.value.i;
                Object.assign(self.state, data)
                self.props.parent.set_value && self.props.parent.set_value(data);
            });
        }else{
            Api.add_pattern(this.props.category, this.val(),function(data){
                data.i = self.props.value.i;
                Object.assign(self.state, data)
                self.props.parent.set_value && self.props.parent.set_value(data);
            });
        }
    }

    on_close_modal(){
        this.setState({show_result_test_modal: null})
    }

    result_test_modal(){
        if(this.state.show_result_test_modal!=null){
            var lines = [];
            for(var i in this.state.show_result_test_modal.filtered){
                var op = this.state.show_result_test_modal.filtered[i];
                lines.push((<tr><td>{op.date}</td> <td>{op.libelle}</td> <td>{op.montant}</td> </tr>))
            }


            return (<ModalSmall
            isOpen={true}
            onClose={this.on_close_modal.bind(this)}
            >
                <div className="modal-header">
                    <h5>{this.state.show_result_test_modal.count} reconnaissance sur les {this.state.show_result_test_modal.total} derniers</h5>
                    <button type="button" className="btn-close" onClick={this.on_close_modal.bind(this)}></button>
                </div>
                <div className="modal-body">
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Libelle</th>
                                <th>Montant</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lines}
                        </tbody>
                    </table>
                </div>
                <div className="modal-footer">
                    <CustomButton onClick={this.on_close_modal.bind(this)}>Fermer</CustomButton>
                </div>



            </ModalSmall>)
        }
        else
        {
            return null;
        }
    }

    on_close(){
        this.props.onClose && this.props.onClose();
    }

    render(){
        var fields = [
            { name: "pattern", field : <Entry type="text" value={this.state["pattern"]}  onChange={this.on_set_pattern.bind(this)} />, label: "Libelle" },
            //{ name: "min_date", field : <Entry type="text" value={this.state["min_date"]} />, label: "Date min" },
            //{ name: "max_date", field : <Entry type="text" value={this.state["max_date"]} />, label: "Date max" },
            { name: "min_amount", field : <Float value={this.state["min_amount"]}  onChange={this.on_set_min_amount.bind(this)} />, label: "Montant min" },
            { name: "max_amount", field : <Float value={this.state["max_amount"]}  onChange={this.on_set_max_amount.bind(this)}  />, label: "Montant max" },
        ];
        return (<Form className="pattern-edit" content={fields}>
            <div>
                {this.result_test_modal()}
                <CustomButton onClick={this.on_set_value.bind(this)} >Appliquer</CustomButton>
                <CustomButton onClick={this.on_test.bind(this)} >Tester </CustomButton>
                <CustomButton onClick={this.on_close.bind(this)} >Fermer </CustomButton>
            </div>
        </Form>)
    }
}

class Pattern extends Component {
    constructor(props){
        super(props);
        var value = props.value || {};
        this.state={
            use_modal: false,
            value: value,
            i: value.i
        }

    }

    val(){
        return this.state;
    }

    on_edit(){
        this.setState({use_modal: true})
    }

    on_close_edit(){
        this.setState({use_modal: false})
    }

    on_suppress(){
        this.props.parent.remove(this.state.i)
    }

    set_value(x){
        x.use_modal = false;
        this.setState(x)
        this.props.parent.set_pattern(x)
    }

    render(){
        var modal=this.state.use_modal?(
        <ModalSmall title="Editer une catégorie" onClose={this.on_close_edit.bind(this)} isOpen={true}>
            <PatternEditor value={this.state}  
                            parent={this} 
                            onClose={this.on_close_edit.bind(this)}  
                            onValidate={this.props.onValidate?this.props.onValidate:null}
                            category={this.props.category} i={this.props.i} />
        </ModalSmall>):null;
        var val = "";
        if(this.state.value.pattern) val+=" pattern='"+this.state.value.pattern+'"';
        if(this.state.value.min_date) val+=" Date min='"+this.state.value.min_date+'"';
        if(this.state.value.max_date) val+=" Date max='"+this.state.value.max_date+'"';
        if(this.state.value.min_amount) val+=" Montant min='"+this.state.value.min_amount+'"';
        if(this.state.value.max_amount) val+=" Montant max='"+this.state.value.max_amount+'"';
        return (<div className="pattern">
            {modal}
            <span className="pattern-resume">{val}</span>
            <span className="pattern-action right">
                <a onClick={this.on_edit.bind(this)}><i className="bi bi-pencil "></i></a>
                <a onClick={this.on_suppress.bind(this)}><i className="bi bi-trash3"></i></a>
            </span>
        </div>)
    }
}

class PatternManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pattern_set: props.defaultValue || []
        };
    }

    _max_i(){
        var max=0;
        for(var i=0; i<this.state.pattern_set.length; i++){
            if(this.state.pattern_set[i].i>max) max=this.state.pattern_set[i].i
        }
        return max;
    }

    add(){
        var max=this._max_i();
        this.state.pattern_set.push({i: max+1})
        this.setState({})
    }

    _do_remove(id){
        for(var j=0; j<this.state.pattern_set.length; j++){
            if(this.state.pattern_set[j].id == id){
                this.state.pattern_set.splice(j, 1);
                break;
            }
        }
        this.setState({})
    }

    remove(i){
        var newpatterns=[];
        for(var j=0; j<this.state.pattern_set.length; j++){
            if(this.state.pattern_set[j].i == i){
                Api.remove_pattern(this.props.category, this.state.pattern_set[j].id, this._do_remove.bind(this, this.state.pattern_set[j].id))
                break;
            }
        }
    }

    set_pattern(data){
        for(var j=0; j<this.state.pattern_set.length; j++){
            if(this.state.pattern_set[j].i == data.i){
                Object.assign(this.state.pattern_set[j], data);
                break;
            }
        }
        this.setState({})
    }

    val(){
        var ret = [];
        for(var i in this.state.pattern_set){
            var tmp = Object.assign({}, this.state.pattern_set[i]);
            delete tmp.i;
            ret.push(tmp)
        }
        return ret;
    }

    render() {
        var val = [];
        for(var i in this.state.pattern_set){
            if(this.state.pattern_set[i].i==undefined){
                this.state.pattern_set[i].i=this._max_i()+1;
            }
                
            var x = <Pattern key={this.state.pattern_set[i].i} value={this.state.pattern_set[i]} parent={this} category={this.props.category}/>;
            val.push(x);
        }
        return (<div className="pattern-holder row">
            {val}
            <CustomButton onClick={this.add.bind(this)} className="col-auto">Ajouter</CustomButton>
        </div>)
    }
}


class CreateCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            /*id : props.value.id || "",
            name: props.value.name || "",
            description: props.value.description || "",
            priority: props.value.priority || "",
            pattern_set: props.value.pattern_set || []*/
        };

        this.ref_id = React.createRef();
        this.ref_name = React.createRef();
        this.ref_description = React.createRef();
        this.ref_priority = React.createRef();
        this.ref_pattern_set = React.createRef();
  }


  val(){
    var def = this.props.value || {};
    var ret =  {
        id : this.ref_id.current.val() || def.id,
        name: this.ref_name.current.val() || def.name,
        description: this.ref_description.current.val() || def.description,
        priority: this.ref_priority.current.val() || def.priority,
    };

    if(this.ref_pattern_set.current!=null)
        ret["pattern_set"] = this.ref_pattern_set.current.val() || def.pattern_set
        
    return ret;
  }

  on_valid(){
    this.props.onValidate && this.props.onValidate(this.val())
  }

  render() {
    var fields = [
        { name: "id", visible : false, field : <Entry value={this.state["id"]} defaultValue={this.props.value.id || ""}  key={-41} ref={this.ref_id} /> },
        { name: "name", label : "Nom", field : <Entry value={this.state["name"]} key={0} defaultValue={this.props.value.name || ""}  ref={this.ref_name}/> },
        { name: "description", label : "Description", field : <Entry value={this.state["description"]} defaultValue={this.props.value.description || ""}   key={1} ref={this.ref_description} /> },
        { name: "priority", label : "Priority", field : <Integer value={this.state["priority"]}  key={2} defaultValue={this.props.value.priority || 0}  ref={this.ref_priority} /> }
    ];

    if(this.props.value.id){ //ne pas afficher les pattern à la création
        fields.push({ name: "pattern_set", label : "Patterns", field : <PatternManager value={this.state["pattern_set"]} defaultValue={this.props.value.pattern_set || []}   key={3} ref={this.ref_pattern_set} category={this.props.value.id}/> });
    }

    return ( <Form content={fields}>
        {this.props.onValidate?<CustomButton onClick={this.on_valid.bind(this)}>Valider</CustomButton>:null}
    </Form>)
  }
}


class CreateCategoryModal extends Component {
    constructor(props){
        super(props);
        this.state={
            show: props.show || false,
            value: props.value || {}
        }
        this.create_ref = React.createRef();
    }

    on_cancel(){
        this.state.show=false;
        this.props.onClose && this.props.onClose();
        this.props.onCancel && this.props.onCancel();
    }

    send(data){
        this.props.parent.on_send(this.create_ref.current.val());
    }

    on_close(){
        this.state.show=false;
        this.props.onClose && this.props.onClose();
    }

    render(){
        return (
        <ModalSmall
            title="Editer une catégorie"
            onClose={this.on_close.bind(this)}
             value={this.props.value}
            isOpen={this.state.show}>

            <div className="modal-header">
                <h5>Créer une catégorie</h5>
                <button type="button" className="btn-close" onClick={this.on_close.bind(this)}></button>
            </div>
            <div className="modal-body">
                <CreateCategory value={this.props.value} parent={this} ref={this.create_ref}/>
            </div>
            <div className="modal-footer">
                <CustomButton onClick={this.send.bind(this)}>Envoyer</CustomButton>
                <CustomButton onClick={this.on_close.bind(this)}>Annuler</CustomButton>
            </div>

        </ModalSmall>
        )
    }
}

export {
    CreateCategory,
    CreateCategoryModal,
    PatternEditor,
    Pattern
}
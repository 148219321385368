import React, { Component } from "react";
import "./css/thumbnail.css";
import { GraphEditorModal } from "./editor";
import { Graph } from "model/stats/graph";
import { GraphManager } from "model/graph_manager";
import { dmyDate, toggleFullscreen } from "widget/common";
import { Button, CustomButton } from "widget";
import { Api } from "banque/api/api";
import { OperationThumbnailList, OperationThumbnailListModal } from "../operation/list";
import { Context } from "context";
import { Operation, QuerySet } from "model/stats/queryset";
import { show_validation } from "model/app/app_common";
import { AutoOptions } from "widget/autorenderer";


class GraphThumbnail extends Component {

    constructor(props){
        super(props);
        var collapsed = Context.user.preferences["ui.graphs.graphs_collapsed"]
        this.state = {
            is_edit: false,
            graph: GraphManager.get_graph(this.props.value),
            graph_data: null,
            associated_operation: null,
            associated_operation_loaded: false,
            offset: 0,
            colapsed: collapsed
        }
        
        
        this.state.graph_data = GraphManager.get_data(this.props.value);
    }

    update_graph(){
        this.set_graph(this.props.value, this.state.offset, false);
    }

    set_graph(graph, offset, setState=true){
        if(this.state.offset){
            GraphManager.remove(this.state.graph.id);
        }
        
        if(Array.isArray(graph.operations)){
            if(graph.operations.length && !(graph.operations[0] instanceof Operation))
                graph.operations = graph.operations.map(x => new Operation(x))
            graph.operations = new QuerySet(graph.operations)
        }

        this.state.graph = GraphManager.update(graph);  
        this.state.graph_data = GraphManager.get_data(graph);
        this.state.offset = offset;
        if(setState) this.setState({})
    }

    on_edit(){
        this.setState({is_edit: true});
    }

    on_edit_close(){

        this.setState({is_edit: false});
    }

    on_remove(){
        var self = this;
        show_validation("Êtes vous sûr ?", "Voulez vous vraiment supprimer le graph '"+this.state.graph.name+"'",
        function(){
            Api.remove_graph(self.state.graph.id, function(){
                self.props.parent.remove(self.state.graph.id)
            })
        })
    }

    on_fullscreen_headless(){
        toggleFullscreen(document.getElementById("graph-"+this.state.graph.id))
    }

    on_fullscreen(){
        this.props.parent.set_fullscreen(this.props.isFullscreen?null:this.state.graph.id);
    }

    on_graph_change(val){
        this.setState({is_edit: false});
    }

    on_date_left(){
        this._on_date_change(this.state.offset+1)
    }

    on_date_right(){
        this._on_date_change(this.state.offset-1)
    }

    _on_date_change(offset){
        var self = this;
        var g = Object.assign({}, this.state.graph)
        delete g.operations;
        Api.graph_translate(g, offset, function(data){
            self.set_graph(data, offset);
        })
    }

    get_date_selector(){
        var [start, end] = this.state.graph.range;
        var left = null, right = null;
        if(start){
            start = dmyDate(start);
            left = (<Button className="graph-thumbnail-date-select-button" onClick={this.on_date_left.bind(this)}>
                <i className="bi bi-chevron-left"></i>
            </Button>);
        }else{
            start = "-";
        }
        if(end && left){
            end = dmyDate(end);
            right = (<Button className="graph-thumbnail-date-select-button" onClick={this.on_date_right.bind(this)}>
                <i className="bi bi-chevron-right"></i>
            </Button>);
        }else{
            end = "-";
            left = null;
        }
        
        
        
        return (<>
            {left}
            <span>du {start} au {end}</span>
            {right}
        </>)
    }

    _get_associated_operations(){
        if(this.state.associated_operation_loaded){
            return (<OperationThumbnailListModal 
                operations={this.state.associated_operation} 
                account={this.state.graph.account_id} 
                onClose={this.hide_operations.bind(this)}
                key="a"/>)
        }
        return null;
    }

    show_operations(ops){
        this.state.associated_operation = ops;
        this.state.associated_operation_loaded = true;
        this.setState({})
    }

    hide_operations(){
        this.state.associated_operation = null;
        this.state.associated_operation_loaded = false;
        this.setState({})
    }

    on_update(data=null){
        this.state.is_edit = false;
        this.set_graph(data, this.state.offset);
    }

    on_refresh(graph_modif){
        var data = Object.assign(this.state.graph, graph_modif || {})
        this.set_graph(data, this.state.offset);
    }

    on_toggle_colapsed(){
        this.state.colapsed = !this.state.colapsed;
        this.setState({})
    }

    render(){
        var graph_content = null;
        var button_collapsed = null;
        var Graphe = Graph.get_descriptor(this.state.graph.type);
        var associated_operations = this._get_associated_operations();
        if(!Graphe || !Graphe.graph){
            alert("Le graph "+this.state.graph.type+" n'est pas déclaré")
        }
        if(this.props.update){
            this.update_graph()
            this.props.parent.clean_update();
        }
        Graphe = Graphe.graph;
        var editor = null;
        if(this.state.is_edit){
            editor =  (<GraphEditorModal 
                holder = {this}
                value={this.state.graph} 
                onChange={this.on_graph_change.bind(this)}
                onClose={this.on_edit_close.bind(this)} />)
        }
            
        if(this.state.colapsed){
            button_collapsed = (<i className="bi bi-caret-right operation-filter-toggle" onClick={this.on_toggle_colapsed.bind(this)}></i>);
        } else {
            button_collapsed = (<i className="bi bi-caret-down operation-filter-toggle" onClick={this.on_toggle_colapsed.bind(this)}></i>);
            graph_content = (<>
                <div className="graph-thumbnail-date-select">
                    {this.get_date_selector()}
                </div>
                <div className="graph-thumbnail-content">
                    Nombre d'opérations  :  {this.state.graph.operations.data.length}
                    <span id={"chart-"+this.state.graph.id}></span>
    
                    <GraphTumbnailOption 
                            parent={this} 
                            type={this.state.graph.type} 
                            value={this.state.graph.options}
                            force_close={this.state.is_edit}
                            options={this.state.graph.options}/>
                    <Graphe data={this.state.graph_data} graph={this.state.graph} parent={this}/>
                </div></>);
        }
        var fullscreen_buttons = [];
        if(this.props.isFullscreen){
            
            fullscreen_buttons.push(<a  key={"fs_1"} className="graph-thumbnail-header-action"  onClick={this.on_fullscreen.bind(this)}><i className="bi bi-fullscreen-exit"/></a>)
            fullscreen_buttons.push(<a key={"fs_2"} className="graph-thumbnail-header-action"  onClick={this.on_fullscreen_headless.bind(this)}><i className="bi bi-arrows-fullscreen"/></a>)
        }else{
            fullscreen_buttons.push(<a key={"fs_1"} className="graph-thumbnail-header-action"  onClick={this.on_fullscreen.bind(this)}><i className="bi bi-arrows-fullscreen"/></a>)
        }

        if(this.props.headless){
            return (<div>
                    {associated_operations}
                <Graphe data={this.state.graph_data} graph={this.state.graph} parent={this}/>
            </div>)
        }else{
            return (<div className="graph-thumbnail" style={this.props.style}>
                {editor}
                <div className="graph-thumbnail-header">
                    {button_collapsed}
                    {associated_operations}
                    <span className="graph-thumbnail-header-title">{this.state.graph.name}</span>
                    <li className="nav-item dropdown graph-thumbnail-header-action">
                        <a className="nav-link" href="#"  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-gear"/>
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><a className="dropdown-item" onClick={this.on_edit.bind(this)}>Editer</a></li>
                            <li><a className="dropdown-item" onClick={this.on_remove.bind(this)}>Supprimer</a></li>
                        </ul>
                    </li>
                    {fullscreen_buttons}
                    <a className="graph-thumbnail-header-action"  onClick={this.on_refresh.bind(this)}><i className="bi bi-arrow-repeat"/></a>
                </div>
                {graph_content}
                
            </div>);
        }
        
    }
}

class GraphTumbnailOption extends Component {
    constructor(props){
        super(props);

        this.state = {
            value: props.value || {},
            is_open: false
        }
        var desc = Graph.get_descriptor(this.props.type);
        this.options = new AutoOptions(desc.options);
    }

    on_change(data){
        this.props.parent.on_refresh({options: data})
    }

    on_toggle(){
        this.state.is_open=!this.state.is_open;
        this.setState({})
    }

    render(){
        var button = null, content = null;
        if(this.props.headless || this.state.is_open && !this.props.force_close){
            button = <CustomButton onClick={this.on_toggle.bind(this)}>Cacher les options</CustomButton>;
            content = this.options.render(this.state.value, this.on_change.bind(this))
        }else{
            button = <CustomButton onClick={this.on_toggle.bind(this)}>Afficher les options</CustomButton>
        }
        if(this.props.headless){
            return content
        }else{
            return (
                <div>
                    <div>{button}</div>
                    {content}
                </div>
            )
        }
    }
}

export {
    GraphThumbnail, GraphTumbnailOption
}
import React, { Component } from "react";




class Entry extends Component {

  constructor(props) {
    super(props);
    var value = props.value || "";
    //delete props.value;
    this.registered=true;
    this.on_change=null;

    this.state = {
        value: props.value
    };

  }

  val(){
    return this.state.value;
  }

  className(){
    var attr="form-control "
    if(this.props.className){
        attr+=this.props.className;
    }

    if(this.props.size){
        attr+=" "+this.props.size;
    }else{
        this.props.className+=" little";
    }
  }

  handleChange(event) {
    this.state.value=event.target.value
    this.setState({});

    if(this.props.onChange){
        this.props.onChange(this.state.value);
    }

  }

   _on_key_down(evt){
        if(evt.key === "Enter"){
            this.props.onValidate && this.props.onValidate(this.state.value);
        }
        return true;
   }

  render() {
    var on_validate={};
    if(this.props.onValidate){
        on_validate.onKeyPress= this._on_key_down.bind(this)
    }
    var attrs = Object.assign({}, this.props);
    for(var k of ["onValidate", "onChange"]){
        if(k in attrs) delete attrs[k]
    }
    return (<input
        value={this.state.value}
        {...attrs}
        {...on_validate}
        onChange={this.handleChange.bind(this)}/>)
  }
}

function Password(props){
    return <Entry type="password" {...props} />
}

function Hidden(props){
    return <Entry className="hidden" {...props}/>
}

class Number extends Component {

    constructor(props){
        super(props);
        this.state = {
            value : props.value || ""
        }

    }

    val(){
        return parseInt(this.state.value);
    }

    get_value(){
        return this.state.value;
    }

    is_allowed(c){
        return (this.props.allowed || "0123456789.").indexOf(c)>=0;
    }

    get_inc(){
        return this.props.inc || 0.01;
    }

    cast(x){
        if(this.props.cast) return this.props.cast(x)
        return parseFloat(x);
    }



    validate(val){
        if(val.length==0){
            if(this.props.required) return (this.props.min != undefined)?this.props.min:0;
            else return "";
        }
        val = this.cast(val)
        if(this.props.min != undefined && val<this.props.min) return this.props.min
        if(this.props.max != undefined && val>this.props.max) return this.props.max

        return val;
    }

    handleChange(event) {
        this.state.value = event.target.value;
        this.setState({});
        if(this.props.onChange){

            this.props.onChange(this.state.value);
        }
    }

    get_default(){
        if(this.props.min != undefined ) return
        else if(this.props.min != undefined ) return
    }

    up(){
        this.setState({value: this.validate(this.state.value+this.get_inc())});
    }
    down(){
        this.setState({value: this.validate(this.state.value-this.get_inc())});
    }


   _on_key_down(evt){
        if(evt.key === "Enter"){
            this.props.onValidate && this.props.onValidate(this.state.value);

        }
        return false;
   }

    render() {

        var on_validate={};
        if(this.props.onValidate){
            on_validate.onKeyPress= this._on_key_down.bind(this)
        }
        var attrs = Object.assign({}, this.props);
        for(var k of ["onValidate", "onChange"]){
            if(k in attrs) delete attrs[k]
        }

        return (<input
            type="number"
            {...attrs}
            value={this.state.value}
            onChange={this.handleChange.bind(this)}
            {...on_validate}/>)
    }
}

class CheckBox extends Component {
    constructor(props){
        super(props);
        this.state = {
            value: props.value || false
        }
    }

    on_change(val){
        this.state.value = val.target.checked;
        if(this.props.onChange){
            this.props.onChange(this.state.value);
        }else{
            this.setState({})
        }
    }

    val(){
        return this.state.value
    }

    render(){
        return (<div className="form-check">
            <input className="form-check-input" type="checkbox" 
                            checked={this.state.value} onChange={this.on_change.bind(this)} />
            <label className="form-check-label" >
                {this.label}
            </label>
        </div>)
    }
}


var Float = Number;

class Integer extends Number {
    cast(x){
        if(this.props.cast) return this.props.cast(x)
        return parseInt(x);
    }



}
export {
    Entry,
    Password,
    Hidden,
    CheckBox,
    Integer,
    Float,
    Number
};

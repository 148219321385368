import React, { Component } from "react";

import './css/modal.css'
import ReactModal from 'react-modal';
import {CustomButton} from "widget/base/button"

var Modal = ReactModal;

Modal.setAppElement('#root');

Modal.defaultStyles.content.bottom="auto";
Modal.defaultStyles.content.top="auto";
Modal.defaultStyles.content.right="auto";
Modal.defaultStyles.content.left="auto";

function CustomModal(props){
    var operation = props.operation;
    if(!operation) return null;
    var data = [
        ["Libellé", operation.libelle],
        ["Montant", operation.montant+" €"],
        ["Type", operation.type],
        ["Date", operation.date],
        ["Mandat", operation.mandat],
        ["Creancier", operation.creancier]
    ]
    var liste=[];
    for(var i=0; i<data.length; i++){
        liste.push((<tr key={i}><td>{data[i][0]}</td><td>{data[i][1]}</td></tr>))
    }
    var style = { content : {background: '#f00'}};
    return <Modal
        isOpen={true}
        onRequestClose={props.onClose}
        htmlOpenClassName="modal-small"
        contentLabel="Example Modal"
      >
          <div className="modal-header">
            <h5 className="modal-title">{this.props.title}</h5>
            <button type="button" className="btn-close" onClick={props.onClose}></button>
          </div><div className="modal-body">
                {this.props.children}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary"  onClick={props.onClose}>Close</button>
          </div>
      </Modal>
}


function ModalSmall(props){
    return (<Modal {...props} className="custom-modal modal-small" />)
}

function ModalMedium(props){
    return (<Modal {...props} className="custom-modal modal-medium" />)
}

function ModalLarge(props){
    return (<Modal {...props} className="custom-modal modal-large" />)
}

function ModalTransparent(props){
    return (<Modal {...props} className="modal-transparent" />)
}

function Validation(props){
    return (
        <ModalSmall
            title={props.title}
            onClose={props.onCancel}
            isOpen={props.isOpen==undefined?true:props.isOpen}>

            <div className="modal-header">
                <h5>{props.title}</h5>
                <button type="button" className="btn-close" onClick={props.onCancel}></button>
            </div>
            <div className="modal-body">
                <p>{props.children}</p>
            </div>
          <div className="modal-footer">
             <CustomButton onClick={props.onValidate}>Valider</CustomButton>
             <CustomButton onClick={props.onCancel}>Annuler</CustomButton>
          </div>
        </ModalSmall>
    )
}


function Error(props){
    return (
        <ModalSmall
            title={props.title}
            onClose={props.onClose}
            isOpen={props.isOpen==undefined?true:props.isOpen}>

            <div className="modal-header">
                <h5>{props.title}</h5>
                <button type="button" className="btn-close" onClick={props.onClose}></button>
            </div>
            <div className="modal-body">
                <p>{props.children}</p>
            </div>
          <div className="modal-footer">
             <CustomButton onClick={props.onClose}>Fermer</CustomButton>
          </div>
        </ModalSmall>
    )
}

export {
    Modal, CustomModal, ModalLarge, ModalMedium, ModalTransparent, ModalSmall, Validation, Error
};
import React, { Component } from "react";
import "./css/date.css"
import { Button } from "./button";
import {  Entry, Integer, Float } from "widget/base/entry";
import { Select } from "widget/base/select";

const DAYS = [
    "D", "L", "M", "M", "J", "V", "S"
]

const MONTH = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    'Juin',
    "Juillet",
    "Aout",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre"
]

class DatePicker extends Component {
    /*
        props:
            - value (Date | "dd/mm/yyyy" | "yyyy/mm/dd") : valeur
            - label str

    */
    constructor(props){

        super(props);
        var value = this.parse_date(props.value);
        this.state={
            is_open: false,
            value: value,
            current_month: props.value?value:(new Date())
        }
    }

    _value(x){
        if(x!=undefined){
            this.state.value=x;
        }
        return this.state.value;

    }

    val(){
        return this.format_date(this.state.value);
    }

    parse_date(x, default_value=null){
        if(x === null || x=== undefined || x===""){
            return default_value
        }
        if(typeof x === "string"){
            var arr = x.split("/");
            if(arr.length==1){
                arr = x.split("-")
            }

            if(arr[0].length==4) arr.reverse()
            if(arr.length!=3 || arr[0].length!=2 || arr[1].length!=2 || arr[2].length!=4){
                console.error("Impossible de parser la date '"+x+"'")
                return default_value
            }
            arr = arr.map(x => parseInt(x))
            return new Date(arr[2], arr[1]-1, arr[0])
        }
    }


    format_date(date){
        if(date == null) return "-"
        var y = (date.getFullYear()+"").padStart(4,'0')
        var m = ((date.getMonth()+1)+"").padStart(2,'0')
        var d = (date.getDate()+"").padStart(2,'0')
        return d+"/"+m+"/"+y
    }

    get_day_letter(x){
        x = x || this._value()
        return DAYS[x.getDay()];
    }

    get_month_string(x){
        x = x || this._value()
        return MONTH[x.getMonth()];
    }

    _do_close(){
        return true;
    }

    on_button_click(date){
        var value = new Date(this.state.current_month.getFullYear(), this.state.current_month.getMonth())
        value.setDate(date)
        this._value(value);

        this.state.is_open= !this._do_close()
        this.setState({})

        this.props.onChange && this.props.onChange(this.val(), this);
    }

    formatted_value(){
        return this.format_date(this._value())
    }

    on_toggle(){
        this.setState({is_open: !this.state.is_open})
    }

    getDaysInMonthUTC(month, year) {
        var date = new Date(Date.UTC(year, month, 1));
        var day_index = (date.getDay()+7-1)%7;
        var days = [];
        for(var i=1; i<day_index-1; i++){
            days.push(0)
        }
        while (date.getUTCMonth() === month) {
            days.push(date.getDate());
            date.setUTCDate(date.getUTCDate() + 1);
        }

        return days;
    }
    
    _equals(d1, d2){
        if(!d1 && !d2) return true
        if( (!d1 && d2) || (d1 && !d2)) return false;
        return d1.getDate()==d2.getDate() && d1.getMonth()==d2.getMonth() && d1.getFullYear()==d2.getFullYear();
    }

    _button_classname(d){
        if(!d) return []
        var v = this._value()
        var ret=["datepicker-table-body-btn"];
        var today = new Date();

        if(this._equals(v,d)){
            ret.push("datepicker-table-body-btn-selected")
        }

        if(this._equals(today,d)){
            ret.push("datepicker-table-body-btn-current-day")
        }
        return ret
    }


    buttons(){
        var days = this.getDaysInMonthUTC(this.state.current_month.getMonth(), this.state.current_month.getFullYear())
        var weeks=[]
        var current = []
        var today = new Date();
        for(var i in days){
            let d = days[i];
            var data = d || null;

            var date = data?(new Date( this.state.current_month.getFullYear(), this.state.current_month.getMonth(), data)):null;
            var className = this._button_classname(date).join(" ");
            current.push(
                <td key={"td_"+this.format_date(this.state.current_month)+"_"+i} className="datepicker-td">
                    <Button className={className} onClick={this.on_button_click.bind(this, d)}>
                        {data}
                    </Button></td>
                );

            if(current.length==7){
                weeks.push(<tr key={"tr_"+this.format_date(this.state.current_month)+"_"+i}>{current}</tr>);
                current=[];
            }
        }
        if(current.length)
            weeks.push(<tr key={"tr_"+this.format_date(this.state.current_month)+"_"+(i+1)}>{current}</tr>);

        return weeks;

    }

    table(){
        return (
            <table className="datepicker-table">
                <thead>
                    <tr>
                        <th><center>L</center></th>
                        <th><center>M</center></th>
                        <th><center>M</center></th>
                        <th><center>J</center></th>
                        <th><center>V</center></th>
                        <th><center>S</center></th>
                        <th><center>D</center></th>
                    </tr>
                </thead>

                <tbody className="datepicker-table-body">
                    {this.buttons()}
                </tbody>
            </table>
        )

    }

    on_left(){
        this.state.current_month.setMonth(this.state.current_month.getMonth()-1)
        this.setState({})
        this.forceUpdate();
    }

    on_right(){
        this.state.current_month.setMonth(this.state.current_month.getMonth()+1)
        this.setState({})
    }

    on_reset(){
        this.setState({value: null, is_open: false})
    }

    on_close(){
        this.setState({is_open: false})
    }

    action(){
        return (
                <div className="datepicker-action-line">
                        <Button onClick={this.on_left.bind(this)} ><i className="bi bi-caret-left datepicker-action" /></Button>
                        <span>{this.get_month_string(this.state.current_month)} {this.state.current_month.getFullYear()} </span>
                        <Button onClick={this.on_right.bind(this)}><i className="bi bi-caret-right datepicker-action" /></Button>
                </div>
        )
    }

    close_buttons(){
            return (<div className="datepicker-action-footer">
                    <Button onClick={this.on_reset.bind(this)}>Réinitialiser</Button>
                    <Button onClick={this.on_close.bind(this)}>Fermer</Button>
            </div>)

    }

    _get_button_text(){
        return this._value()!=null?this.formatted_value():"Choisir";
    }

    static_button(){
        return <Button className={this.props.className || "datepicker-btn"} key={"b"+0} onClick={this.on_toggle.bind(this)}> {this._get_button_text()} </Button>
    }

    render(){
        var modal = this.state.is_open?(
            <div className="datepicker-window">
                {this.action()}
                {this.table()}
                {this.close_buttons()}
            </div>
        ):null;


        return (<div>
            {modal}
            {this.static_button()}
        </div>)

    }
}


class DateStartEndPicker extends DatePicker {
    SELECT_START="select_start"
    SELECT_END="select_end"
    IDLE="idle"
    constructor(props){
        super(props);
        this.state={
            is_open: props.is_open,
            start: this.parse_date(props.start),
            end: this.parse_date(props.end),
            current_month: props.start || new Date(),
            state: this.SELECT_START
        }
    }


    _get_button_text(){
        if(this.state.state!=this.SELECT_START){
            return this._render_text_range();
        }
        else{
            return "Choisir un interval"
        }
    }


    on_reset(){
        this.setState({start: null, end: null, is_open: true, state: this.SELECT_START})
    }


    val(){
        return {
            start: this.format_date(this.state.start),
            end: this.format_date(this.state.end)
        };
    }


    _button_classname(d){
        if(!d) return []
        var s = this.state.start || new Date(0);
        var e = this.state.end || new Date();
        var ret=["datepicker-table-body-btn"];
        var today = new Date();

        if(this.state.state!=this.SELECT_START){
            if( (e && d.getTime()>=s.getTime() && d.getTime()<=e.getTime()) || (!e && d.getTime()==s.getTime()) )
                ret.push("datepicker-table-body-btn-selected")
        }
        if(this._equals(today,d)){
            ret.push("datepicker-table-body-btn-current-day")
        }
        return ret
    }

    _value(x){
        if(this.state.state==this.IDLE){
            // ne rien faire, les deux dates sont valides
            return this.state.end;
        }
        else if(this.state.state==this.SELECT_END){
            // la date d'entrée est valide, on met à jour la date de sorte
            if(x !== undefined){
                this.state.end = x;
            }
            this.state.state=this.IDLE;
            return this.state.end;
        }
        else {
            // aucune date de valide on met à jour la date d'entrée
            if(x !== undefined){
                this.state.start=x;
            }
            this.state.state=this.SELECT_END;
            return this.state.start;
        }
    }

    _do_close(){
        return this.state.state==this.IDLE;
    }

    _render_text_range(){
        if(this.state.state == this.IDLE){
            //complet
            return "Du "+this.format_date(this.state.start)+" au "+this.format_date(this.state.end)
        }else if(this.state.state == this.SELECT_END){
            return "Du "+this.format_date(this.state.start)+" au <selection>"
        }
        else{
            return "Du <selection>"
        }
    }

    render(){
        var modal = this.state.is_open?(
        <div className="datepicker-window">
            {this.action()}
            {this.table()}
            <span className="date-start-end-selection">{this._render_text_range()}</span>
            {this.close_buttons()}
        </div>
        ):null;


        return (<div>
            {modal}
            {this.static_button()}
        </div>)

    }

    on_null_button(){
        switch(this.state.state){
            case this.SELECT_START:
                this.setState({state: this.SELECT_END});
                break;
            case this.SELECT_END:
                this.state.is_open= !this._do_close()
                this.setState({state: this.IDLE});
                this.props.onChange && this.props.onChange(this.val(), this);
                break;
        }
    }

    close_buttons(){
        var nullButton = null;
        if(this.state.state == this.SELECT_START){
            nullButton= (<Button onClick={this.on_null_button.bind(this)}>Pas de début</Button>);
        }else if(this.state.state == this.SELECT_END){
            nullButton= (<Button onClick={this.on_null_button.bind(this)}>Pas de fin</Button>);
        }
        return (<><div className="datepicker-action-null">
                {nullButton}
            </div>
            <div className="datepicker-action-footer">
                <Button onClick={this.on_reset.bind(this)}>Réinitialiser</Button>
                <Button onClick={this.on_close.bind(this)}>Fermer</Button>
        </div></>)
    }
}


class TimePicker extends Component {
    constructor(props){
        super(props);
        var value = props.value || {};
        this.state = {
            value: value.value || null,
            type: value.type || "absolute",
        };
    }

    get_value(){
        return {
            value: this.state.value,
            type: this.state.type
        }
    }

    on_change(event){
        this.setState({type: event.target.value});

    }

    on_content_change(value){

    }

    _event(){
       return (<div>
            <Float />
            <Float />
            <Entry />
        </div>
        )
    }


    _get_content(){
        //if(this.state.type=="absolute") return (<DatePicker value={this.state.value} onChange={this.on_content_change.bind(this)} />)
        if(this.state.type=="relative") return (<Integer value={this.state.value} onChange={this.on_content_change.bind(this)} />)
        if(this.state.type=='event') return this._event()
        return null;
    }

    render(){
        return (<div className="date-picker">
            <div>
                <select className="form-select form-select-lg mb-3 col-auto date-picker-select" aria-label=".form-select-lg example" onChange={this.on_change.bind(this)}>
                  <option value="today">Aujourd'hui</option>
                  <option value="absolute">Date</option>
                  <option value="relative">Date relative (jours)</option>
                  <option value="last_month">Dernier moi</option>
                  <option value="event">Evenement</option>
                </select>
            </div>
            <div className="date-picker-content">
                {this._get_content()}
            </div>
        </div>)
    }
}

class PeriodPicker extends Component {
    DURATIONS = {
        day: "Jour",
        month: "Mois",
        year: "Année"
    }
    constructor(props){
        super(props);
        var value = props.value || {};
        this.state = {
            duration: value.duration || "month",
            count: value.count || 1,
            offset: value.offset || ""+0
        }
    }

    val(){
        return {
            duration: this.state.duration,
            count: this.state.count,
            offset: this.state.offset
        }
    }

    on_value_change(key, val){
        this.state[key] = val
        this.props.onChange && this.props.onChange(this.val())
    }

    render(){
        return (<div className="periodpicker">
            <Select options={this.DURATIONS} onChange={this.on_value_change.bind(this, "duration")} selected={this.state.duration} />
            <Integer onChange={this.on_value_change.bind(this, "count")} value={""+this.state.count}  />
            <Integer onChange={this.on_value_change.bind(this, "offset")} value={""+this.state.offset}  />
        </div>)
    }
}


class DateRangePicker extends Component {
    MODE_ABSOLUTE="absolute"
    MODE_RELATIVE="relative"
    MODE_ROLLING="rolling"
    constructor(props){
        super(props)
        var value = props.value || {};
        this.state = {
            mode: value.mode || "absolute",
            value: value.value || {}
        }
        this._select_options = {
            [this.MODE_ABSOLUTE]: "Date à date",
            [this.MODE_RELATIVE]: "Date et durée",
            [this.MODE_ROLLING]: "Date et durée glissant",
        }
    }

    val(){
        return {
            mode: this.state.mode,
            value: this.state.value
        }
    }

    _mode_relative(){
        return  <PeriodPicker  value={this.state.value} onChange={this._on_change.bind(this)} />
    }
    
    _mode_rolling(){
        return  <PeriodPicker  value={this.state.value} onChange={this._on_change.bind(this)} />
    }

    _on_change(val){
        this.state.value = val
        this.props.onChange && this.props.onChange(this.val());
    }

    _mode_absolute(){
        return <DateStartEndPicker value={this.state.value} onChange={this._on_change.bind(this)} />
    }

    on_change(val){
        this.state.mode = val;
        this.setState({})
        this.props.onChange && this.props.onChange(this.val());
    }

    content(){
        return (this.state.mode==this.MODE_ABSOLUTE)?this._mode_absolute():this._mode_relative;
    }



    render(){
        var content = this["_mode_"+(this.state.mode || "relative")].bind(this)
        return (<div className="daterange-picker">
                <Select options={this._select_options} className="col-sm-3" onChange={this.on_change.bind(this)} value={this.state.mode} />
                {content()}            
        </div>

        )
    }
}



export {DatePicker, TimePicker, DateRangePicker, DateStartEndPicker};
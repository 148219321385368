import React, { Component } from "react";

class CategoryThumbnail extends Component {
    constructor(props) {
    super(props);
    this.state = {
        id: this.props.id,
        name: this.props.name,
        description: this.props.description,
        priority: this.props.priority,
        pattern_set: this.props.pattern_set
    };
  }


  on_edit(){
    this.props.parent.on_edit(this)
  }

  on_remove(){
    this.props.parent.on_remove(this.state)
  }


  render() {
    return ( <tr className="category-thumbnail">
        <td>{this.props.name}</td>
        <td>{this.props.description}</td>
        <td>{this.props.priority}</td>
        <td className="category-thumbnail-action-holder">
            <a onClick={this.on_edit.bind(this)}><i className="bi bi-pencil category-thumbnail-action"></i></a>
            <a onClick={this.on_remove.bind(this)}><i className="bi bi-trash2 category-thumbnail-action"></i></a>
        </td>
    </tr>)
  }
}

export {
    CategoryThumbnail
};
import { Context } from "context";

const { Component } = require("react");

class _Default {
    constructor(name, value, default_value=""){
        this.name = name;
        this.value = value;
        this.default_value = default_value;
    }
}

function Default(name, value, default_value=""){
    return new _Default(name, value, default_value);
}


class ReactiveComponent extends Component {
    render_mobile(...args){
        console.error("La classe ne définit pas de méthode 'render_mobile'");
        return null;
    }

    render_desktop(...args){
        console.error("La classe ne définit pas de méthode 'render_desktop'");
        return null;
    }

    smart_render(...args){
        return (Context.is_mobile)?this.render_mobile(...args):this.render_desktop(...args);
    }

    render(){
        return this.smart_render()
    }

}


class AbsComponent extends ReactiveComponent {

    constructor(props){
        super(props);
        this.state = {
            value: {}
        }
        this._on_change_callback = self => self.props.onChange || (() => null);
        this.init()
    }

    init(){

    }

    set_onChange(x){
        if(typeof x !== 'function'){
            x = (self => self.props[x]);
        }
        this._on_change_callback = x
    }

    set_default(name, default_value, value){
        value = (value!==undefined)?value:(this.props.value?this.props.value[name]:"");
        this.state.value[name] = (value===undefined)?default_value:value; 
    }

    set_value(args, update=true){
        if(Array.isArray(args)){
            for(var opt of args){
                this.state.value[opt.name] = (opt.value===undefined)?opt.default_value:opt.value; 
            }
        }
        else{
            for(var [k, v] of Object.entries(args)){
                this.state.value[k] = v;
            }
        }
        
        if(update){
            this.setState({})
        }
        this._on_change_callback(this)(this.val());
    }

    _on_change_item(cast, key, value){
        if(cast) value=cast(value);
        this.state.value[key] = value;
        console.log("change",key, value)
        this.setState({})
        this._on_change_callback(this)(this.val());
    }

    _on_change(key, value){
        return this._on_change_item(null, key, value)
    }

    bind(name, cast=null){
        return this._on_change_item.bind(this, cast, name);
    }

    val(){
        return this.state.value;
    }

}

export {
    AbsComponent,
    ReactiveComponent
}
import React, { Component } from "react";
import "./css/graph.css";
import {Select, DateRangePicker, CustomButton, Entry, ModalMedium, Field, AccountSelect} from 'widget';
import {Api} from 'banque/api/api'
import { Graph } from "model/stats/graph";
import { Context } from "context";
import { Operation } from "model/stats/operation";
import { QuerySet } from "model/stats/queryset";
import { AutoOptions } from "widget/autorenderer";


class GraphEditor extends Component {
    constructor(props){
        super(props);
        var value = props.value || {};
        this.state = {
            id: value.id,
            type: value.type || 'lignes',
            name: value.name || '',
            multiple: value.multiple || true,
            content: value.content || [],
            period: value.period || { mode : "absolute", value: {start: null, end:null}},
            options: value.options || {},
            account: value.account || []
        }
    }

    val(){
        return {
            id: this.state.id,
            type: this.state.type ,
            name: this.state.name ,
            multiple: this.state.multiple ,
            account: this.state.account ,
            content: this.state.content,
            period: this.state.period,
            options: this.state.options
        }
    }


    _get_graph_content(){
        var graph = Graph.get_descriptor(this.state.type);
        var Classe = graph.content;

        if(!Classe){
            return null;
        }
        if(this.state.multiple){
            var acc = [];
            for(var obj of this.state.content){
                acc.push(<Classe value={obj} parent={this} key={obj.id} />)
            }
            acc.push(<CustomButton onClick={this.on_content_add.bind(this)} key={-1}>Ajouter un ligne</CustomButton>)
            return acc;
        }else{
            return <Classe value={this.state.content} parent={this} key={"simple"} />;
        }
    }

    _get_select(){
        var options = Graph.get_labels()
        return <Select options={options} selected={this.state.type} onChange={this.on_change.bind(this, "type")} />
    }

    _get_account(){
        return <AccountSelect selected={this.state.account || []} onChange={this.on_change.bind(this, "account")} />
    }

    on_change(key, value){
        if(key=="type"){
            this.state.type = value;
            var graph =  Graph.get_descriptor(value);
            if(graph == undefined){
                alert("Le graph '"+value+"' n'est pas enregistré")
            }else{
                this.state.multiple = graph.multiple;
            }
        } else if(key=="period"){
            this.state.period = value;
        }else if(key=="options"){

            this.state.options = value;
        }else if(key=="account"){
            this.state.account = value;
        }else if(key=="name"){
            this.state.name = value;
        }else if(key==""){
        }else{
            throw "Error"
        }
        this.props.onChange && this.props.onChange(this.val())
        this.props.onFieldChange && this.props.onFieldChange(key, value)

        
        this.setState({})
    }

    on_content_delete(id){
        for(var i=0; i<this.state.content.length; i++){
            var obj = this.state.content[i];
            if(obj.id != id) continue;
            this.state.content.splice(i,1);
            break;
        }
        this.setState({});
    }

    on_content_change(data){
        var id =data.id;

        for(var i in this.state.content){
            var obj = this.state.content[i];
            if(obj.id != id) continue;
            this.state.content[i] = data;
            break;
        }
        this.setState({});
    }

    on_content_add(){
        var maxi = 0;
        for(var obj of this.state.content){
            if(obj.id>maxi) maxi=obj.id;
        }
        this.state.content.push({id: maxi+1})
        this.setState({});
    }

    on_validate(send=true){
        var self = this;
        var data = this.val();
        if(send){
            Api.update_or_create_graph(data,function(x){
                var graph = null, update = false, create = false;
                if(x.update){
                    graph = x.update.graph[0];
                    update = true;
                }else{
                    graph = x.create.graph[0];
                    create = true;
                }
                graph.operations = graph.operations.map(x => new Operation(x))
                Context.data.graphs[graph.id] = x;
                if(update) self.props.holder && self.props.holder.on_update(graph);
                else { 
                    self.props.holder.on_create(graph);
                }
            })
        }else{
            Context.data.graphs[this.state.id] = this.state;
            self.props.holder && self.props.holder.on_update(this.state);
        }

    }

    render(){

        var graph = Graph.get_descriptor(this.state.type);
        var OptClasse = graph.options;
        var options = null;
        var button = (this.props.hideButton)?null:(<CustomButton onClick={this.on_validate.bind(this)} >Valider</CustomButton>);
        if(OptClasse){
            if(Array.isArray(OptClasse)){
                OptClasse = new AutoOptions(OptClasse)
            }
            if(OptClasse instanceof AutoOptions){
                options = OptClasse.render(this.state.options, this.on_change.bind(this, "options"))
            }else{
                options = <OptClasse value={this.state.options} onChange={this.on_change.bind(this, "options")} />
            }
            
        }

        var name = null;
        if(!this.props.hideName){
            name=(<Field label="Nom"  className=" graph-field">
                <Entry placeholder="Nom du graph" value={this.state.name} onChange={this.on_change.bind(this, "name")} />
            </Field>)
        }
    
        return (<div className="graph">
            {name}

            <Field label="Type de graphe"  className=" graph-field">
                {this._get_select()}
            </Field>
            
            <Field label="Comptes"  className=" graph-field">
                {this._get_account()}
            </Field>

            <Field label="Contenu"  className=" graph-field">
                {this._get_graph_content()}
            </Field>
            
            <Field label="Options"  className=" graph-field">
                {options}
            </Field>
            <Field label="Période"  className=" graph-field">
                <DateRangePicker  value={this.state.period} onChange={this.on_change.bind(this, "period")} />
            </Field>
            {button}
        </div>)
    }
}

class GraphEditorModal extends Component {
    constructor(props){
        super(props);
    }

    render(){
        return (
            <ModalMedium
            isOpen={this.props.isOpen!=undefined?this.props.isOpen:true}
            onClose={this.props.onClose}
            >
                <div className="modal-header">
                    <h5>Edition du graph</h5>
                    <button type="button" className="btn-close" onClick={this.props.onClose}></button>
                </div>
                <div className="modal-body">
                    <GraphEditor holder={this.props.holder} value={this.props.value} />
                </div>
    
                <div className="modal-footer">
                    <CustomButton onClick={this.props.onClose}>Fermer</CustomButton>
                </div>
            </ModalMedium>
        );
    }
}

export {
    GraphEditor,
    GraphEditorModal
};
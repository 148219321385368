import { Component } from "react";
import "./numeric.css"
var _registered = {}
class Info {
    
    constructor(graph, index){
        this.title = null;
        this.sub_title = null;
        this.content = null;
        this.icon = null;
        this._index = index;
        this._config = graph.content[index];
        this.used_operations = null;
        this.original_data = graph.operations;

        this.init(this.filter(graph.operations));
    }

    filter(data){
        return data
    }

    init(data){
    }

    get_title(){
        return this.title
    }

    get_sub_title(){
        return this.sub_title
    }

    get_content(){
        return this.content
    }

    get_operations(){
        return this.used_operations;
    }

    set_operations(x){
        if(x.data){
            this.used_operations = x.data;
        }else{
            this.used_operations = x;
        }
    }

    onShowOps(){
        var ops = this.get_operations();
        window.show_modal_operations(this.used_operations);
    }

    _get_icon(){
        const icon = this.get_icon()
        var ops = this.get_operations();
        if(ops){
            return <a style={{cursor:"pointer"}} onClick={this.onShowOps.bind(this)}>{icon}</a>
        }
        return icon
    }

    get_icon(){
        return this.icon
    }


    static register(name, data){
        _registered[name] = data;
    }

    static get_info(name){
        return _registered[name];
    }

    static get_info_labels(){
        var ret = {}
        for(var [k,v] of Object.entries(_registered)) ret[k] = v.label
        ret[""] = "";
        return ret
    }

    static get_options(){
        return [
        ];
    }

    render(mode, n_per_line, key){
        var width = per_line_to_width[n_per_line];
        if(width === undefined){
            console.error("Erreur le nombre par ligne "+n_per_line+" n'est pas défini");
        }
        var args = {
            mode: mode,
            title: this.get_title(),
            sub_title: this.get_sub_title(),
            content: this.get_content(),
            icon: this._get_icon(),
            width: width,
            key: key
        }


        if(mode=="simple"){
            return <InfoWrapperSimple {...args}/>
        }else{
            return <InfoWrapperImage {...args}/>
        }
        console.error("Le mode '"+mode+"' est incoonu");
    }
}

const per_line_to_width = {
    1: 12,
    2: 6,
    3: 4,
    4: 3,
    6: 2,
    12: 12
}

class InfoWrapperSimple extends Component{
    constructor(props){
        super(props)
    }

    render(){
        return (<div className={"numeric-info-simple col-md-"+this.props.width}>
            <div className="numeric-info-simple-root">
                <span className="numeric-info-simple-header">
                    <div className="numeric-info-simple-title">{this.props.title}</div>
                    <div className="numeric-info-simple-sub-title">{this.props.sub_title}</div>
                </span>
                <span className="numeric-info-simple-value">
                    <span className="numeric-info-simple-content">{this.props.content}</span>
                </span>
            </div>
        </div>);
    }
}

class InfoWrapperImage extends Component{
    constructor(props){
        super(props)
    }

    render(){
        return (<div className={"numeric-info-image col-md-"+this.props.width}>

            <div className="numeric-info-simple-root">
                <span className="numeric-info-image-icon-holder">
                    <span className="numeric-info-image-icon">{this.props.icon}</span>
                </span>
                <span className="numeric-info-image-header">
                    <div className="numeric-info-image-title">{this.props.title}</div>
                    <div className="numeric-info-image-content">{this.props.content}</div>
                    <div className="numeric-info-image-sub-title">{this.props.sub_title}</div>
                </span>
            </div>
        </div>);
    }
}




export {
    Info
}

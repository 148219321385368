import { Context } from "context";
import { page_register } from "model/app/app_common";
import React, { Component } from "react";
import {CustomButton} from "widget/base/button";
import {Entry, Password, Hidden} from "widget/base/entry";

import './login.css'

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  submit(){
    window.document.getElementById('login_form').submit()
  }

  render() {
    var error = window.data.error?(<p className="error_message">{window.data.error}</p>):null;


    return (<main >
          <div className="root">
            <p>
                Page de connexion Connexion : Merci de vous connecter
            </p>
            {error}
            <form action="/auth?redirect=/" method="post" id="login_form">
              <div className="login-field login-name">
                <Entry name="login" placeholder="Identifiant"/>
              </div>

              <div className="login-field login-password">
                <Password name="password" placeholder="Mot de passe" onValidate={this.submit.bind(this)}/>
              </div>
              <div className="login-field login-submit">
                <CustomButton onClick={this.submit.bind(this)}>Se connecter</CustomButton>
              </div>
              
              </form>
        
          </div>
</main>)
  }

}


page_register("login",{
  page: (() => <Login {...Context.data} />)
})


export { Login };
import { cast_float_montant } from "model/common/utils"
import { CheckBox, Icon, Select } from "widget";
import { CategoryIncludeExcludeField, CheckBoxField, FloatField, IntField, MultipleAccountField, OperationTypeIncludeExcludeField, SimpleAccountField } from "widget/autorenderer/autorenderer";
import { HabitudesUtils } from "../habitudes";
import { Filters } from "../utils/filter";
import { Info } from "./info"




class InfoObjectif extends Info {


    get_objectifs_today(data){
        var habitudes = HabitudesUtils(data, this._config);
        var start = habitudes.habitudes.start
        var now = new Date()
    }   

    filter(data){
        data = Filters.current_month(true, data);
        data = Filters.virtual(this._config.virtual, data);
        data = Filters.operation_types(this._config.operation_types, data);
        return Filters.categories(this._config.filter_categories, data);
    }

    init(data){
        var now = new Date()
        var date_start = new Date()
        var revenu = this._config.revenu || 0;
        if(true){
            date_start = new Date(date_start.getFullYear(), date_start.getMonth(), 1, 0, 0, 0, 0);
        }else{
            date_start.setDate(date_start.getDate()-31);
        }

        var objectifs = parseFloat(this._config.fixed_charges)+(now.getDate()-1)*parseFloat(this._config.daily_charges)
        
        var hier = new Date();
        hier.setDate(hier.getDate()-1)

        //data = data.filter_categories_include_exclude(this._config.filter_categories);
    

        this.set_operations(data)
        var montant = cast_float_montant(data.sum("montant"))
        var val = cast_float_montant(objectifs+montant)
        if(val>0){
            this.content = <span style={{color: "#00ff00"}}>{val} €</span>
        }else{
            this.content = <span style={{color: "#ff0000"}}>{val} €</span>
        }
        this.title = "Par rapport à l'objectifs ("+objectifs+"€) "+(!this._config.virtual?" (hors provisions)":"");
        var content = null;

        if(now.getMonth()==hier.getMonth()){
            var tendance_data = data.filter(x => (x.get_date() >= date_start && x.get_date() <= hier), "filtered" )
            var hier_objectifs = objectifs - this._config.daily_charges;
            var hier_montant = cast_float_montant(tendance_data.sum( "montant" ))
            var hier_val =  cast_float_montant(hier_objectifs+hier_montant);
            if(montant>hier_val){
                content = (<span style={{color: "#00ff00"}}><Icon icon="trending_up"/>{hier_val-val}€</span>)
            }else if (montant<hier_val){
                content = (<span style={{color: "#ff0000"}}><Icon icon="trending_down"/>{hier_val-val}€</span>)
            }else{
                content = (<span><Icon icon="trending_flat"/>{hier_val-val}€</span>)
            }
        }
        this.sub_title = <span>{(-montant)+"€ dépensé. "}{content}</span>;

    }

    get_icon(){
        return <div><Icon icon="timeline" style={{fontSize: "60pt"}}/></div>
    }

    static get_options(){
        return [
            new MultipleAccountField("Compte", "Compte", null),
            new FloatField("fixed_charges", "Charges fixes", 900),
            new FloatField("daily_charges", "Charges journalière", 20),
            new CheckBoxField("virtual", "Opération virtuelles", true),
            new CategoryIncludeExcludeField("filter_categories", "Catgégories"),
            new OperationTypeIncludeExcludeField("operation_types", "Types d'opération", {}),
        ];
    }
}

Info.register("objectif", {
    classe: InfoObjectif,
    label: "Objectif"
})
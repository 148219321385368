
import {Bar, Line} from 'react-chartjs-2';
import { Context } from 'context';
import { CategoryIncludeExcludeField, SelectField } from 'widget/autorenderer';
import { OperationTypeIncludeExcludeField } from 'widget/autorenderer/autorenderer';
const { Component } = require("react");
const { DataEntry, Operation, QuerySet } = require("../queryset");
const { Graph, colors_alpha, Value } = require("./base");

class ProduitChargeBar extends Graph {
    init(){
        this._grouped_data = []
        var cats = {}
        for(var op of this.operations.data){
            var cat = -1;
            op = this.filter_operation(op)
            if(op==null) continue
            if(op.category){
                cat = op.category;
            }

            if(cats[cat] === undefined){
                cats[cat] = new DataEntry(cat);
            }
            cats[cat].add(op);
        }
        for(var k in cats){
            this._grouped_data.push(cats[k]);
        }
        this._grouped_data = cats;
    }

    on_click(graph, evt){
        var x = this.get_dataset_by_event(evt);
        graph.props.parent.show_operations(x.operations)
    }


    compute_data(){
        var datasets = []
        var sum_prod = 0;
        var sum_neg = 0;


            
        for(var [cat, values] of Object.entries(this._grouped_data)){
            var produit = new Value();
            var charges = new Value();
            if(this.options.synthese=="abs"){
                
                produit=Value.from_abs_operations(values.data, (x => x.montant>=0));
                charges=Value.from_abs_operations(values.data, (x => x.montant<0));

                sum_prod+=produit.value
                sum_neg+=charges.value
            }else{
                var tmp = Value.from_abs_operations(values.data);
                if(cat==-1){
                    produit=Value.from_abs_operations(values.data, (x => x.montant>=0));
                    charges=Value.from_abs_operations(values.data, (x => x.montant<0));
                }else{
                    if(values.sum()>=0) produit=tmp;
                    else charges=tmp;
                }
                sum_prod+=produit.value
                sum_neg+=charges.value
            }
            cat = Context.data.categories.get(cat);
            cat = (cat)?cat.name:"Sans catégorie"
            datasets.push({
                label:  cat,
                data: [produit, charges],
                backgroundColor: colors_alpha[datasets.length % colors_alpha.length]
            })
        }

        var data = {
            labels: ["Produits ("+sum_prod+" €)", "Charges ("+sum_neg+" €)"],
            datasets: datasets
        }
        
        return data;
    }
}

class ProduitChargeBarGraph extends Component {
    render() {
        return (
          <div>
            <Bar id={"graph-"+this.props.graph.id}
                options ={{
                responsive: true, 
                scales: {  x: { stacked: true }, y: { stacked: true}},
                onClick: this.props.graph.on_click.bind(this.props.graph, this),
                parsing: {yAxisKey: 'value', xAxisKey: 'value'},
              }}
              data={this.props.data}
            />
          </div>
        );
      }
}


Graph.register("produi_charge_bat", {
    data: ProduitChargeBar,
    label: "Produit/Charges bar",
    graph: ProduitChargeBarGraph,
    options: [
        new SelectField("synthese", "Calcul", {
            sum: "Somme des categories",
            abs: "Valeur absolue",
        }, "abs"),
        new CategoryIncludeExcludeField("filter_categories", "Catégories", {
            categories: [], include: false
        }),

        new OperationTypeIncludeExcludeField("operation_types", "Types d'opération", {}),
    ]
})


class ComputeUtils {
    constructor(operations, options){
        this.operations = operations;
        this.options = options;
        this.pre_init();
        if(this.options.filter_categories){
            this._filtered_categories = {}
            for(var cat of this.options.filter_categories.categories){
                this._filtered_categories[cat] = true;
            }
        }
        this.post_init();

    }

    pre_init(){

    }

    post_init(){

    }

    get_today(){
        return new Date();
    }

    get_nb_jour_in_month(date=null){
        date = date || this.get_today();
        var end_of_month = new Date(date)
        end_of_month.setMonth(end_of_month.getMonth()+1);
        end_of_month.setDate(0)
        return end_of_month.getDate();
    }

    filter_operation(op){
        if(op && this._filtered_categories){
            if(this.options.filter_categories.include=="true" || this.options.filter_categories.include==true){
                op = this._filtered_categories[op.category]?op:null;
            }else{
                op = this._filtered_categories[op.category]?null:op;
            }
        }
        return op;
    }
}

export {
    ComputeUtils
}
class ParserError extends Error {
    constructor(parser, message){
        super("ParserError:"+parser.lex.index+": "+message)
        this.parser = parser.lex;
        this._message = message;
    }
}

class LexerError extends Error {
    constructor(lex, message){
        super("LexerError:"+lex.index+": "+message)
        this.lex = lex;
        this._message = message;
    }
}

class RuntimeExpressionError extends Error {
    constructor(env, op, message){
        super("RuntimeExpressionError: '"+op.toString()+"' :"+message)
        this.op = op;
        this._message = message
        this._env =env;
    }
}



class _Functions {
    sum(ctx, args){

        return args.length?args.reduce((p, c) => p+c, 0):0;
    }

    cat(ctx, x, ...args){
        return x.concat(...args);
    }

    filter(ctx, ...args){
        const [data, fct] = args;
        var ret = data.filter(x => fct.call(ctx, x));
        return ret;
    }

    map(ctx, ...args){
        const [data, fct] = args;
        return data.map(x => fct.call(ctx, x));
    }

    abs(ctx, x){
        return x.map(a => Math.abs(x));
    }

    min(ctx, x, key=null){
        if(key)
            x.sort((a,b) => a[key]>b[key])
        else
            x.sort()
        return x.length?x[0]:0
    }

    max(ctx, x, key=null){
        if(key)
            x.sort((a,b) => a[key]>b[key])
        else
            x.sort()
        return x.length?x[x.length-1]:0
    }

    last(ctx, x, key){
        return this.max(ctx, x, "id")
    }

    first(ctx, x, key){
        return this.min(ctx, x, "id")
    }

}

var Context = { data: { categories: { get: x=> ({ name: x})}} } 

var Functions = new _Functions();

class Element {

    constructor(){

    }

    toString(){

    }

    execute(context){

    }



    error(env, msg){
        return new RuntimeExpressionError(env, this, msg)
    }

}

class Operation extends Element {

    constructor(operation, operandes){
        super(operation, operandes)
        this.operation = operation
        this.operandes = operandes
    }

    toString(){
        return "("+this.operandes.map(x => x.toString()).join(this.operation)+")"
    }

    execute(context){
    }

}

class Membre extends Operation {
    constructor(a,b){
        super('.', [a, b]);
    }

    execute(context){
        var [base, member] = this.operandes;
        base = base.execute(context);
        if(Array.isArray(base)){
            return base.map(x => x[member]);
        }
        return base[member];
    }
}


class Function extends Operation {
    execute(context){
        const fct = Functions[this.operation]
        var args = this.operandes;
        args=this.operandes.map(x => x.execute(context));
        var ret = fct(context, ...args);
        return ret;
    }


    toString(){
        return this.operation+"("+this.operandes.map(x => x.toString()).join(", ")+")"
    }
}

class BinaryOperation extends Operation{
    constructor(s, opa, opb, fct){
        super(s, [opa, opb])
        this.fct = fct;
    }

    execute(context){
        var [a, b] = this.operandes.map(x => x.execute(context));
        return this.fct(a,b);
    }

}

class Soustraction extends BinaryOperation { constructor(a, b){super("-", a,b, (x,y) => x-y)} }
class Addition extends BinaryOperation { constructor(a, b){super("+", a,b, (x,y) => x+y)} }
class Multiplication extends BinaryOperation { constructor(a, b){super("*", a,b, (x,y) => x*y)} }
class Divisiion extends BinaryOperation { constructor(a, b){super("/", a,b, (x,y) => x/y)} }
class Modulo extends BinaryOperation { constructor(a, b){super("%", a,b, (x,y) => x % y)} }
class Gt extends BinaryOperation { 
    constructor(a, b){
        super(">", a,b, (x,y) => x > y)
    } 
}
class Gte extends BinaryOperation { constructor(a, b){super(">=", a,b, (x,y) => x >= y)} }
class Lt extends BinaryOperation { constructor(a, b){super("<", a,b, (x,y) => x < y)} }
class Lte extends BinaryOperation { constructor(a, b){super("<=", a,b, (x,y) => x <= y)} }
class Eq extends BinaryOperation { constructor(a, b){super("==", a,b, (x,y) => x == y)} }
class Dif extends BinaryOperation { constructor(a, b){super("!=", a,b, (x,y) => x != y)} }
class Or extends BinaryOperation { constructor(a, b){super("||", a,b, (x,y) => x || y)} }
class And extends BinaryOperation { constructor(a, b){super("&&", a,b, (x,y) => x && y)} }

function operation_get_comparaison(op){
    if(op==">") return  Gt;
    if(op==">=") return Gte;
    if(op=="<") return  Lt;
    if(op=="<=") return Lte;
    if(op=="==") return Eq;
    if(op=="!=") return Dif;
    return null;
}

function mult_get_comparaison(op){
    if(op=="*") return Multiplication;
    if(op=="/") return Divisiion;
    if(op=="%") return Modulo;
    return null;
}

function add_get_comparaison(op){
    if(op=="+") return Addition;
    if(op=="-") return Soustraction;
    return null;
}


class DataArray extends Element {
    constructor(name){
        super();
        this.name = name;
    }

    toString(){
        return "["+this.name+"]";
    }

    execute(context){
        return context.get(this.name)
    }

}



class Variable extends Element {
    constructor(name, index){
        super();
        this.name = name;
        this.index = index;
    }

    toString(){
        return this.name;
    }

    execute(context){
        return context.resolve(this.index)
    }

}


class Const extends Element {
    constructor(value){
        super();
        this.value = value;
    }

    toString(){
        return this.value;
    }

    execute(context){
        return this.value
    }

}

class ConstString extends Const {

    toString(){
        return "'"+this.value+"'";
    }
}


class Lambda extends Operation {
    constructor(args, content){
        super("lambda", [args, content])
        this.args=args;
        this.content = content;

    }

    toString(){
        return "lambda("+this.args.join(", ")+"{"+this.content.toString()+"}"
    }

    execute(ctx){
        return this;
    }

    call(context, ...args){
        context.push(args);
        var ret = this.content.execute(context);
        context.pop();
        return ret;
    }
}

class Expression {
    constructor(root, valid=true){
        this.root = valid?root:null;
        this.error = valid?null:root;
        this.valid=valid

    }


    execute(context){
        if(!this.valid) return null;
        try{
            return this.root.execute(context)
        } catch (e){
            if(e instanceof RuntimeExpressionError){
                return e;
            }
            throw e;
        }
    }

    toString(){
        return this.valid?this.root.toString():this.error.message;
    }

}

const LEX_NULL="null"
const LEX_EOF="eof"
const LEX_DATA_ARRAY="var"
const LEX_PLUS="+"
const LEX_MOINS="-"
const LEX_OPEN="("
const LEX_CLOSE=")"
const LEX_VIRGULE=","
const LEX_POINT="."
const LEX_INDENT="indent"
const LEX_STRING="str"
const LEX_NUMBER="number"
const LEX_MULT="*"
const LEX_DIV="/"
const LEX_MOD="%"
const JS_OPEN="{{"
const JS_CLOSE="}}"
const LEX_ACC_OPEN="{"
const LEX_ACC_CLOSE="}"
const LEX_EQ="=="
const LEX_GT=">"
const LEX_GTE=">="
const LEX_LT="<"
const LEX_LTE="<="
const LEX_DIF="!="
const LEX_NOT="!"
const LEX_OR="||"
const LEX_AND="&&"
const INDENT_CHAR_0="ABCDEFGHIJKLMNOPQRSTUVWXYZabdecefghijklmnopqrstuvwxyz_"
const INDENT_CHAR="ABCDEFGHIJKLMNOPQRSTUVWXYZabdecefghijklmnopqrstuvwxyz_0123456789"
const NUMBER_CHAR_0 = "0123456789"
const NUMBER_CHAR = "0123456789."

class Lexer {
    constructor(data){
        this.data= data;
        this.current = null;
        this.value = null;
        this.index = 0;
        this.c=null;
    }

    error(message){
        return new LexerError(this, message)
    }

    sep(){
        while(this.index<this.data.length && this.data[this.index]==" ") 
            this.index++;
    }

    _set(curr, value){
        this.current = curr;
        this.value = (value!=undefined)?value:curr;
        this.index++;
        return this.current
    }


    next(){
        this.sep();
        if(this.index>=this.data.length){
            this.current = LEX_EOF;
            this.value = "";
            return this.current
        }

        switch(this.data[this.index]){
            case '-': return this._set(LEX_MOINS)
            
            case '+': return this._set(LEX_PLUS)
            
            case '(': return this._set(LEX_OPEN)
                
            case ')': return this._set(LEX_CLOSE)

            case '.': return this._set(LEX_POINT)

            case ',':  return this._set(LEX_VIRGULE)

            case '{': return this._set(LEX_ACC_OPEN)

            case '}': return this._set(LEX_ACC_CLOSE)
            
            case '*': return this._set(LEX_MULT)
            
            case '/': return this._set(LEX_DIV)
            
            case '%': return this._set(LEX_MOD)

            case '=':
            case '>':
            case '<':
            case '!':
                var c = this.data[this.index++];
                var next = this.data[this.index+1];
                if(next=="="){
                    c+=next;
                    this.index++;
                }
                switch(c){
                    case "==": return this._set(LEX_EQ);
                    case "!=": return this._set(LEX_DIF);
                    case ">": return this._set(LEX_GT);
                    case ">=": return this._set(LEX_GTE);
                    case "<": return this._set(LEX_LT);
                    case "<=": return this._set(LEX_LTE);
                    case "!": return this._set(LEX_NOT);
                    default:
                        throw this.error(" lex '"+c+"' unknown")
                }

            case '&':
            case '|':
                var c = this.data[this.index++];
                var next = this.data[this.index+1];
                if(c==next){
                    c+=next;
                    if(c=="||"){
                        this._next();
                        return LEX_OR;
                    }
                    if(c=="&&"){
                        this._next();
                        return LEX_OR;
                    }
                }
                throw this.error(" lex '"+c+"' unknown")




            
            case '[':{
                var name = "";
                this.index++;
                while(this.index<this.data.length && this.data[this.index]!=']') name+=this.data[this.index++];
                if(this.index<this.data.length) this.index++;
                this.current = LEX_DATA_ARRAY;
                this.value = name;
                return this.current;
            }

            case '"':
            case "'":
                var end = this.data[this.index++];
                var v = "";
                while(this.index<this.data.length && this.data[this.index]!=end) v+=this.data[this.index++];
                this.index++;
                this.current = LEX_STRING;
                this.value = v;
                return this.current


            default:{
                if(INDENT_CHAR_0.indexOf(this.data[this.index])>=0){
                    var name = "";
                    while(this.index<this.data.length && INDENT_CHAR.indexOf(this.data[this.index])>=0) name+=this.data[this.index++];
                    this.current = LEX_INDENT;
                    this.value = name;
                    return this.current;
                }else if(NUMBER_CHAR_0.indexOf(this.data[this.index])>=0){
                    var content = "";
                    while(this.index<this.data.length && NUMBER_CHAR.indexOf(this.data[this.index])>=0) content+=this.data[this.index++];
                    this.current = LEX_NUMBER;
                    this.value = parseFloat(content);
                    return this.current
                }
                throw this.error(" le caractère '"+this.data[this.index]+"' ne correspond pas un début de lexem")
            }

        }

    }

}


class Parser {

    constructor(data){
        this.lex = new Lexer(data);
        this.current = null;
        this.value = null;
        this.table = []
    }

    resolve(x){
        if(this.table.length==0) return -1;
        if(x in this.table[this.table.length-1])
            return this.table[this.table.length-1][x];
        return -1;
    }
    
    pop(){
        this.table.pop();
    }

    push(args){
        var ret = {};
        for(var i in args) ret[args[i]]=i; 
        this.table.push(ret)
    }

    error(msg){
        return new ParserError(this, msg)
    }

    _next(){
        this.current = this.lex.next()
        this.value = this.lex.value;
        return this.current;
    }

    parse(){

        try{
            this._next();
            return new Expression(this._root());
        } catch (e){
            if(e instanceof ParserError || e instanceof LexerError){
                return new Expression(e, false);
            }
            throw e;
        }
    }

    _root(){
        return this._or();
    }

    _or(){
        var v1 = this._and();

        if(this.current!=LEX_AND)
            return v1;

        this._next();

        var v2 = this._or();
        return new Or(v1,v2)
    }

    _and(){
        var v1 = this._comp();

        if(this.current!=LEX_AND)
            return v1;

        this._next();

        var v2 = this._and();
        return new And(v1,v2)
    }

    _comp(){
        var v1 = this._add_sub();

        var op = operation_get_comparaison(this.current);
        if(!op) return v1;

        this._next();

        var v2 = this._comp();
        return new op(v1,v2)
    }

    _add_sub(){
        var v1 = this._mult_div_mod();

        var op = add_get_comparaison(this.current);
        if(!op) return v1;

        this._next();

        var v2 = this._add_sub();
        return new op(v1,v2)
    }



    _mult_div_mod(){
        var v1 = this._member();

        var op = mult_get_comparaison(this.current);
        if(!op) return v1;
        
        this._next();

        var v2 = this._mult_div_mod();
        return new op(v1,v2)
    }

    _member()
    {
        var v1 = this._prim();
        if(this.current!=LEX_POINT) return v1;
        this._next();
        if(this.current==LEX_INDENT){
            var name = this.value;
            this._next();
            return new Membre(v1, name);

        }else{
            throw this.error("INDENT attendu")
        }
    }

    _prim(){
        switch(this.current){
            case LEX_DATA_ARRAY:
                return this._data_array();
            case LEX_STRING:
                return this._const_string();
            case LEX_NUMBER:
                return this._const_number();
            case LEX_OPEN:
                return this._parenthesis()
            case LEX_INDENT:{
                var name = this._indent();
                if(name=="lambda") 
                    return this._lambda();
                switch(this.current){
                    case LEX_OPEN:
                        return this._function(name)
                    default:
                        return this._variable(name);
                }
            }

            default:
                throw this.error(" CONST, INDENT ou VARIABLE attendu, trouvé '"+this.current+"' : '"+this.value+"'")

        }

    }

    _data_array(){
        var v = new DataArray(this.value);
        this._next();
        return v;
    }

    _const_string(){
        var v = new ConstString(this.value);
        this._next();
        return v;
    }

    _const_number(){
        var v = new Const(this.value);
        this._next();
        return v;
    }

    _variable(name){
        var index = this.resolve(name);
        if(index<0){
            throw this.error("symbole '"+name+"' indéfini")
        }
        return new Variable(name, index);
    }

    _parenthesis(){
        this._next();
        var  op = this._root();
        if(this.current!=LEX_CLOSE){
            throw this.error("')' attendu, trouvé '"+this.current+"' '"+this.lex.data[this.lex.index]+"'")
        }
        this._next()
        return op
    }

    _function(name){
        var args = [];
        var fct = Functions[name];
        if(fct===undefined){
            throw this.error("la fonction '"+name+"' n'est pas définie");
        }
    
        this._next();

        if(this.current==LEX_CLOSE)
            return Function(name, [])

        while(true){
            args.push(this._root());
            if(this.current==LEX_CLOSE){
                this._next();
                break;
            }
            else if(this.current==LEX_VIRGULE)
            {
                this._next();
            }
            else
            {
                throw this.error("')' ou ',' attendu")
            }
        }
        return new Function(name, args);
    }

    _lambda(){
        var args = [];
        var content = null;
        
        if(this.current!=LEX_OPEN) 
            throw this.error("'(' attendu")
        
        this._next();

        if(this.current!=LEX_CLOSE){    
            while(true){
                args.push(this._indent());
                if(this.current==LEX_CLOSE){
                    this._next();
                    break;
                }
                else if(this.current==LEX_VIRGULE)
                {
                    this._next();
                }
                else
                {
                    throw this.error("')' ou ',' attendu")
                }
            }
        }

        if(this.current != LEX_ACC_OPEN){
            this.error(" '{' attendu apres une déclaration de lambda")
        }
        this._next()
        this.push(args)
        content = this._root();
        this.pop();
        if(this.current != LEX_ACC_CLOSE){
            this.error(" '}' attendu apres une déclaration de lambda")
        }
        this._next()

        return new  Lambda(args, content)
    }

    _indent(){
        if(this.current!=LEX_INDENT){
            throw this.error("INDENT attendu")
        }
        var v = this.value;
        this._next();
        return v;
    }

    
}

function parse(x){
    return (new Parser(x)).parse()
}

/*
class OpStub {
    constructor(m, c){
        this.montant = m;
        this.solde = null;
        this.category = c;
    }
}
function _op(args){ return args.map(x =>  new OpStub(...x)) }

class DataEntry {
    constructor(label){
        this.data= []
        this.label = label
        this.categories = {}
    }

    sum(key="montant"){
        return this.data.reduce((p, c) => p+c[key], 0)
    }

    add(y){
        this.data.push(y);
        var cat_name = "";
        var cat = Context.data.categories.get(y.category);
        if(cat) cat=cat.name;

        if(!(cat in this.categories)) this.categories[cat]=[]
        this.categories[cat].push(y)
    }


    get(x){
        if(x=="") return this.data;
        if(x=="!") return this.categories[null] || []
        return this.categories[x] || []

    }

}
*/


class Environemnent {
    constructor(dataentry){
        this.stack=[]
        this.table=[]
        this.entry = dataentry;
    }

    get(x){
        return this.entry.get(x); 
    }

    error(x){
        return RuntimeExpressionError()
    }

    resolve(v){
        return this.stack[this.stack.length-1][v]
    }


    push(...x){
        this.stack.push(x)
    }

    pop(){
        this.stack.pop();
    }
}

export {
    Environemnent,
    parse
};
/*
var s = new Environemnent();


var ex = parse("sum(filter([], lambda(x){ x.montant > 0} ).montant)")
if(ex)
//var ex = parse("[].montant")

export {
  )  parse
};*/
import React, { Component } from "react";
import "banque/css/common.css"
import {Api} from "banque/api/api"
import { Model } from "banque/model/utils";
import { CustomButton, Entry, Float, Form, ModalLarge, ModalMedium, ModalSmall, MultipleSelect, Select, Validation } from "widget";
import { Context } from "context";
import { AbsComponent } from "widget/base/base";
import { cast_float_montant } from "model/common/utils";


class CreateList extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

        this.ref_id = React.createRef();
        this.ref_name = React.createRef();
        this.ref_description = React.createRef();
        this.ref_budget = React.createRef();
  }

  val(){
    var def = this.props.value || {};
    var budget_val = parseFloat(this.ref_budget.current.val());
    return {
        id : this.ref_id.current.val() || def.id,
        name: this.ref_name.current.val() || def.name,
        description: this.ref_description.current.val() || def.description,
        budget: (isNaN(budget_val)?null:budget_val)
    };
  }

  on_valid(){
    this.props.onValidate && this.props.onValidate(this.val())
  }

  render() {
    var fields = [
        { name: "id", visible : false, field : <Entry value={this.state["id"]} defaultValue={this.props.value.id || ""}  key={-41} ref={this.ref_id} /> },
        { name: "name", label : "Nom", field : <Entry value={this.state["name"]} key={0} defaultValue={this.props.value.name || ""}  ref={this.ref_name}/> },
        { name: "description", label : "Description", field : <Entry value={this.state["description"]} defaultValue={this.props.value.description || ""}   key={1} ref={this.ref_description} /> },
        { name: "budget", label: "Budget", field : <Float value={this.state.budget} defaultValue={this.props.value.budget || ""} ref={this.ref_budget}/> }
    ];

    return ( <Form content={fields}>
        {this.props.onValidate?<CustomButton onClick={this.on_valid.bind(this)}>Valider</CustomButton>:null}
    </Form>)
  }
}


class CreateListModal extends Component {
    constructor(props){
        super(props);
        this.state={
            show: props.show || false,
            value: props.value || {}
        }
        this.create_ref = React.createRef();
    }

    on_cancel(){
        this.state.show=false;
        this.props.onClose && this.props.onClose();
        this.props.onCancel && this.props.onCancel();
    }

    send(data){
        this.props.parent.on_send(this.create_ref.current.val());
    }

    on_close(){
        this.state.show=false;
        this.props.onClose && this.props.onClose();
    }

    render(){
        return (
        <ModalSmall
            title="Editer une liste"
            onClose={this.on_close.bind(this)}
             value={this.props.value}
            isOpen={this.state.show}>

            <div className="modal-header">
                <h5>Créer une liste</h5>
                <button type="button" className="btn-close" onClick={this.on_close.bind(this)}></button>
            </div>
            <div className="modal-body">
                <CreateList value={this.props.value} parent={this} ref={this.create_ref}/>
            </div>
            <div className="modal-footer">
                <CustomButton onClick={this.send.bind(this)}>Envoyer</CustomButton>
                <CustomButton onClick={this.on_close.bind(this)}>Annuler</CustomButton>
            </div>

        </ModalSmall>
        )
    }
}


class ListThumbnail extends Component {
    constructor(props) {
    super(props);
    this.state = {
        id: this.props.id,
        name: this.props.name,
        description: this.props.description,
        operations: this.props.operations
    };
  }


  on_edit(){
    this.props.parent.on_edit(this)
  }

  on_remove(){
    this.props.parent.on_remove(this.state)
  }

  show_ops(liste){
    var tmp = liste.operations.map(x => Context.data.operations.get(x));
    return tmp
    }

  render() {
    return ( <tr className="list-thumbnail">
        <td>{this.props.name}</td>
        <td>{this.props.description}</td>
        <td>{cast_float_montant(this.props.budget)}</td>
        <td>{cast_float_montant((this.props.budget || 0)+this.props.sum)}</td>
        <td className="list-thumbnail-action-holder">
            <a onClick={this.on_edit.bind(this)}><i className="bi bi-pencil list-thumbnail-action"></i></a>
            <a onClick={this.on_remove.bind(this)}><i className="bi bi-trash2 list-thumbnail-action"></i></a>
            <i className={"bi bi-list list-thumbnail-action"} onClick={() => window.show_modal_operations(this.show_ops(this.props))}/>
        </td>
    </tr>)
  }
}


class ListThumbnailList extends Component {

  constructor(props) {
    super(props);
    this.state = {
        lists: this.props.lists || window.data.lists,
        show_modal: null,
        remove: null,
        current_list: {}
    };
  }


    on_edit(data){
        this.setState({show_modal: true, current_list: data.state})
    }

    on_send(data, x ,y, z){
        var self = this;
        if(data.id && data.id != ""){
            var cat = this.state.lists.get(data.id);
            if(cat){
                Api.edit_list(data, function(_){
                        self.state.lists.edit(cat);
                        self.state.show_modal=false;
                        self.setState({});
                    })
            }
        }else{
            Api.create_list(data, function(data){
                self.state.show_modal=false;
                if(data && data.create && data.create.list){
                    for(var i in data.create.list){
                        self.state.lists.add(data.create.list[i]);
                    }
                }
                self.setState({});

            })
        }

    }

    on_remove(data){
        this.setState({remove: data})
    }

    on_remove_validated(){
        var data = this.state.remove;

        var self = this;
        Api.remove_list(data, function(data){
            self.state.show_modal=false;
            if(data && data["delete"] && data["delete"].list){
                var cats = data["delete"].list;
                for(var i=0; i<cats.length; i++){
                    self.state.lists.remove(cats[i])
                }
            }
            self.setState({remove: null});
        })
    }

    on_remove_cancel(){
        this.setState({remove: null})
    }

    add_list(){
        this.setState({show_modal: true, current_list: {}})
    }

    on_modal_close(){
        this.setState({show_modal: false, current_list: {}})
    }

  render() {
    var liste = [];
    if(Array.isArray(this.state.lists)){
        for(var i in this.state.lists){
            liste.push(<ListThumbnail {...this.state.lists[i]} parent={this} key={this.state.lists[i].id} />)
        }
    }else{
        this.state.lists.foreach(x => liste.push(<ListThumbnail {...x} parent={this} key={x.id} />))
    }


    var validate_remove = this.state.remove!=null?(<Validation
            title="Supprimer"
            onCancel={this.on_remove_cancel.bind(this)}
            onValidate={this.on_remove_validated.bind(this)}> Voulez vous vraiment supprimer cette liste ?
            </Validation>):null;

    return (
        <div>
        <CustomButton onClick={this.add_list.bind(this)} > Ajouter une liste </CustomButton>
        {this.state.show_modal?<CreateListModal value={this.state.current_list} parent={this} show={this.state.show_modal} onClose={this.on_modal_close.bind(this)}/>:null}
        {validate_remove}
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Nom</th>
              <th scope="col">Description</th>
              <th scope="col">Budget</th>
              <th scope="col">Restant</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
          {liste}
          </tbody>
      </table>
      </div>)
  }
}


function ListManagerModal(props){
    return (
        <ModalMedium
        isOpen={props.isOpen!=undefined?props.isOpen:true}
        onClose={props.onClose}
        >
            <div className="modal-header">
                <h5>Gérer les listes</h5>
                <button type="button" className="btn-close" onClick={props.onClose}></button>
            </div>
            <div className="modal-body">
                <ListThumbnailList />
            </div>

            <div className="modal-footer">
                <CustomButton onClick={props.onClose}>Fermer</CustomButton>
            </div>
        </ModalMedium>
    );
}

class ListSelect extends Component {
    constructor(props){
        super(props);
        this.state={
            selected: this.props.selected || []
        }
    }

    _get_options(){
        var ret = {"" : "(Aucune)"}
        if(this.props.lists){
            for(const cat of this.props.lists){
                var c = Context.data.lists.get(cat);
                ret[c.id]=c.name;
            }
        }else{
            Context.data.lists.get_array().map(x => ret[x.id]=x.name)
        }
        return ret
    }

    render(){
        return (
            <MultipleSelect options={this._get_options()}
                            selected={this.state.selected}
                            className={this.props.className || "btn-list"}
                            {...this.props} />
        )
    }

}

class ListSelectIncludeExclude extends AbsComponent {
    OPTIONS={
        true: "Inclure",
        false: "Exclure"
    }
    constructor(props){
        super(props);
        this.set_default("include", false);
        this.set_default("lists", []);
    }


    render(){
        return (
            <span className="lists-inc-excl">
                <ListSelect lists={Model.get_lists_names()}  selected={this.state.lists} onChange={this.bind("lists")} />
                <Select options={this.OPTIONS} onChange={this.bind("include")}></Select>
            </span>
        )
    }
}



class FilterList extends Component {
    constructor(props){
        super(props);
        this.state = {
            list: null,
            value: {}
        }
    }

    get_operations_ids(){
        var x =  this.props.selected || [this.props.operation];
        return x.map(a => a.id);
    }

    on_choose_add(liste){
        var self = this;

        Api.list_add_operations(liste.id, this.get_operations_ids(), function(data){
            //self.props.parent.close_filter();
            Context.data.lists.edit(data);
            self.setState({})
        })
       
    }

    on_choose_remove(liste){
        var self = this;
        Api.list_remove_operations(liste.id, this.get_operations_ids(), function(data){
            //self.props.parent.close_filter();
            Context.data.lists.edit(data);
            self.setState({})
        })
       
    }

    set_value(data){
        var self = this;
        var cat = Context.data.lists.get(this.state.lists.id);
        if(cat!=null){
            Api.edit_list(cat, function(_){
                self.setState({list: null})
            })
        }else{
            console.error("Erreur la liste "+this.state.list.id+" est introuvable", this.state.list)
        }
    }

    show_ops(liste){
        return liste.operations.map(x => Context.data.operations.get(x))
    }

    render(){
        var lists = [];
        for(var x of Context.data.lists.get_array()){
            var icon = "plus";
            var callback = this.on_choose_add.bind(this, x);
            for(var op of this.get_operations_ids()){
                if(x.operations.indexOf(op)>=0){
                    icon="dash"
                    callback = this.on_choose_remove.bind(this, x);
                    break;
                }
            }
            lists.push((<tr key={x.id}>
                <td>{x.name}</td>
                <td>
                    <i className={"bi bi-"+icon+" list-thumbnail-action"} onClick={callback}/>
                    <i className={"bi bi-list list-thumbnail-action"} onClick={() => window.show_modal_operations(this.show_ops(x))}/>
                </td>
            </tr>))
        }

        return (
            <ModalSmall
            title={"Choisir une liste ("+this.get_operations_ids().length+" opérations)"}
            onClose={this.props.onClose}
            isOpen={true}
            >


            <div className="modal-header">
                <h5>{"Choisir une liste ("+this.get_operations_ids().length+" opérations)"}</h5>
                <button type="button" className="btn-close" onClick={this.props.onClose}></button>
            </div>
            <div className="modal-body">
                <table className="table table-striped">
                    <tbody>
                        {lists}
                    </tbody>
                </table>
            </div>
              <div className="modal-footer">
                 <CustomButton onClick={this.props.onClose}>Annuler</CustomButton>
              </div>

            </ModalSmall>
        )
    }
}


export {
    CreateList,
    CreateListModal,
    ListThumbnailList,
    ListManagerModal,
    ListSelect, 
    ListSelectIncludeExclude,
    FilterList
}

import {Bar, Line} from 'react-chartjs-2';
import { Context } from 'context';
import { parse_str_period, pretty_str } from 'model/date';
import { SelectField, CheckBoxField, CategoryIncludeExcludeField, IntField} from "widget/autorenderer";
import { OperationTypeIncludeExcludeField } from 'widget/autorenderer/autorenderer';
const { Component } = require("react");
const { DataEntry, Operation, QuerySet, DataSet } = require("../queryset");
const { Graph, colors_alpha, Value,  get_chart_type } = require("./base");


class Solde extends Graph {
    init(){
        this._grouped_data = []
        var cats = {}
        this._grouped_data = new QuerySet(this.operations.data);
    }

    on_click(graph, evt){

        var x = this.get_dataset_by_event(evt);
        if(x==null) return
        graph.props.parent.show_operations(x.operations)
    }


    compute_data(){
        var datasets = []
        var labels = []
        var data = []
        var ordered = this._grouped_data["group_by_"+this.options.periode]().as_array({
            date_start: this.range[0],
            date_end: this.range[1],
            max_date_end: "today",
            extrapolation_start: DataSet.ZERO,
            extrapolation_end: DataSet.ZERO,
            interpolation: DataSet.ZERO,
        });
        var acc = []
        var revenu = (this.options.revenu || 0);
        var total = 0;
        var avg = 0; 
        for(var [period, values] of ordered){
            period = pretty_str(period);
            var value = Value.from_operations(values.data.filter(this.filter_operation.bind(this)));
            value.label = period;
            total += value.value;
            data.push(value);
            if(this.options.draw_acc){
                var tmp = acc.length?acc[acc.length-1].value:revenu;
                acc.push({
                    label : period,
                    value: value.value + tmp,
                    operations: value.operations
                })
            }
        }
        avg = total / ordered.length;
        
        if(this.options.draw_evo){
            datasets.push({
                label:  "Evolution du solde",
                data: data,
                backgroundColor: colors_alpha[datasets.length % colors_alpha.length],
                fill: true,
                tension: 0.4
            })
        }

        if(this.options.draw_acc){
            datasets.push({
                label:  "Accumulation",
                data: acc,
                backgroundColor: colors_alpha[(datasets.length+1) % colors_alpha.length],
                tension: 0.4
            })
        }

        if(this.options.draw_avg){
            var average = [];
            for(var x of data) average.push({value: avg, label: x.label, operations: x.operations})
            datasets.push({
                label:  "Moyenne",
                data: average,
                backgroundColor: colors_alpha[(datasets.length+1) % colors_alpha.length],
                tension: 0.4
            })
        }


        var data = {
            datasets: datasets
        }
        return data;
    }
}

class SoldeGraph extends Component {
    render() {
        var Classe = get_chart_type(this.props.graph.options.affichage);
        return (
          <div>
            <Classe id={"graph-"+this.props.graph.id}
                options ={{
                responsive: true, 
                scales: {  x: { stacked: false }, y: { stacked: false}},
                onClick: this.props.graph.on_click.bind(this.props.graph, this),
                parsing: {yAxisKey: 'value', xAxisKey: 'label'}
              }}
              data={this.props.data}
            />
          </div>
        );
      }
}


Graph.register("solde", {
    data: Solde,
    label: "Solde",
    graph: SoldeGraph,
    options: [
        new IntField("revenu", "Revenu", 0),
        new SelectField("affichage", "Affichage", {
            bar: "Bar",
            line: "Ligne"
        }, "line"),
        new CheckBoxField("draw_evo", "Afficher l'éculution relative", true),
        new CheckBoxField("draw_acc", "Afficher l'accumulation", true),
        new CheckBoxField("draw_avg", "Afficher la moyenne", true),
        new SelectField("periode", "Période", {
            day: "day",
            week: "week",
            month: "month",
            year: "year"
        }, "month"),
        new CategoryIncludeExcludeField("filter_categories", "Catégories", {
            categories: [], include: false
        }),
        new OperationTypeIncludeExcludeField("operation_types", "Types d'opération", {}),
    ]
})
import React, { Component } from "react";
import {Error} from 'widget'
import {parse, Environemnent} from 'model/stats/expression'
import {Context} from 'context'



class AbsGraphContent extends Component {
    constructor(props){
        super(props);
        var value = props.value || this.default_value();
        this.state = {
            id: undefined,
            error: null,
            self: this,
            ...value
        }
    }

    error(str){
        this.setState({error: <div dangerouslySetInnerHTML={{__html: str}}></div>})
    }

    _get_error(){
        var error = null;
        if(this.state.error){
            error = <Error onClose={this.on_close_error.bind(this)}>{this.state.error}</Error>
        }
        return error;
    }

    on_close_error(){
        this.setState({error: null })
    }

    resolve_expr(x){
        if(x && x[0]=="@"){
            x=x.substr(1);
            var id = parseInt(x.split(':')[0]);
            var expr = Context.data.expressions.get(id);
            if(expr) x=expr.expression;
        }
        return x
    }

    check_expression(x){
        x = this.resolve_expr(x)
        var op = parse(x);
        if(op.valid){
            return op
        }else{
            this.error("<b>Erreur:</b> "+op.error.message+"<br><b>Expression: </b> "+this.state.expression)
        }
        return null;
    }

    default_value(){
        throw "NotImplemented"
    }

    on_delete(){
        this.props.parent.on_content_delete(this);
    }

    get_value(){
        return Object.assign({id: this.state.id}, this.val());
    }

    on_change(){
        var val = this.get_value()
        this.props.parent.on_content_change(val);
    }

    validate(){

    }

    _on_change(key, value){
        this.state[key] = value
        this.setState({})
        this.on_change()

    }
}


export {
    AbsGraphContent
}
import React, { Component } from "react";
import './css/operation.css'
import {Context} from 'context'
import { Api } from "banque/api/api";
import { MultipleSelect } from "widget";


class OperationThumbnail extends Component {
    constructor(props) {
    super(props);
    this.state = {
        modal_visible: false
    };
  }

  date(){
    return this.props.date.substr(8,9)+"/"+this.props.date.substring(5,7)+"/"+this.props.date.substr(0,4)
  }

  libelle(){
    if(this.props.max_libelle_length){
      return this.props.libelle.substr(0,this.props.max_libelle_length)
    }
    return this.props.libelle
  }

  on_info(){
    this.props.parent.show_info(this.get_clear_operation())
  }

  get_clear_operation(){
    var obj = Object.assign({}, this.props);
    obj.date=this.date();
    obj.montant = this.montant();
    return obj;
  }

  on_filter(){
    this.props.parent.on_filter(this.props)
  }

  on_add_cat(){
    this.props.parent.on_filter(this.props, true)
  }

  on_add_list(){
    this.props.parent.on_filter_list(this.props)
  }

  on_remove_cat(){
    var self = this;
    Api.operation_remove_category(this.props.id, function(data){
      Context.data.operations.edit(data);
      self.props.parent.setState({});
    });
  }

  montant(){
    return parseInt(this.props.montant * 100)/100;
  }

  _categories(){
    var cat = Context.data.categories.get(this.props.category);
    cat =  cat?cat.name:"";
    return cat
  }

  on_select(){
    if(this.props.selected){
        this.props.parent.on_unselect(this.props.id);
    }else{
        this.props.parent.on_select(this.props.id);
    }
  }

  render() {
    var selected_class=this.props.selected?"operation-thumbnail-selected ":"";
    var actions = [];
    if(this.props.category){
      actions = [
        <a onClick={this.on_remove_cat.bind(this)} key="actions_2"><i className="bi bi-dash operation-thumbnail-action"></i></a>
      ]
    }else{
      actions = [
        <a onClick={this.on_add_cat.bind(this)} key="actions_0"><i className="bi bi-plus operation-thumbnail-action"></i></a>,
        <a onClick={this.on_filter.bind(this)} key="actions_1"><i className="bi bi-filter operation-thumbnail-action"></i></a>]
    }
    return ( <tr className={"operation-thumbnail "+selected_class}>
        <td className="form-check" onClick={this.on_select.bind(this)}>
            <input type="checkbox" checked={this.props.selected} onChange={this.on_select.bind(this)}/>
        </td>
        <td>{this.date()}</td>
        <td>{this.libelle()}</td>
        <td>{this.montant()} €</td>
        <td>{this._categories()}</td>
        <td className="operation-thumbnail-action-holder">
          {actions}
            <a onClick={this.on_info.bind(this)}><i className="bi bi-info operation-thumbnail-action"></i></a>
            <a onClick={this.on_add_list.bind(this)} key="actions_0"><i className="bi bi-list-check operation-thumbnail-action"></i></a>
        </td>
    </tr>)
  }
}

export {
    OperationThumbnail
};
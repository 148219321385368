import { Api } from "banque/api/api";
import { Context } from "context";
import { show_validation } from "model/app/app_common";
import React from "react"
import { Test } from "widget/base/form";
import "./css/provision.css"
const { Component} = require("react");
const { Integer, Entry, Float, ModalMedium, CustomButton, Icon, ModalCloseButton, AccountSelect, CheckBox, Select } = require("widget");
const { AbsComponent } = require("widget/base/base");
const { PatternEditor, Pattern } = require("../category/create");

const provision_types = {
    "recurrent" : "Récurrent",
    "temporaire" : "Temporaire",
    "permanente" : "Permanente"
}

class Provision extends AbsComponent {
    constructor(props){
        super(props)
        this.set_default("id", null);
        this.set_default("type", "recurrent");
        this.set_default("account", Context.data.accounts.get_array()[0].id);
        this.set_default("day_start", 1);
        this.set_default("month_period", 1);
        this.set_default("libelle", "");
        this.set_default("montant", "");
        this.set_default("lifetime", 30);
        this.set_default("trigger_pattern", {});
        this.set_default("activate", false);
    }

    remove(){
        this.state.value.trigger_pattern = null;
        this.setState({})
    }

    render(){

        var context_1 = null;
        var context_2 = null;
        if(this.state.value.type == "recurrent") {
            context_1 = (<>
                <div className="provision-field">
                    <span className="provision-field-label">Jour</span>
                    <Integer value={this.state.value.day_start} onChange={this.bind("day_start")} />
                </div>

                <div className="provision-field">
                    <span className="provision-field-label">Nombre de mois</span>
                    <Integer value={this.state.value.month_period} onChange={this.bind("month_period")} />
                </div>

                <div className="provision-field">
                    <span className="provision-field-label">Activer dès maintenant</span>
                    <CheckBox value={this.state.value.activate} onChange={this.bind("activate")} />
                </div>
            </>)
        }

        if(this.state.value.type == "recurrent" || this.state.value.type == "temporaire"){
            context_2 = (<>
                <div className="provision-field">
                    <span className="provision-field-label">Pattern</span>
                    <Pattern value={this.state.value.trigger_pattern || {}} 
                            onValidate={this.bind("trigger_pattern")} 
                            onChange={this.bind("trigger_pattern")} />
                </div>

                <div className="provision-field">
                    <span className="provision-field-label">Durée de vie (en jours)</span>
                    <Integer value={this.state.value.lifetime} onChange={this.bind("lifetime")} />
                </div>
            </>)
        }
        
        return (<div className="provision">

            <div className="provision-field">
                <span className="provision-field-label">Type</span>
                <Select value={this.state.value.type} options={provision_types} onChange={this.bind("type")} />
            </div>

            <div className="provision-field">
                <span className="provision-field-label">Compte</span>
                <AccountSelect simple={true}
                        selected={this.state.value.account} onChange={this.bind("account")} />
            </div>

            <div className="provision-field">
                <span className="provision-field-label">Libellé</span>
                <Entry value={this.state.value.libelle} onChange={this.bind("libelle")} />
            </div>

            <div className="provision-field">
                <span className="provision-field-label">Montant</span>
                <Float value={this.state.value.montant} onChange={this.bind("montant")} />
            </div>


            {context_1}
            {context_2}


        </div>)

    }
}


class ProvisionModal extends Component{
    constructor(props){
        super(props);
        this.ref = React.createRef();
    }

    on_validate(){
        this.props.onValidate && this.props.onValidate(this.ref.current.val()) 
    }

    render(){
        var props = this.props;
        var verbe = "Créer";
        if(props.value){
            verbe="Éditer"
        }
        return (
            <ModalMedium
            isOpen={props.isOpen!=undefined?props.isOpen:true}
            onClose={props.onClose}
            >
                <div className="modal-header">
                    <h5>{verbe} les provisions</h5>
                    <button type="button" className="btn-close" onClick={props.onClose}></button>
                </div>
                <div className="modal-body">
                    <Provision value={props.value} ref={this.ref}/>
                </div>

                <div className="modal-footer">
                    <CustomButton onClick={this.on_validate.bind(this)}>Appliquer</CustomButton>
                    <ModalCloseButton onClick={props.onClose}>Fermer</ModalCloseButton>
                </div>
            </ModalMedium>
        );
    }
}



class ProvisionList extends Component {

    constructor(props){
        super(props);
        this.state = {
            is_edit: null
        }
    }

    remove(data){
        var self = this;
        show_validation("Êtes vous sur ?", "Voulez vous vraiment supprimer cette provision ?",function(){
            Api.remove_provision(data.id, function(){
                Context.data.provisions.remove(data.id)
                self.setState({})
            })
        })
    }

    _item(x, i){
        return (
            <tr className="provision-list-item" key={i}>
                <td>{x.libelle}</td>
                <td>{x.montant}</td>
                <td>
                    <Icon className="provision-list-item-icon" icon="bi-pencil" 
                                onClick={() => this.setState({is_edit: x})}/>
                    <Icon className="provision-list-item-icon" icon="bi-trash2" 
                                onClick={() => this.remove(x)}/>
                </td>
            </tr>
        )
    }

    on_validate(data){
        var self = this;
        Api.edit_or_create_provision(data, function(new_data){
            if(data.id){
                Context.data.provisions.update(data)
            }else{
                Context.data.provisions.add(data)
            }
            self.state.is_edit = false;
            self.setState({})
        })

    }


    render(){
        var provs = Context.data.provisions.get_array();
        var content = provs.map(this._item.bind(this));
        var edit = null;
        if(this.state.is_edit){
            edit=<ProvisionModal value={this.state.is_edit} 
                                onClose={() => this.setState({is_edit: null})} 
                                parent={this}
                                onValidate={this.on_validate.bind(this)}/>
        }
        return (<div className="provision-list">
            {edit}
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Libellé</th>
                        <th>Montant</th>
                        <th>Tarif</th>
                    </tr>
                </thead>
                <tbody>
                    {content}
                </tbody>
            </table>
            <CustomButton onClick={() => this.setState({is_edit: {}})}>Ajouter</CustomButton>
        </div>)
    }
}


class ProvisionListModal extends Component{
    constructor(props){
        super(props);
        this.ref = React.createRef();
    }

    render(){
        var props = this.props;
        return (
            <ModalMedium
            isOpen={props.isOpen!=undefined?props.isOpen:true}
            onClose={props.onClose}
            >
                <div className="modal-header">
                    <h5>Gérer les provisions</h5>
                    <button type="button" className="btn-close" onClick={props.onClose}></button>
                </div>
                <div className="modal-body">
                    <ProvisionList />
                </div>
    
                <div className="modal-footer">
                    <ModalCloseButton onClick={props.onClose}>Fermer</ModalCloseButton>
                </div>
            </ModalMedium>
        );
    }
   
}


export {
    ProvisionList,
    Provision,
    ProvisionListModal
}
import { Api } from "banque/api/api";
import { CustomButton, ModalCloseButton, ModalLarge } from "widget";
import { AutoOptions, IntField, StringField } from "widget/autorenderer";
import { AbsComponent, ReactiveComponent } from "widget/base/base";
import { Tile, TileEditorModal } from "./tile";
import React from 'react'
import { Context } from "context";
import { DashboardMenu, DashboardMenuModal } from "./menu";
const { Component } = require("react");

class Dashboard extends ReactiveComponent {
    constructor(props){
        super(props);
        this.state = {
            value: this.props.dashboard,
            addtile: false,
            fit_screen: null,
            show_menu: false
        }
    }

    set_fit_screen(x){
        this.state.fit_screen = x;
        if(this.props.parent){
            this.props.parent.set_fit_screen(x);
        }else{
            this.setState({})
        }
    }

    on_update_tile(tile){
        tile.update = true;
        for(var i in this.state.value.tiles){
            if(this.state.value.tiles[i].id == tile.id){
                this.state.value.tiles[i] = tile;
                this.setState({})
                return;
            }
        }
        this.state.value.tiles.push(tile);
        this.setState({})
    }

    on_remove_tile(tile){
        for(var i in this.state.value.tiles){
            if(this.state.value.tiles[i].id == tile.id){
                this.state.value.tiles.splice(i, 1);
                this.setState({})
                return;
            }
        }
    }

    setState(props){
        props = props || {}
        this.state.value.tiles.sort((a,b) => parseInt(a || 0) - parseInt(b || 0))
        return super.setState(props)
    }

    add_tile(){
        this.state.addtile=true;
        this.setState({})
    }

    add_tile_close(){
        this.state.addtile=false;
        this.setState({})
    }

    on_menu_click(data){
        this.props.parent.on_menu_click(data);
    }

    render(){
        var tiles = [];
        for(var tile of  this.state.value.tiles){
            var CurrentTile = Tile.get_tile(tile).classe
            if(CurrentTile == null) continue;
            if(this.state.fit_screen){
                if(tile.id==this.state.fit_screen.id){
                    tiles.push(<CurrentTile 
                        content={tile} 
                        key={tile.id} 
                        fit_screen={true}
                        parent={this}
                        dashboard={this.state.value.id} />)
                }
                
            }else{
                tiles.push(<CurrentTile 
                    content={tile} 
                    key={tile.id} 
                    parent={this}
                    dashboard={this.state.value.id} />)
            }
            
        }
        var modal = null;
        if(this.state.addtile){
            modal = (<TileEditorModal 
                holder = {this}
                dashboard = {this.state.value.id}
                value={{}} 
                parent={this}
                key={"tile_editor_main"}
                onClose={this.add_tile_close.bind(this)}/>)
        }
        
        var mobile_button = null;
        var modal_menu = null;
        if(Context.is_mobile){
            mobile_button = (
                <div className="dashboard-overlay-left">
                    <a onClick={() => this.setState({show_menu: true})} className="dashboard-title-list"><i className="bi bi-list"></i></a>
                </div>
            )

            if(this.state.show_menu){
                modal_menu = (
                    <DashboardMenuModal
                        dashboards={this.props.parent.state.dashboards} 
                        parent={this.props.parent}
                        onClose={() => this.setState({show_menu: false})}
                        onClick={this.on_menu_click.bind(this)}/>)
            }
        }

        return (<div className="dashboard">
            {modal}
            {modal_menu}
            <h1><center>{this.state.value.name}</center></h1>
            <div className="dashboard-overlay">
                <a onClick={this.add_tile.bind(this)} className="dashboard-title-add"><i className="bi bi-plus"></i></a>
            </div>
            {mobile_button}
            <div className="row">
                {tiles}
            </div>
        </div>)
    }
}


class DashboardEditor extends Component {
    constructor(props){
        super(props)
        var value = props.value || {};
        this.state = {
            value: value || {}
        }
        this.form = new AutoOptions([
            new StringField("path", "Chemin", "/"),
            new StringField("name", "Nom", "")
        ])
    }

    on_change(value){
        Object.assign(this.state.value, value);
        this.setState({})
    }

    on_validate(){
        var self = this;
        Api.dashboard_update_or_create(this.state.value, function(x,y,z){
            self.props.onValidate && self.props.onValidate(x);
            self.props.onClose && self.props.onClose();
        })
    }


    render(){
        return (
            <div className="lists-inc-excl">
                {this.form.render(this.state.value, this.on_change.bind(this))}
            </div>
        )
    }

}

class MenuModal extends ReactiveComponent{
    constructor(props){
        super(props);
    }


    render(){
        return (<ModalLarge
            isOpen={true} >
            
            <div className="modal-header">
                <h5>Editer le dashboard {this.props.value.name}</h5>
                <button type="button" className="btn-close" onClick={this.props.onClose}></button>
            </div>
            <div className="modal-body">
                <DashboardMenu {...this.props}/>
            </div>
            <div className="modal-footer">
                <CustomButton onClick={() => this.editor.current.on_validate()}>Enregistrer</CustomButton>
                <ModalCloseButton onClick={this.props.onClose}>Fermer</ModalCloseButton>
            </div>
        </ModalLarge>)
    }
}


class DashboardEditorModal extends ReactiveComponent{
    constructor(props){
        super(props);
        this.editor = React.createRef();
    }


    render(){
        return (<ModalLarge
            isOpen={true} >
            
            <div className="modal-header">
                <h5>Editer le dashboard {this.props.value.name}</h5>
                <button type="button" className="btn-close" onClick={this.props.onClose}></button>
            </div>
            <div className="modal-body">
                <DashboardEditor {...this.props} 
                                ref={this.editor}/>
            </div>
            <div className="modal-footer">
                <CustomButton onClick={() => this.editor.current.on_validate()}>Enregistrer</CustomButton>
                <ModalCloseButton onClick={this.props.onClose}>Fermer</ModalCloseButton>
            </div>
        </ModalLarge>)
    }
}


export {
    Dashboard, DashboardEditor, DashboardEditorModal
}
import React, { Component } from "react";
import './css/select.css'
import {Context} from 'context';
import {MultipleSelect, Select} from 'widget';
import { extend } from "jquery";
import { AbsComponent } from "widget/base/base";
import { Model } from "banque/model/utils";



class CategorySelect extends Component {
    constructor(props){
        super(props);
        this.state={
            selected: props.value || props.selected || []
        }
    }

    _get_options(){
        var ret = {"" : "(Aucune)"}
        if(this.props.categories){
            for(const cat of this.props.categories){
                var c = Context.data.categories.get(cat);
                ret[c.id]=c.name;
            }
        }else{
            Context.data.categories.get_array().map(x => ret[x.id]=x.name)
        }
        return ret
    }

    render(){
        return (
            <MultipleSelect options={this._get_options()}
                            selected={this.state.selected}
                            className={this.props.className || "btn-category"}
                            {...this.props} />
        )
    }

}

class CategorySelectIncludeExclude extends AbsComponent {
    OPTIONS={
        true: "Inclure",
        false: "Exclure"
    }
    constructor(props){
        super(props);
        this.set_default("include", false);
        this.set_default("categories", []);
    }


    render(){

        return (
            <span className="categories-inc-excl">
                <CategorySelect categories={Model.get_categories_names()}  selected={this.state.value.categories} onChange={this.bind("categories")} />
                <Select options={this.OPTIONS} selected={this.state.value.include} 
                            cast={(x) => (x=="true" || x == true)?true:false}
                            value={this.state.include} onChange={this.bind("include")}></Select>
            </span>
        )
    }
}


export {
    CategorySelect, CategorySelectIncludeExclude
};
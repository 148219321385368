
import {Bar, Doughnut, Line, Pie} from 'react-chartjs-2';
import { Select } from 'widget';
import { Context } from 'context';
import './css/camembert.css'
import { SelectField,  CategoryIncludeExcludeField, CheckBoxField} from "widget/autorenderer";
import { OperationTypeIncludeExcludeField } from 'widget/autorenderer/autorenderer';
const { Component } = require("react");
const { DataEntry, Operation, QuerySet } = require("../queryset");
const { Graph, Value, colors_alpha } = require("./base");

class Camembert extends Graph {

    on_click(graph, evt){
        var x = this.get_dataset_by_event(evt);
        graph.props.parent.show_operations(x.operations)
    }

    init(){
        this._grouped_data = []

        var cats = {}

        
        for(var op of this.operations.data){
            var cat = -1;
            op = this.filter_operation(op);
            if(op==null) continue;

            if(op.category){
                cat = op.category;
            }

            if(cats[cat] === undefined){
                cats[cat] = new DataEntry(cat);
            }
            cats[cat].add(op);
        }
        this._grouped_data = cats;
        this._filter_mode = this.options.mode=="produit"?(x => x.montant>0):(x => x.montant<0);
    }


    compute_data(){
        var datasets = [];
        var vals = []
        var cols = []
        var labels = []
        for(var [cat, values] of Object.entries(this._grouped_data) ){
            var value = null;
            if(this.options.synthese=="abs"){
                vals.push(value=Value.from_abs_operations(values.data, this._filter_mode));
            }else{
                if( (values.sum()>=0 && this.options.mode=="produit") ||
                    (values.sum()<0 && this.options.mode=="charges")){
                        vals.push(value=Value.from_abs_operations(values.data))
                }
            }
            if(value!=null){
                cat = Context.data.categories.get(cat);
                cat = (cat)?cat.name:"Sans catégorie"
                labels.push(cat)
                cols.push(colors_alpha[cols.length % colors_alpha.length])
            }
        }
        
        datasets.push({
            label:  cat,
            data: vals,
            backgroundColor: cols,
            hoverOffset: 4
        })
        datasets.push({})

        var data = {
            labels: labels,
            datasets: datasets
        }
        return data;
    }
}

class CamembertGraph extends Component {
    constructor(props){
        super(props);
    }

    render() {
        var Mode = this.props.display=="pie"?Pie:Doughnut;
        return (
          <div>
            <Pie id={"graph-"+this.props.graph.id}
                options ={{
                responsive: true,
                parsing: {yAxisKey: 'value', xAxisKey: 'value'},
                onClick: this.props.graph.on_click.bind(this.props.graph, this),
              }}
              data={this.props.data}
            />
          </div>
        );
      }

}

Graph.register("camembert", {
    data: Camembert,
    label: "Camembert",
    graph: CamembertGraph,
    options: [
        new SelectField("mode", "Mode", {
            produit: "Produits",
            charges: "Charges"
        }),
        new SelectField("display", "Type", {
            pie: "Camembert",
            doughnut: "Donut"
        }),
        new SelectField("synthese", "Calcul", {
            sum: "Somme des categories",
            abs: "Valeur absolue",
        }, "abs"),
        new CategoryIncludeExcludeField("filter_categories", "Catégories", {
            categories: [], include: false
        }),
        new OperationTypeIncludeExcludeField("operation_types", "Types d'opération", {}),
    ]
})
import { DataEntry, QuerySet } from "../queryset";
import {Context} from 'context'
import { Environemnent, parse } from "../expression";
import { Component } from "react";
import { Select } from "widget";
import { CategorySelectIncludeExclude } from "banque/widget/category/select";
import { Doughnut, Bar, Line, Pie, Radar, Bubble, PolarArea, Scatter } from "react-chartjs-2";
import { Filters } from "./utils/filter";

var _graph_index = {}
var _graph_labels = {}


var DISPLAY ={
    line: Line,
    bar: Bar,
    pie: Pie,
    doughnut: Doughnut,
    bubble: Bubble,
    radar: Radar,
    polararea: PolarArea,
    scatter: Scatter
}

function get_chart_type(x){
    var y = x.toLowerCase()
    var ret = DISPLAY[y]
    if(ret === undefined){
        alert("Erreur le chart '"+x+"' est inconnu")
    }
    return ret
}


class Value {
    constructor(value, operations, label=null){
        this.value = (value===undefined)?0:value;
        this.operations = (operations===undefined)?[]:operations;
        this.label = label;
    }


    add(op){
        this.value+=op.montant;
        this.operations.push(op)
    }

    static from_operations(ops, filter=null, label=null){
        if(!Array.isArray(ops) && Array.isArray(ops.data)) ops = ops.data
        var v = new Value(0, []);
        if(filter){
            for(var x of ops){
                if(filter(x))v.add(x);
            }
        }else{
            for(var x of ops){
                v.add(x);
            }
        }
        v.label = label;
        return v
    }

    static from_abs_operations(ops, filter=null, label=null){
        var x = Value.from_operations(ops, filter, label);
        if(x.value<0) x.value = -x.value;
        return x;
    }


}

class Graph {
    constructor(data, config){
        this.account_id = null;
        this.content = [];
        this.account = [];
        this.favoris = 0;
        this.id = 0;
        this.config = config;
        this.multiple = false;
        this.name = "";
        this.operations = [];
        this.last_data = null;
        this.options = {
        };
        this.period = {}
        this.type = null;
        this.user_id = 0;
        Object.assign(this, data);
        if( !(this.operations instanceof QuerySet)){
            this.operations = new QuerySet(this.operations._data);
        }
        this.operations = this.filter(this.operations)
        for(var field of config.options){
            if(this.options[field.name]===undefined){
                this.options[field.name] = field.default_value;
            }
        }
        this._init_common_options();
        this.init();
    }

    get_utils(classe){
        return new classe(this.operations, this.options);
    }

    filter(data){
        if(this.options.operation_types){
            data = Filters.operation_types(this.options.operation_types, data);
        }
        return data;
    }


    _init_common_options(){
        if(this.options.filter_categories){
            this._filtered_categories = {}
            for(var cat of this.options.filter_categories.categories){
                this._filtered_categories[cat] = true;
            }
        }
        if(this.options.operation_types){
            this._filtered_categories = {}
            for(var cat of this.options.filter_categories.categories){
                this._filtered_categories[cat] = true;
            }
        }
    }

    filter_operation(op){
        if(op && this._filtered_categories){
            if(this.options.filter_categories.include=="true" || this.options.filter_categories.include==true){
                op = this._filtered_categories[op.category]?op:null;
            }else{
                op = this._filtered_categories[op.category]?null:op;
            }
        }
        return op;
    }

    get_dataset_by_event(event){
        window.chart = event.chart;
        var evts = event.chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);
        if(evts.length==0) return null
        var evt = evts[0];
        return  this.current_data.datasets[evt.datasetIndex].data[evt.index]
        
    }

    resolve_expr(x){
        if(x && x[0]=="@"){
            x=x.substr(1);
            var id = parseInt(x.split(':')[0]);
            var expr = Context.data.expressions.get(id);
            if(expr) x=expr.expression;
        }
        return x
    }

    init(){}

    get_data(){
        return this.compute_data()
    }

    compute_data(){
        throw "Error"
    }

    static parse(x){
        if(x.type in _graph_index){
            var ret = _graph_index[x.type]
            return new ret.data(x, ret);
        }
        throw "Error de parsing"
    }

    static register(name, obj){
        _graph_index[name] = obj
        _graph_labels[name] = obj.label
    }

    static get_labels(){
        return _graph_labels;
    }

    static get_descriptor(name){
        return _graph_index[name];
    }
}

const colors_alpha = [
    "#40407a7f",
    "#706fd37f",
    "#f7f1e37f",
    "#34ace07f",
    "#33d9b27f",
    "#ff52527f",
    "#ff793f7f",
    "#d1ccc07f",
    "#ffb1427f",
    "#ffda797f"
]
export {
    Graph, colors_alpha,
    Value, get_chart_type
};
import { AbsGraphContent } from  "banque/widget/graph/content/abscontent";
import { Component } from "react";
import { Button, Field, FormRenderer, Select } from  "widget";
import { AutoOptionsRenderer, AutoOptions, StringField, SelectField, CheckBoxField, IntField } from "widget/autorenderer";
import { Graph } from "../base";
import { numeric_infos_default, numeric_infos } from "./constantes";
import { Info } from "./info";



class NumericContentData extends AbsGraphContent {
    constructor(props){
        super(props)
        this.state.value = this.state.value || {};
        this.renderer_options =  new SelectField("info", "Information à afficher", numeric_infos, numeric_infos_default)
        
        this.options = null;
    }

    default_value(){
        return {
            info: ''
        }
    }

    _get_classe_options(){
        if(!this.state || !this.state.info) return []
        var Classe = Info.get_info(this.state.info);
        if(Classe === undefined){
            return [];
        }
        Classe = Classe.classe;
        var options = Classe.get_options();
        return options
    }

    _on_remove(){
        this.props.parent.on_content_delete(this.state.id)
    }

    _on_value_change(value){
        const old_info = this.state && this.state.info;
        Object.assign(this.state, value);
        if(old_info != this.state.info){
            this.options = [this.renderer_options].concat(this._get_classe_options())
        }
        this.on_change()
    }
    
    get_classe_options(){
        if(!this.options)  this.options = [this.renderer_options].concat(this._get_classe_options());
        return this.options
    }

    render(){
        return (<div className="graph-content"> {this._get_error()}
            <Button className="graph-content-remove">
                <i className='bi bi-x' onClick={this._on_remove.bind(this)}></i>
            </Button>
            <FormRenderer fields={this.get_classe_options()} value={this.state} onChange={ this._on_value_change.bind(this)}/>
        </div>)
    }

    val(){
        var ret = {};
        for(var field of this.options){
            ret[field.name] = this.state[field.name]
        }
        return ret
    }
}



class NumericGraph extends Graph {
    compute_data(){
        var content = []
        for(var i in this.content){
            const name = this.content[i].info || "restant";
            var Classe = Info.get_info(name);
            if(Classe === undefined){
                console.error("L'info numérique ",name,"est inconnue");
                return null;
            }
            Classe = Classe.classe;
            content.push(new Classe(this, i,this.content[i], this.operations))
        }
        return content;
    }
}

class NumericContent extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }

    render(){
        var children = this.props.graph.current_data;
        var mode = this.props.graph.options.mode;
        var n_per_line = this.props.graph.options.per_line;
        var content = children.map( (x,i) => x.render(mode, n_per_line, i))
        return <div className="numeric-info-holder row">{content}</div>
    }
}

Graph.register("Expressions_numérique", {
    data: NumericGraph,
    label: "Expressions_numérique",
    options: [
        new SelectField("mode", "Mode", {
            simple: "Simple",
            image: "Image"
        }),
        new IntField("per_line", "Item par ligne", 3)
    ],
    content: NumericContentData,
    graph: NumericContent,
    multiple: true
})

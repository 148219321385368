import React, { Component } from "react";
import {Context} from 'context'
import {Entry, Float, CustomButton, DateStartEndPicker, DateRangePicker, AccountSelect, MultipleSelect, Select} from 'widget'
import {CategorySelect} from 'banque/widget/category/select'
import './css/filter.css'
import {Api} from "banque/api/api";
import { ListSelect } from "../list/list";
import { AbsComponent } from "widget/base/base";

/*

*/
const OPERATION_TYPES = {'Retrait': 'Retrait', 'Remise de cheque': 'Remise de cheque', 'Virement recu': 'Virement recu', 'Virement émis': 'Virement émis', 'Paiement par carte': 'Paiement par carte', 'Prelevement': 'Prelevement', 'Frais': 'Frais', 'Avoir': 'Avoir', 'Cotisation': 'Cotisation', 'Cheque emis': 'Cheque emis', 'provision': 'provision'}
;


class MultipleOperationTypeSelect extends Component {
  render(){
    var val = (this.props.value===undefined?(this.props.selected===undefined?[]:this.props.selected):this.props.value)
    return (
        <MultipleSelect options={OPERATION_TYPES}
                        selected={val}
                        className={this.props.className || "btn-category"}
                        {...this.props} />
    )  
  }
}

class SimpleOperationTypeSelect extends Component {

    render(){
        return (
            <Select options={OPERATION_TYPES}
                            selected={this.props.selected || this.props.value}
                            className={this.props.className || "btn-category"}
                            {...this.props} />
        )
    }

}


class OperationTypeSelectIncludeExclude extends AbsComponent {
    OPTIONS={
        true: "Inclure",
        false: "Exclure"
    }
    constructor(props){
        super(props);
        this.set_default("include", false);
        this.set_default("operation_types", []);
    }

    val(){
        return super.val()
    }

    render(){

        return (
            <span className="categories-inc-excl">
                <MultipleOperationTypeSelect  selected={this.state.value.operation_types} onChange={this.bind("operation_types")} />
                <Select options={this.OPTIONS} selected={this.state.value.include} 
                            cast={(x) => (x=="true" || x == true)?true:false}
                            value={this.state.include} onChange={this.bind("include")}></Select>
            </span>
        )
    }
}

class OperationFilter extends Component {
    constructor(props){
        super(props);
        this.state = {
            value: (props.value!=null)?props.value:{},
            is_open: false
        }
        if(!this.state.value.libelle) this.state.value.libelle="";
    }

    _on_change(key, value){
        if(key=="min_max_date"){
            this.state.value.min_date = value.start;
            this.state.value.max_date = value.end;
        }else if(key=="categories"){
            this.state.value.categories = value;
        }else if(key=="categories"){
            value = parseInt(value)
            this.state.value.lists = value;
        }else{
            this.state.value[key]=value;
        }
        if(this.props.onChange)
            this.props.onChange(key, value)
        else
            this.setState({})
    }

    val(){
        return this.state.value;
    }

    _on_validate(){
        var self = this;
        var val = this.val();
        Api.filter_operation(val, function(data){
            var operations = data.query.operation;
            self.props.onValidate && self.props.onValidate(operations);
        })
    }

    _erase(){
        this.setState({value: {}})
    }

    _close(){
        this.setState({is_open: false})
    }

    _open(){
        this.setState({is_open: true})
    }


    _render_content(){
        var actions = (this.props.operations!==undefined)?(<div className="operation-filter-validate">
            <CustomButton onClick={this._erase.bind(this)}>Effacer</CustomButton>
            <CustomButton onClick={this._on_validate.bind(this)}>Filtrer</CustomButton>
            <span>{this.props.operations._data.length} opérations correspondantes</span>
        </div>):null;
        if(!this.state.is_open && !this.props.headless) return null;
        return (<div className="operation-filter-content">
                <div className="operation-filter-input">
                    <label>Compte</label>
                    <span><AccountSelect selected={this.state.value.account} onChange={this._on_change.bind(this, "account")} label="Account"/></span>
                </div>

                <div className="operation-filter-input">
                    <label> Libellé</label>
                    <span>
                        <Entry type="text" onChange={this._on_change.bind(this, "libelle")}
                                value={this.state.value.libelle} placeholder="Recherche"
                                onValidate={this._on_validate.bind(this)}/>
                    </span>
                </div>

                <div className="operation-filter-input">
                    <label>Montant</label>
                    <span> <Float onValidate={this._on_validate.bind(this)} onChange={this._on_change.bind(this, "min_amount")} value={this.state.value.min_amount}  placeholder="Prix min"/> </span>
                    <span> <Float onValidate={this._on_validate.bind(this)} onChange={this._on_change.bind(this, "max_amount")} value={this.state.value.max_amount}  placeholder="Prix max"/> </span>
                </div>

                <div className="operation-filter-input">
                    <label>Type d'opération</label>
                    <span><MultipleOperationTypeSelect selected={this.state.value.type} onChange={this._on_change.bind(this, "type")} label="Type d'opération"/></span>
                </div>

                <div className="operation-filter-input">
                    <label>Catégorie</label>
                    <span><CategorySelect selected={this.state.value.categories} onChange={this._on_change.bind(this, "categories")} label="Categorie"/></span>
                </div>
                
                <div className="operation-filter-input">
                    <label>Liste</label>
                    <span><ListSelect selected={this.state.value.lists} onChange={this._on_change.bind(this, "lists")} label="Liste"/></span>
                </div>

                <div className="operation-filter-input">
                    <label>Date</label>

                    <span><DateRangePicker value={this.state.period} onChange={this._on_change.bind(this, "period")} /></span>
                </div>
                {actions}
                
           </div>)
    }

    _render_toggle_button(){
        if(this.state.is_open){
            return (<i className="bi bi-caret-up operation-filter-toggle" onClick={this._close.bind(this)}></i>)
        }else{
            return (<i className="bi bi-caret-down operation-filter-toggle" onClick={this._open.bind(this)}></i>)
        }
    }

    render(){
        if(this.props.disableFilter){
            return null;
        }
        if(this.props.headless){
            return this._render_content();
        }
        return (
            <div className={"operation-filter operation-filter-"+(this.state.is_open?"opened":"closed")}>
                <span className="operation-filter-header">
                    {this._render_toggle_button()}
                    <h5>Rechercher</h5>
                </span>
                {this._render_content()}
            </div>
        )
    }

}

export {
    OperationFilter, SimpleOperationTypeSelect, MultipleOperationTypeSelect,
    OperationTypeSelectIncludeExclude
};
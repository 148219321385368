
var _element_manager_entry = {}
class ManagerEntry {
    constructor(data){
        Object.assign(this, data);
    }

    update(data){
        Object.assign(data)
    }
}




class ListManager {
    constructor(data, cast=null, key="id"){
        this._key=key
        this._indexed_data={}
        this._data = []
        this._filter = null
        this._sort = null;
        this._cast = null;
        if(cast.constructor === undefined){
            this._cast = x => (x instanceof cast)?x:(cast(x));
        }else{
            this._cast = x => (x instanceof cast)?x:(new cast(x));
        }
        this.set(data)
    }

    set_filter(fct){
        this._filter = fct;
    }

    sort(key, revered=false){
        this._sort=[key, revered]
        return this._data.sort((a, b) => revered?(a[key]<b[key]):(a[key]>b[key]))
    }

    update(data){
        for(var i in data){
            if(this.get(data[i].id) == null){
                this.add(data[i]);
            }else{
                this.edit(data[i]);
            }
        }
    }

    set(data){
        this._data.splice(0, this._data.length);
        this._indexed_data={}
        for(var i in data){
            var elem = this._cast(data[i])
            this._data.push(elem);
            this._indexed_data[elem[this._key]] = elem;
        }
        if(this._sort){
            this.sort(this._sort[0], this._sort[1])
        }
    }

    at(i){
        return this._data[i]
    }

    get(id){
        return this._indexed_data[id] || null;
    }

    add(data){
        if(Array.isArray(data)){
            for(var op of data){
                if(op[this._key] in this._indexed_data) continue;
                this._data.push(op);
                this._indexed_data[op[this._key]] = op
            }
            return;
        }
        if(data[this._key] in this._indexed_data) return
        this._data.push(data);
        this._indexed_data[data[this._key]] = data
    }

    edit(data){

        console.error("edit ", data, this);
        Object.assign(this._indexed_data[data[this._key]], data)
    }

    remove(data){
        if(Array.isArray(data)){
            for(var i in data) this.remove(data[i]);
            return;
        }

        for(var i=0; i<this._data.length; i++){
            if(this._data[i][this._key] == data[this._key]){
                this._data.splice(i, 1)
                delete this._indexed_data[data[this._key]]
                return;
            }
        }
        console.error("Impossible de supprimer ", data);
    }

    get_array(){
        return this._data
    }

    get_object(){
        return this._indexed_data
    }

    foreach(fct){
        var data = this._filter?this._data.filter(this._filter):this._data;
        for(var i in data){
            fct(data[i]);
        }
    }

    select(ids, fct=null){
        var ret = [];
        for(var i in ids){
            if(fct) ret.push(fct(this.get(ids[i])))
            else ret.push(this.get(ids[i]))
        }
        return ret
    }

    static get_manager(name, value, key="id"){
        for(var [n, classe] of Object.entries(_element_manager_entry) ){
            if(n==name){
                if(classe)
                    return new ListManager(value, classe, key);
                else    
                    return value
            }
        }
        return new ListManager(value, ManagerEntry, key);
    }

    static register(classe, names){
        for(var x of (Array.isArray(names)?names:[names])){
            _element_manager_entry[x] = classe;
        }
    }

}

export {
    ListManager, 
    ManagerEntry
};
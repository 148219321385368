import { Graph } from "./stats/graph";
import { Operation } from "./stats/operation";
import { QuerySet } from "./stats/queryset";

class GraphEntry {
    constructor(graph){
        this.graph = Graph.parse(graph);
        this.data = this.graph.get_data();
        this.graph.current_data = this.data;
    }

    update(graph){
        this.graph = Graph.parse(graph);
        this.data = this.graph.get_data();
        this.graph.current_data = this.data;
    }
}

class _GraphManager {
    constructor(){
        this.graphs = {}
    }

    get_graph(graph, force_update=false){
        if(Array.isArray(graph.operations)){
            if(graph.operations.length && !(graph.operations[0] instanceof Operation))
                graph.operations = graph.operations.map(x => new Operation(x))
            graph.operations = new QuerySet(graph.operations)
        }
        if(force_update || !(graph.id in this.graphs)){
            this.graphs[graph.id] = new GraphEntry(graph);
        }
        return this.graphs[graph.id].graph;
    }

    get_data(graph){
        if(! (graph.id in this.graphs)){
            this.graphs[graph.id] = new GraphEntry(graph);
        }
        return this.graphs[graph.id].data;
    }

    update(graph){
        if(! (graph.id in this.graphs)){
            this.graphs[graph.id] = new GraphEntry(graph);
        }else{
            this.graphs[graph.id].update(graph);
        }
        return this.graphs[graph.id].graph;
    }

    remove(id){
        delete this.graphs[id];
    }


}

const GraphManager = new _GraphManager();

export {
    GraphManager
}

import { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import './banque/pages/common.css'
import {Login} from './banque/pages/login'
import {ListAccounts} from './banque/pages/list_accounts'
import {ListOperations} from './banque/pages/operations'
import {DashboardsList} from './banque/pages/dashboard'
import {NavBar} from './banque/pages/nav'
import { GraphPage } from 'banque/pages/graphs';
import { extend } from 'jquery';
import { AppCommon } from 'model/app/app_common';
import { ReactiveComponent } from 'widget/base/base';
import { Api } from 'banque/api/api';
import { Context } from 'context';


var _appcommon_ref = null;



var is_loading = false;
class App extends ReactiveComponent {
    constructor(props){
        super(props)
        this.state = {
            loaded: false,
            error: null
        }
    }

    fetch_data(){
        if(is_loading){
            console.log("Already fetching data");
            return;
        }
        is_loading = true;
        Api.get_context_async((x) => this.load_data(x),
                (...args) => this.load_error(...args),
                )
    }

    load_data(data){
        console.log("Loading data : ", data)
        data = data.context
        Context.set(data)
        if(!window.is_debug){
            window.DEBUG = false;
        }else{
            window.DEBUG = true;
        }
        console.log("load_data.Context:",Context)
        window.data = Context.data;
        window.Context = Context;
        is_loading = false;
        this.setState({loaded: true, error: null})
    }

    load_error(data){
        this.setState({loaded: false, error: data})
    }

    render(){
        if(this.state.error){
            return (<div
                ><h1>Erreur</h1>
                <p style={{color: "red"}} >
                    {this.state.error}
                </p>
            </div>)
        }
        else if(this.state.loaded){
            return (
                <div className="App">
                    <AppCommon />
                </div>
              );
        }
        else{

            this.fetch_data();
            return (<div className="app-loader-root">
                <center>
                    <div className="app-loader-title">Chargement, merci de patienter</div>
                    <span className="app-loader"></span>
                </center>
            </div>)
        }
        
    }

}

window.AppManager = _appcommon_ref;

export {
    App,
    AppCommon
};

import {Context} from 'context'
import { BadDateException, Day, Month, parse_period, Year } from 'model/date'
import {Operation } from './operation'



class DataEntry {
    constructor(label){
        this.data= []
        this.label = label
        this.categories = {}
    }

    sum(key="montant"){
        return this.data.reduce((p, c) => p+c[key], 0)
    }

    add(y){
        this.data.push(y);

        var cat = y.category>=0?y.category:-1;
        if(!(cat in this.categories)) this.categories[cat] = []
        this.categories[cat].push(y)
    }

    get(x){
        if(x=="") return this.data;
        if(x=="!") return this.categories[null] || []
        return this.categories[x] || []
    }

    filter(fct){
        var de = new DataEntry(this.data);
        this.data.map(x => fct(x)?de.add(x):null);
        return de;
    }
}

class DataSet {
    constructor(){
        this.data={}
        this.tag = null;
    }

    
    flatten(options ){
        options = Object.assign({
            reduce: (y => y.sum()),
            sort: ((x,y) => x.x>x.y)
        }, options || {})
        return Object.entries(this.data).map( ([x,y]) => ({x:x, y:options.reduce(y), data: y }) ).sort(options.sort)
    }

    add(x, y, label){
        if(!(x in this.data)){
            this.data[x]=new DataEntry( (label!=undefined)?label:x )
        }
        this.data[x].add(y);
    }

    sort(x){
        return Object.entries(this.data).sort(x)
    }

    get(){
        alert("---")
    }

    map(x = ([x, y]) => ({x:x, y:y})){
        return Object.entries(this.data).map(x)
    }

    get_date_object(){
        switch(this.tag){
            case "day": return Day
            case "month": return Month
            case "year": return Year
            default: return null;
        }
    }

    static FIRST_VALUE = "first_value";
    static LAST_VALUE = "last_value";
    static ZERO = "zero";
    static NONE = "none";
    _fill_beetwin(data, curr, next, interpolation){

        var next_label = next[1].label
        var curr_label = curr[1].label;
        while(true){
            
            if(isNaN(curr_label.year) || curr_label.year > (new Date()).getFullYear()){ 
                console.log("_fille_end")
                return;
            }
            curr_label = curr_label.next();
            //console.log("next_label",interpolation,  next_label, curr_label)
            if(next_label.equals(curr_label)){
                break
            }
            var elem = null;
            if(interpolation == DataSet.FIRST_VALUE || interpolation == null){
                elem = Object.assign({}, curr[1], {label: curr_label});
            }else if(interpolation == DataSet.LAST_VALUE){
                elem = Object.assign({}, curr[1], {label: curr_label});
            }else if(interpolation == DataSet.ZERO){
                var tmp = new DataEntry(curr_label)
                elem = Object.assign({}, tmp);
            }
            data.push([curr_label.toString(), elem])
        }
    }



    _fill_holes(opt){
        opt = Object.assign({
            interpolation: null,
            date_start: null,
            date_end: null,
            extrapolation_start: null,
            extrapolation_end: null,
            max_date_end: "today"
        }, opt || {})
        var data = Object.entries(this.data).sort()
        const len = data.length;
        if(!len || this.tag == "all"  ) return data;
        var date_class = this.get_date_object()

        if(opt.date_start && date_class && data.length){
            var date = new Date(opt.date_start)
            date.setDate(date.getDate()-1)
            var first = new DataEntry(date_class.from_date(date));
            this._fill_beetwin(data, [first.label.toString(), first], data[0], opt.extrapolation_start || DataSet.ZERO)
        }

        for(var i=0; i<len-1; i++){
            this._fill_beetwin(data, data[i], data[i+1], opt.interpolation)
        }
        data.sort((a,b) => a[0] > b[0])
        if(opt.date_end && date_class && data.length && opt.extrapolation_end != DataSet.NONE){
            var date = new Date(opt.date_end)
            if(opt.max_date_end == "today" && date > new Date()){
                date = new Date()
            }
            date.setDate(date.getDate()+1)
            var end =  new DataEntry(date_class.from_date(date));
            this._fill_beetwin(data, data[data.length-1], [end.label.toString(), end], opt.extrapolation_end || DataSet.FIRST_VALUE)
        }

        return data
    }


    as_array(opt){
        opt = Object.assign({
            key: null,
            interpolation: null,
            date_start: null,
            date_end: null,
            extrapolation_start: null,
            extrapolation_end: null,
            max_date_start: "today"
        }, opt || {})
        var ret = null;
        if(this.tag == null){
            ret = Object.entries(this.data)
            return ret;
        }
        else{
            ret = this._fill_holes(opt);
        }
        if(opt.key) ret = ret.map(x => [opt.key(x[0]), x[1]])
        return ret;
    }
}

window.all_query_set = []
class QuerySet {
    constructor(data, tag){
        this.data = data
        this.tag = tag || null;
        window.all_query_set.push(this)
    }

    static from_json(x, tag){
        return QuerySet(x, tag!==undefined?tag:this.tag)
    }

    add(x){
        return this.data.push(x)
    }

    evaluate(expr){
        return expr.execute(expr);
    }

    filter(fct, tag){
        return new QuerySet(this.data.filter(fct), tag)
    }

    map(fct, tag){
        return new QuerySet(this.data.map(fct), tag)
    }

    sum(field){
        var acc = 0;
        if(!(typeof field === "function")){
            var field_name = field;
            field = (x => x[field_name])
        }
        for(var x of this.data){
            acc+=field(x)
        }
        return acc
    }

    group_by_field(field, tag){
        return this.group_by({
            get_key: x => x[field],
            get_label: x => x[field]
        }, tag)
    }


    group_by(options, tag=null){
        var options = Object.assign({
            get_key: x => x,
            get_label: null,
            sort: (a,b) => a.date>b.date
        }, options)
        if(options.get_label==null) options.get_label = options.get_key
        this.data.sort(options.sort);

        var ret = new DataSet();
        ret.tag = tag;
        for(const op of this.data){
            ret.add(options.get_key(op), op, options.get_label(op));
        }
        return ret
    }

    group_by_day(opt){return this.group_by(Object.assign(opt || {}, { get_key: x => parse_period("day", x.get_date())}), "day")}
    group_by_week(opt){ return this.group_by(Object.assign(opt|| {}, { get_key: x => parse_period("week", x.get_date())}), "week")}
    group_by_month(opt){return this.group_by(Object.assign(opt|| {}, { get_key: x => parse_period("month", x.get_date())}), "month")}
    group_by_year(opt){return this.group_by(Object.assign(opt|| {}, { get_key: x => parse_period("year", x.get_date()) }), "year")}
    group_by_all(opt){return this.group_by(Object.assign(opt|| {}, { get_key: x => "" }))}

    copy(){
        return new QuerySet(this.data, this.tag)
    }

    filter_categories(cats, is_include=true, tag=undefined){
        is_include = (is_include=="true" || is_include==true)?true:false;
        var cats  = cats.map( x => parseInt(x));
        if(cats.length==0) return this.copy();
        const include_fct = (x) => (cats.indexOf(x.category) >= 0);
        const exclude_fct = (x) => (cats.indexOf(x.category) < 0);
        const fct = (is_include?include_fct:exclude_fct); 
        return new QuerySet(this.data.filter(fct), (tag===undefined?this.tag:tag));
    }

    filter_operation_types(ops, is_include=true, tag=undefined){
        is_include = (is_include=="true" || is_include==true)?true:false;
        if(ops.length==0) return this.copy();
        const include_fct = (x) => (ops.indexOf(x.type) >= 0);
        const exclude_fct = (x) => (ops.indexOf(x.type) < 0);
        const fct = (is_include?include_fct:exclude_fct); 
        return new QuerySet(this.data.filter(fct), (tag===undefined?this.tag:tag));
    }

    filter_categories_include_exclude(data=null, tag){
        data = data || {}
        return this.filter_categories(data.categories || [], data.include || true, tag);
    }

    filter_operation_types_include_exclude(data=null, tag){
        data = data || {}
        return this.filter_operation_types(data.operation_types || [], data.include || true, tag);
    }

    static from_ids(ids, ops_dict=null){
        ops_dict = ops_dict || Context.data.operations._indexed_data;
        return new QuerySet(ids.map(x => ops_dict[x]))
    }
}



export {
    QuerySet,
    DataEntry,
    DataSet,
    Operation
}
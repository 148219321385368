import { Api } from "banque/api/api";
import { show_validation } from "model/app/app_common";
import { CustomButton, ModalCloseButton, ModalLarge } from "widget";
import { DashboardEditorModal } from "./dashboard";

const { Component } = require("react");


class NodeMenu extends Component {
    constructor(props){
        super(props);
        var content = this.props.content;
        this.state = {
            is_open: true,
            is_leaf: content.type=="leaf",
            hover: false
        }
    }

    on_click(){
        if(this.state.is_leaf){
            this.props.onClick && this.props.onClick(this.props.content.content)
        }else{
            this.state.is_open = !this.state.is_open;
            this.setState({})
        }
    }

    _render_leaf(){
        var hover = (this.state.hover)?(" dashboard-list-leaf-hover"):""
        var edit_button = null;


        if(this.props.content.type=="leaf" &&  this.state.hover){
            edit_button = (<span className="nav-item dropdown dashboard-list-leaf-dropdown">
                <a className="nav-link" href="#"  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="bi bi-gear"/>
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <li><a className="dropdown-item" onClick={this._on_edit.bind(this)}>Editer</a></li>
                    <li><a className="dropdown-item" onClick={this._on_remove.bind(this)}>Supprimer</a></li>
                </ul>
            </span>)
        }
        return (
            <li 
                onMouseEnter={this._on_mouse_enter.bind(this)}
                onMouseLeave={this._on_mouse_leave.bind(this)}
                className={"dashboard-list-leaf "+hover} >
                <a onClick={this.on_click.bind(this)}>{this.props.content.content.name}</a>
                    {edit_button}
            </li>
        )
    }

    _on_mouse_enter(){
        this.setState({hover: true})
    }

    _on_mouse_leave(){
        this.setState({hover: false})
    }

    _on_edit(){
        this.props.holder.set_edit(this.props.content);
    }

    _on_remove(){
        var self = this;
        show_validation("Supprimer ?", "Êtes vous sur de vouloir supprimer le dashbord"+this.props.content.name, function(){
            Api.dashboard_remove(self.props.content.content.id, function(){
                self.props.holder && self.props.holder.remove(self.props.content); 
            })
        })
    }

    _render_node(){
        var hover = (this.state.hover)?("dashboard-list-node-hover"):""
        var content = null;
        if(this.state.is_open){
            var children=[];
            for(var child of this.props.content.content){
                child.parent = this.props.content
                children.push(<NodeMenu  
                        holder={this.props.holder}
                        onClick={this.props.onClick} 
                        content={child}
                        parent={this}
                        key={child.name}/>)
            }
            content=(
                <ul className={" dashboard-list-node-children"+(this.props.content.name!="/"?"":"-root")}>
                    {children}
                </ul>
            )
        }

        if(this.props.content.name!="/"){
            return (
                <li className={"dashboard-list-node "+hover} >
                    <span className="dashboard-list-node-label" onClick={this.on_click.bind(this)}>
                        <i className={"bi bi-caret-"+(this.state.is_open?"down":"right")}/>
                        {this.props.content.name}
                    </span>
    
                    {content}
                </li>
            )
        } else {
            return content
        }
        
    }

    render(){
        return this.state.is_leaf?this._render_leaf():this._render_node();
    }
}



class DashboardMenu extends Component {
    constructor(props){
        super(props);
        this.state = {
            is_adding: false,
            is_edit: null
        }
    }

    on_add(){
        this.setState({is_adding: true})
    }

    set_edit(data){
        this.setState({is_edit: data})
    }

    _get_from_path(path, create=false){
        var str_path = path
        path = path.substr(1).split("/")
        var current = this.props.dashboards;
        var old_path = "/"
        for(var i in path){
            var found = false;
            for(var content of current.content){
                if(content.name==path[i]){
                    current = content;
                    found = true;
                    break;
                }
            }
            if(found) continue;

            if(create){
                var tmp = {type: "node", name : path[i], content: [], parent: current}
                current.content.push(tmp)
                current = tmp;
            }else{
                console.error("Impossible de trouver le nom '"+path[i]+"' ('"+str_path+"') dans ", current.content, current);
                return null;
            }
            old_path+="/"+path[i]
            
            
        }
        return current
    }

    remove(data, update=true){
        var parent = this._get_from_path(data.content.path);
        if(!parent) return
        for(var i in parent.content){
            if(parent.content[i].name){
                parent.content.splice(i, 1);
                break
            }
        }
        var current = parent;
        while(!current.content.length && current.parent){
            for(var i in current.parent.content){
                if(current.parent.content[i].name == current.name){
                    current.parent.content.splice(i, 1);
                    current = current.parent;
                    break;
                }
            }
        }
        if(update){
            this.setState({})
        }
        this.props.parent.on_remove(data.content)


    }

    add(data){
        var parent = this._get_from_path(data.completename, true);
        parent.type = "leaf"
        parent.content = data
        this.setState({})
    }

    edit(old_data, data){
        this.remove({content: old_data})
        this.add(data)
    }

    render(){
        var modal = null;
        if(this.state.is_adding){
            modal = (<DashboardEditorModal 
                        onClose={() => this.setState({is_adding: false})}
                        onValidate={this.add.bind(this)}
                        value={{}}/>)
        }else if(this.state.is_edit){
            var old_data = Object.assign({}, this.state.is_edit.content);
            modal = (<DashboardEditorModal 
                        onClose={() => this.setState({is_edit: null})}
                        value={this.state.is_edit.content}
                        onValidate={this.edit.bind(this, old_data)}
                        />)

        }
        return (
            <div className="dashbords-menu">
                <h5>Dashboards</h5>
                {modal}
                <NodeMenu content={this.props.dashboards} onClick={this.props.onClick} holder={this}/>
                <CustomButton onClick={this.on_add.bind(this)} className="dashbords-menu-add">Ajouter</CustomButton>
            </div>
        )
    }
}


class DashboardMenuModal extends Component {
    constructor(props){
        super(props)
    }

    render(){

        return (<ModalLarge
            isOpen={true} >
            
            <div className="modal-header">
                <h5>Liste des dashboards</h5>
                <button type="button" className="btn-close" onClick={this.props.onClose}></button>
            </div>
            <div className="modal-body">
                <DashboardMenu {...this.props} />
            </div>
            <div className="modal-footer">
                <ModalCloseButton onClick={this.props.onClose}>Fermer</ModalCloseButton>
            </div>
        </ModalLarge>)
    }
}

export {
    DashboardMenu,
    DashboardMenuModal
}
import {Environemnent, parse} from "../expression"
import { Context } from "context";
import { CustomButton, Button, Entry, Select, Field, ColorChooser } from 'widget';
import './css/line.css'

import {Bar, Line} from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import { AbsGraphContent } from "banque/widget/graph/content/abscontent";
import { ExpressionListModal } from "banque/widget/expression/expression";
import { Component } from "react";
import { CheckBoxField, SelectField } from "widget/autorenderer";
import { Graph } from "./base";
import { OperationTypeIncludeExcludeField } from "widget/autorenderer/autorenderer";


ChartJS.register(...registerables);

class LineContent extends AbsGraphContent {
    constructor(props){
        super(props)
        this.state.expression_modal = null;
    }

    default_value(){
        return {
            expression: ''
        }
    }

    validate(){
        this.check_expression(this.state.expression);
        this.on_change()
    }

    _on_open_expression(){
        this.setState({expression_modal: true})
    }

    _on_close_expression(){
        this.setState({expression_modal: false})
    }

    _on_select_expression(x, y){
        var text = "@"+x.id+":"+x.name
        this.state.expression = text;
        this.setState({expression_modal: false})
    }

    _on_remove(){
        this.props.parent.on_content_delete(this.state.id)
    }
    

    render(){
        
        var _options_interpolation = {
            none: "Laisser vide",
            last_value: "Utiliser la dernière valeur",
            linear: "Linéaire"
        }
        var expr_modal = null;
        if(this.state.expression_modal){
            expr_modal = (<ExpressionListModal
                                value={Context.data.expressions} 
                                onClose={this._on_close_expression.bind(this)} 
                                onSelect={this._on_select_expression.bind(this)} />)
        }
        
        return (<div className="graph-content"> {this._get_error()}
            <Button className="graph-content-remove">
                <i className='bi bi-x' onClick={this._on_remove.bind(this)}></i>
            </Button>
            {expr_modal}
            <Field label="Nom" className=" graph-content-field">
                <Entry className="line-entry" onChange={this._on_change.bind(this, "label")} placeholder="Label" value={this.state.label||''}/>
                <ColorChooser onChange={this._on_change.bind(this, "color")} value={this.state.color} />
            </Field>
            <Field label="Interpolation" className=" graph-content-field">
                <Select options={_options_interpolation} onChange={this._on_change.bind(this, "interpolation")} selected={this.state.interpolation} /> 
            </Field>
            <Field label="Expression" className=" graph-content-field">
                <Entry className="line-entry" onChange={this._on_change.bind(this, "expression")} placeholder="Expression" value={this.state.expression||''}/>
                <CustomButton className="line-action" onClick={this.validate.bind(this)}><i className="bi bi-check" /></CustomButton>
                <CustomButton className="line-action" onClick={this.on_delete.bind(this)}><i className="bi bi-trash3" /></CustomButton>
                <CustomButton className="line-action" onClick={this._on_open_expression.bind(this)}><i className="bi bi-folder2-open" /></CustomButton>
            </Field>
        </div>)
    }

    val(){
        return {
            expression: this.state.expression,
            color: this.state.color,
            label: this.state.label,
            interpolation: this.state.interpolation
        }
    }
}



class LineContentData extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
          <div>
            <Line id={"graph-"+this.props.graph.id}
              data={this.props.data}
              options={{
                title:{
                  display:true,
                  text:'Average Rainfall per month',
                  fontSize:20
                },
                legend:{
                  display:true,
                  position:'right'
                }
              }}
            />
          </div>
        );
      }

}



class GraphLineData extends Graph {
    init(){
        this._grouped_data = this.operations["group_by_day"]().as_array();
        return
    }


    next_non_empty_index(i){
        for(var j=i; j<this._grouped_data.length; i++){
            if(this._grouped_data[j][1]!=null){
                return j;
            }
        }
    }

    compute_data(){
        var out = []
        var exprs = []
        var options = []
        var labels = []
        var last_non_empty=0;
        for(var expr of this.content){
            var x = []
            out.push(x)
            exprs.push(parse(this.resolve_expr(expr.expression)))
            options.push({
                label: expr.label,
                borderColor: expr.color,
                borderWidth: 2,
                tension: 0.2,
                data: x
            })
        }

        try{
            for(var i in this._grouped_data){
                var [label, data] = this._grouped_data[i];
                labels.push(label)
                if(data!=null){
                    var env = new Environemnent(data);
                    last_non_empty=i;
                    for(var j in this.content){
                        if(this.content){
                            out[j].push(exprs[j].execute(env))
                        }
                    }
                }
                else{
                    for(var j in this.content){
                        if(this.content[j].interpolate=="last_value"){
                            if(j) out[j].push(out[j][out[j].length-1])
                            else{
                                var next = this.next_non_empty_index(i);
                                var env = new Environemnent(this._grouped_data[i][1]);
                                out[j].push(exprs[j].execute(env))
                            }
                        }else if(this.content[j].interpolate=="linear"){
                            const last_value = out[j][last_non_empty];
                            var next = this.next_non_empty_index(i);
                            var env = new Environemnent(this._grouped_data[i][1]);
                            var next_value = exprs[j].execute(env);
                            const diff = next_value - last_value;
                            const prop = (i-last_non_empty) / ( next - last_non_empty)
                            out[j].push(last_value + (prop*(next_value-last_value)));
                        }else{
                            out[j].push(0)
                        }
                    }
                }
            }
        }
        catch {

        }
        var ret = {
            labels: labels,
            datasets: options
        }
        return ret
    }
}

Graph.register("lignes", {
    data: GraphLineData,
    label: "Lignes",
    options: [
        new CheckBoxField("starts_zero", "Commencer à 0", true),
        new SelectField("group", "Grouper", {
            year: "Grouper par ans",
            month: "Grouper par mois",
            day: "Grouper par jour",
            all: "Tout grouper",
        }),
        new OperationTypeIncludeExcludeField("operation_types", "Types d'opération", {}),
    ],
    content: LineContent,
    graph: LineContentData,
    multiple: true
})

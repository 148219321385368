import { Context } from "context";




class _Model {
    get_categories() {
        return Context.data.categories.get_array();
    }
    
    get_categories_names() {
        var ret = {};
        for(var obj of this.get_categories()){
            ret[obj.id] = obj.name;
        }
    }


    get_lists() {
        return Context.data.lists.get_array();
    }
    
    get_lists_names() {
        var ret = {};
        for(var obj of this.get_lists()){
            ret[obj.id] = obj.name;
        }
    }

    list_has_operations(listeid, operation_id){
        var obj = Context.data.lists.get(listeid);
        if(Array.isArray(operation_id)){
            for(var op of operation_id){
                if(!(op in obj.operations)) return false;
            }
            return true;
        }
        else{
            if(obj){
                return operation_id in obj.operations;
            }
            return false;
        }
        
    }

    get_accounts(){
        if(Context.data.account){
            return Context.data.account
        }
        return Context.data.accounts.get_array()
    }
}


export const Model = new _Model();
window.Model = Model;
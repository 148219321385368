import React, { Component } from "react";

import './css/button.css'
class Button extends Component {

  constructor(props) {
    super(props);
    this.attrs=Object.assign({}, this.props)
    if(this.attrs.className){
        this.attrs.className = "btn "+this.attrs.className;
    }else {
        this.attrs.className = "btn ";
    }
    this.state = {
    };
  }

  render() {
    return (<a {...this.attrs}> {this.props.children} </a>)
  }
}


function CustomButton(props){
    props = Object.assign({}, props)
    if(!props.className) props.className=""

    if(!props.btnType){
        props.btnType="primary"
    }
    props.className+=" btn-"+props.btnType

    delete props.btnType;
    return <Button {...props} />
}


function ModalCloseButton(props){
  props = Object.assign({}, props)
  var classname = " modal-footer-close";
  if(props.className){
    classname+=props.className;
  }
  props.className = classname;
  return <CustomButton {...props}/>
}




export {
    CustomButton,
    Button,
    ModalCloseButton
};
import React, { Component } from "react";
import { Form, Field, Entry, Integer, Float, Button, CustomButton, Modal, ModalSmall } from "widget";
import "./css/import.css";
import {Context} from 'context'

class ImportModal extends Component {
    constructor(props){
        super(props);
        this.state={
        }
    }

    render(){
        return (<ModalSmall
                isOpen={true}
                >
                    <form action="/import" encType='multipart/form-data' method="POST">
                        <div>
                            <select name="mode">
                                <option value="operations">Opérations</option>
                                <option value="category">Catégorie</option>
                            </select>
                        </div>
                        <div>
                            <input type="file" name="file" />
                        </div>
                        <div className="hidden">
                            <input type="text" value={Context.data.account.id} name="account" />
                        </div>
                        <div>
                            <button type="submit">Valider</button>
                        </div>

                    </form>
                </ModalSmall>)
    }
}


export {
    ImportModal
};
import { Component } from "react";
import { Button } from "./button";

export class ColorButton extends Component {
    constructor(props){
        super(props);
    }
    _on_change(){
        this.props.parent.on_change(this.props.color)
    }

    render(){
        return (<Button 
                    className="color-button" 
                    style={{backgroundColor: this.props.color}}
                    onClick={this._on_change.bind(this)} />)
    }
}

export class ColorChooser extends Component {
    constructor(props){
        super(props);
        this.state = {
            value: props.value || '#fff',
            is_open: false
        }
    }

    open(){
        this.setState({is_open: true});
    }

    close(){
        this.setState({is_open: false});
    }

    on_change(x){
        this.state.value = x
        this.state.is_open = false;
        this.props.onChange && this.props.onChange(x)
    }

    get_modal(){
        var colors = this.props.colors || [
            "#40407a",
            "#706fd3",
            "#f7f1e3",
            "#34ace0",
            "#33d9b2",
            "#ff5252",
            "#ff793f",
            "#d1ccc0",
            "#ffb142",
            "#ffda79"
        ]
        var ret = colors.map(x => (<ColorButton key={x} color={x} parent={this}/>))
        return (<span className="color-chooser-colors">
            {ret}
        </span>)

    }

    render(){
        var chooser = (this.state.is_open)?this.get_modal():null;
        var color = {backgroundColor: this.state.value+""}
        var btn = [<Button className="color-chooser-button"  style={color} onClick={this.open.bind(this)} key={color.backgroundColor}/>]

        return (<>
            {btn}
            {chooser}
        </>)
    }
}



import React, { Component } from "react";
import {AccountThumbnail} from 'banque/widget/account/thumbnail';
import {GraphEditor} from "banque/widget/graph/editor"
import {Integer, Float} from 'widget/base/entry';


class AccountThumbnailList extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var liste = [];
    this.props.accounts.foreach(x => liste.push(<AccountThumbnail {...x} key={x.id} />))

    return (<div>
      <GraphEditor />
        <div className="account-thumbnail-holder">
                {liste}
    </div>
   </div>)
  }
}

export {
    AccountThumbnailList
};

import React, { Component } from "react";
import {Select, MultipleSelect,DatePicker, DateStartEndPicker, CustomButton, Entry, ModalSmall, Error, Validation} from 'widget';
import {Context} from 'context'
import {CategoryThumbnailList} from 'banque/widget/category/list'
import {CategorySelect} from 'banque/widget/category/select';
import {QuerySet} from 'model/stats/queryset';
import {parse, Environemnent} from 'model/stats/expression'
import {Api} from 'banque/api/api'

import './css/expression.css'

class ExpressionEditor extends Component {
    constructor(props){
        super(props);
        var value = props.value || {};
        this.state = {
            name: value.name || "",
            expression: value.expression || "",
            id: value.id || "",
            error: null
        }
    }

    val(){
        var x =  {
            
            expression: this.state.expression,
            name: this.state.name
        }
        if(this.state.id != ""){
            x.id = this.state.id;
        }
        return x;
    }

    error(str){
        this.setState({error: <div dangerouslySetInnerHTML={{__html: str}}></div>})
    }

    on_change(key, value){
        this.setState({[key]: value})
    }

    on_validate(){
        var op = this.on_check();
        if(!op.valid) return;
        this.props.parent.on_send(this.val())
    }

    on_check(){
        var op = parse(this.state.expression);
        if(op.valid){
            return op
        }else{
            this.error("<b>Erreur:</b> "+op.error.message+"<br><b>Expression: </b> "+this.state.expression)
        }
        return null;
    }

    on_close_error(){
        this.setState({error: null});
    }

    render(){
        var error = null;
        if(this.state.error){
            error = <Error onClose={this.on_close_error.bind(this)}>{this.state.error}</Error>
        }
        return (
            <div className="expression-editor">
                {error}
                <div>
                    <span>Nom</span><Entry onChange={this.on_change.bind(this, "name")} value={this.state.name} />
                </div>
                <div>
                    <span>Expression</span><Entry onChange={this.on_change.bind(this, "expression")} value={this.state.expression}  />
                </div>
                <div>
                    <Entry className="hidden" value={this.state.id}  />
                </div>
                <div>
                    <CustomButton onClick={this.on_validate.bind(this)}>Valider</CustomButton>
                    <CustomButton onClick={this.on_check.bind(this)}>Verifier</CustomButton>
                </div>
            </div>
        )
    }

}

function ExpressionEditorModal(props){
    return (
        <ModalSmall
            isOpen={true} >
            
            <div className="modal-header">
                <h5>Edition d'expression</h5>
                <button type="button" className="btn-close" onClick={props.onClose}></button>
            </div>
            <div className="modal-body">
                <ExpressionEditor value={props.value} parent={props.parent} />
            </div>
            <div className="modal-footer">
                <CustomButton onClick={props.onClose}>Annuler</CustomButton>
            </div>
        </ModalSmall>
    )
}

class ExpressionThumbnail extends Component {
    /**
     * 
     * @param {*} props
     *   
     */
    constructor(props){
        super(props);
        this.state = {};
    }
    
    on_edit(){
        this.props.parent.on_edit(this.props.value);
    }

    on_remove(){
        this.props.parent.on_remove(this.props.value);
    }

    _on_select(){
        this.props.onSelect(this.props.value)
    }

    render(){
        var select = null;
        if(this.props.onSelect){

            select = (<i className="bi bi-check2 expression-thumbnail-action" onClick={this._on_select.bind(this)}/>)
        }
        return (<tr>
            <td>{this.props.value.name}</td>
            <td>{this.props.value.expression}</td>
            <td>
                {select}
                <i className="bi bi-pencil expression-thumbnail-action" onClick={this.on_edit.bind(this)}/>
                <i className="bi bi-trash3 expression-thumbnail-action" onClick={this.on_remove.bind(this)}/>
            </td>
        </tr>)
    }
}


class ExpressionList extends Component {
    constructor(props){
        super(props);
        this.state = {
            value: props.value || [],
            edit: null,
            remove: null,
            add: false
        };
    }

    on_edit(data){
        this.setState({edit: data})
    }

    on_remove(data){
        this.setState({remove: data})
    }

    on_add(){
        this.setState({edit: {}})
    }

    on_remove_validate(data){
        var self = this;
        Api.remove_expression(data, function(d){
            self.props.value.remove(data);
            self.setState({remove: null});
        });
    }

    on_send(val){
        var self = this;
        if(val.id){
            Api.edit_expression(val, function(data){
                self.props.value.edit(val);
                self.setState({edit: null});
            });
        }else{
            Api.create_expression(val, function(data){
                var d = data.create.expression;
                if(d.length){
                    for(var x of d)
                        self.props.value.add(x)
                }
                self.setState({edit: null});
            });
        }
    }

    thumbnails(){
        var ret = [];
        var select = {};
        if(this.props.onSelect)
            select.onSelect = this.props.onSelect;
        for(var expr of this.state.value.get_array()){
            ret.push(<ExpressionThumbnail value={expr} key={expr.id} parent={this} {...select}/>)
        }
        return ret;
    }

    close_modal(modal){
        this.setState({[modal] : null})
    }

    render(){
        var edit = null, remove = null;
        if(this.state.edit){
            edit = (<ExpressionEditorModal value={this.state.edit} onClose={this.close_modal.bind(this, "edit")} parent={this} />)
        }
        if(this.state.remove){
            remove = (<Validation 
                        onCancel={this.close_modal.bind(this, "remove")} 
                        onValidate={this.on_remove_validate.bind(this, this.state.remove)} >
                Voulez vous vraiment supprimer l'expression '{this.state.remove.name}' : '{this.state.remove.expression}' ?
            </Validation>)
        }

        return (<div className="expression-list">
            {edit}
            {remove}
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Nom</th>
                        <th>Expression</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {this.thumbnails()}
                </tbody>
            </table>
            <CustomButton onClick={this.on_add.bind(this)}>Ajouter</CustomButton>
        </div>);
    }
}

function ExpressionListModal(props){
    return (
        <ModalSmall
            isOpen={true} >
            
            <div className="modal-header">
                <h5>Liste des expressions</h5>
                <button type="button" className="btn-close" onClick={props.onClose}></button>
            </div>
            <div className="modal-body">
                <ExpressionList {...props}/>
            </div>
            <div className="modal-footer">
                <CustomButton onClick={props.onClose}>Fermer</CustomButton>
            </div>
        </ModalSmall>
    )
}

export {
    ExpressionList,
    ExpressionListModal
};
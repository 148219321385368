import React, { Component } from "react";
import {Entry, Password, Hidden, CustomButton} from "widget";

import {Context} from 'context'
import './graphs.css'
import { QuerySet } from "model/stats/queryset";
import { GraphThumbnail } from "banque/widget/graph/thumbnail";
import { GraphEditorModal } from "banque/widget/graph/editor";
import { page_register } from "model/app/app_common";
import { CategoryIncludeExcludeField, SelectField } from "widget/autorenderer";
import { get_option, get_options_descriptions } from "widget/autorenderer/autorenderer";



class GraphPage extends Component {

    constructor(props){
        super(props)

        this.state = {
            graphs: props.graphs || Context.data.graphs,
            operations: props.operations || Context.data.operations,
            expressions: props.expressions || Context.data.expressions,
            is_adding: false,
            current_fullscreen : null
        }
        for(var i in this.state.graphs){
            if(Array.isArray(this.state.graphs[i].operations)){
                this.state.graphs[i].operations = QuerySet.from_ids(this.state.graphs[i].operations)
            }
        }
    }

    on_create(data){
        data.operations = new QuerySet(data.operations)
        this.state.graphs[data.id] = data;
        this.setState({is_adding: false})
    }

    on_add(){
        this.setState({is_adding: true});
    }

    close(){
        this.setState({is_adding: false});
    }

    remove(id){
        delete this.state.graphs[id];
        this.setState({})
    }

    set_fullscreen(id){
        this.state.current_fullscreen = id;
        this.setState({})
    }

    render(){

        var modal = (this.state.is_adding)?(<GraphEditorModal holder={this}  onClose={this.close.bind(this)}  />):null;
        if(this.state.current_fullscreen){
            return (<div className="graph-page-root">
                {modal}
                <GraphThumbnail 
                        holder={this} 
                        parent={this} 
                        value={this.state.graphs[this.state.current_fullscreen]} 
                        key={i} 
                        isFullscreen={true}/>
            </div>);

        }else{
            
            var graphs = []
            var nb = parseInt(get_option("ui.graphs.graphs_per_line"));
            var i = 0;
            for(var g in this.state.graphs){
                var x = i % nb + 1;
                var y = parseInt(i / nb) +1 ;
                graphs.push(<GraphThumbnail 
                        holder={this} 
                        parent={this} 
                        value={this.state.graphs[g]} 
                        key={i} 
                        style={{
                            gridColumn: x,
                            gridRow: y,
                        }}/>)
                i+=1;
            }

            return (<div className="graph-page-root">
                {modal}
                <div className="graph-page-root-grid" style={{
                    gridTemplateColumns: "repeat("+nb+", 1fr)",
                    gridAutoRows: "fit-content(max-content)"
                }}>
                    {graphs}
                </div>
                <div className="graph-page-root-add">
                    <CustomButton onClick={this.on_add.bind(this)}>Ajouter</CustomButton>
                </div>
            </div>);
        }
        
    }
}

page_register("graphs",{
    page: (() => <GraphPage {...Context.data} />),
    options: "ui.graphs"
})

export {GraphPage}
import React, { Component } from "react";
import {Context} from 'context'
import {CategoryManagerModal} from 'banque/widget/category/list'
import "./nav.css"
import {ImportModal} from "banque/widget/import/import"
import { ListManagerModal } from "banque/widget/list/list";
import { Integer } from "widget";
import { show_validation } from "model/app/app_common";
import { Api } from "banque/api/api";
import { PageOption } from "./options";
import { get_options_descriptions, get_options_descriptions_list } from "widget/autorenderer/autorenderer";
import { ProvisionList, ProvisionListModal } from "banque/widget/provision/provision";

class NavBar extends Component {
    constructor(props){
        super(props);
        this.state={
          show_category_modal : false,
          show_import_modal : false,
          show_list_modal : false,
          show_provision_modal: false,
        }

        this.has_operation = Context.data.operations != undefined;
        this.has_categories = Context.data.categories != undefined;
        this.has_lists = Context.data.lists != undefined;
        this.has_accounts = Context.data.accounts != undefined;
    }

    _home(){
        return "Mes comptes"
    }

    _categories(){
      if(!this.has_categories) return null;
      return (
          <li className="nav-item">
            <a className="nav-link active" aria-current="page" onClick={()=>this.setState({show_category_modal: true})} >Catégories</a>
          </li>)
  }

  _lists(){
    if(!this.has_lists) return null;
    return (
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" onClick={()=>this.setState({show_list_modal: true})} >Listes</a>
        </li>)
    }

    _provisions(){
      return (
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" onClick={()=>this.setState({show_provision_modal: true})} >Provisions</a>
        </li>)
    }
  

    _on_imported(value){
      var ret = [];
      for(var [compte, x] of Object.entries(value)){
          if(Number.isInteger(x)){
            x = x+" opérations ajoutés"
          }
          ret.push(<p key={compte}>{compte}:{x}</p>)
      }

      show_validation("Mise à jour", ret)
    }

    _import(){
        if(!this.has_categories) return null;
        return (
            <li className="nav-item">
              <a className="nav-link active" aria-current="page" onClick={()=>Api.update_operations(this._on_imported.bind(this))} >Mettre à jour</a>
            </li>)
    }

    _content_import(){
        return this.state.show_import_modal?(<ImportModal onClose={()=>this.setState({show_import_modal: false})} />):null;
    }

    _content_category(){
      if(!this.has_categories) return null;
      return this.state.show_category_modal?(<CategoryManagerModal onClose={()=>this.setState({show_category_modal: false})} />):null;
  }

  _content_provisions(){
    if(this.state.show_provision_modal){
      return (<ProvisionListModal onClose={()=>this.setState({show_provision_modal: false})} />)
    }
    return null
  }

  _content_list(){
    if(!this.has_lists) return null;
    return this.state.show_list_modal?(<ListManagerModal onClose={()=>this.setState({show_list_modal: false})} />):null;
  }

    _operations(){

    }


    _statistiques(){
        return (
            <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="/graphs" >Statistiques</a>
            </li>)
    }

    _page_options(){
      if(!this.props.options){
        return null;
      }
      return (<PageOption options={get_options_descriptions_list(this.props.options)} app={this.props.parent}/>)
    }


    
    render(){
        
        return (
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
            {this._content_category()}
            {this._content_list()}
            {this._content_provisions()}
            {this._content_import()}
              <div className="container-fluid">
                <a className="navbar-brand" href="/">{this._home()}</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    {this._categories()}
                    {this._lists()}
                    {this._provisions()}
                    {this._import()}
                  </ul>
                </div>
              </div>
              {this._page_options()}
            </nav>
        )
    }

}

/*




<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">Navbar</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Link</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Dropdown
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" href="#">Action</a></li>
            <li><a class="dropdown-item" href="#">Another action</a></li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
        </li>
      </ul>
      <form class="d-flex">
        <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
        <button class="btn btn-outline-success" type="submit">Search</button>
      </form>
    </div>
  </div>
</nav>*/

export {
    NavBar
};
import React, { Component } from "react";
import {ModalSmall} from "widget/modal/modal"
import {Context} from 'context';
import { Button, CustomButton, DatePicker, Entry, ModalCloseButton } from "widget";
import { Api } from "banque/api/api";
import { SimpleOperationTypeSelect } from "../filter";




class ModalInfo extends Component {
    constructor(props){
        super(props)

        this.state = {
            value: {
                id : this.props.operation.id,
                date : this.props.operation.date,
                type : this.props.operation.type,
                libelle : this.props.operation.libelle,
            }
        }
    }

    set_operation(data){
        
        this.state.value = {
                id : data.id,
                date : data.date,
                type : data.type,
                libelle : data.libelle,
                comment : data.comment,
        }
        //this.setState({})
    }

    on_remove(){
        var self = this;
        Api.operation_delete(this.props.operation.id,
        function(x){
            self.props.parent.remove_operation({id: self.props.operation.id});
            self.props.onClose()
        });
    }

    on_edit(){
        const self = this;
        Api.operation_edit(this.state.value, 
            function(data){
                self.props.parent.edit_operation(self.state.value);
                self.props.onClose()
            });
        
    }

    on_change(key, value){
        this.state.value[key] = value;
        this.setState({})
    }

    render(){
        const operation = this.props.operation
        const operation_data = [
            ["Libellé", operation.libelle, Entry, "libelle"],
            ["Montant", operation.montant+" €"],
            ["Catégories", Context.data.categories.select(operation.category, x => x.name).join(", ")],
            ["Type", operation.type, SimpleOperationTypeSelect, "type"],
            ["Date", operation.date, DatePicker, "date"],
            ["Mandat", operation.mandat],
            ["Creancier", operation.creancier],
            ["Solde", operation.solde],
            ["Commentaire", operation.solde, Entry, "comment"],
            ["Virtuelle", operation.virtual?"Oui":"Non"],
            ["Id", operation.id],
        ]

        var props = this.props;
        var supprimer = null;
        if(true || this.props.operation.virtual){
            supprimer = (<CustomButton onClick={this.on_remove.bind(this)}>Supprimer</CustomButton>)
        }
        var liste=[];
        for(var i=0; i<operation_data.length; i++){
            var content =operation_data[i][1];
            if(operation_data[i].length>3){
                var Classe = operation_data[i][2];
                var name  = operation_data[i][3];
                content = (<Classe value={this.state.value[name] || ""} onChange={this.on_change.bind(this, name)} />)
            }

            liste.push((<tr key={i}><td>{operation_data[i][0]}</td><td>{content}</td></tr>))
        }
        return <ModalSmall
            isOpen={this.props.operation!=false}
            onRequestClose={props.onClose}
            className=""
            contentLabel="Example Modal"
        >

            <div className="modal-header">
                <h5 className="modal-title">Opération</h5>
                <button type="button" className="btn-close" onClick={props.onClose}></button>
            </div>
            <div className="modal-body">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Nom</th>
                            <th scope="col">Valeur</th>
                        </tr>
                    </thead>
                    <tbody>
                        {liste}
                    </tbody>
                </table>
                {supprimer}
            </div>
            <div className="modal-footer">
                <CustomButton type="button" className="btn btn-secondary"  onClick={this.on_edit.bind(this)}>Modifier</CustomButton>
                <ModalCloseButton type="button" className="btn btn-secondary"  onClick={props.onClose}>Fermer</ModalCloseButton>
            </div>
        </ModalSmall>
    }
}

export {
    ModalInfo
};
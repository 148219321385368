import { Component } from "react";
import './icons.css'

function filter_props(props){
    var ret = {}
    for(var [key, value] of Object.entries(props)){
        if(key != "children" && key != "className" && key != "icon")
            ret[key] = value;
    }
    return ret;
}

class MaterialIcon extends Component {
    render(){
        var icon = this.props.icon||this.props.children;
        return <i className={"material-icons "+(this.props.className?this.props.className:"")} 
                    {...filter_props(this.props)}>{icon}</i>        
    }
}
class BootstrapIcon extends Component {
    render(){
        var icon = this.props.icon||this.props.children;
        
        return <i className={"bi bi-"+icon+" "+(this.props.className?this.props.className:"")} {...filter_props(this.props)}/>        
    }
}

class Icon extends Component {
    render(){
        var is_bi = false;
        var icon = this.props.icon||this.props.children;
        if(icon.startsWith("bi-")){
            return <BootstrapIcon {...filter_props(this.props)} className={this.props.className} icon={icon.substr(3)} />
        }

        return <MaterialIcon {...filter_props(this.props)} className={this.props.className} icon={icon} />
        
    }
}

export {
    MaterialIcon,
    BootstrapIcon,
    Icon
}
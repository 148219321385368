import {Context} from "context"
import $ from "jquery";
import { AppCommon, show_error, modal_register, show_error_unescaped } from 'model/app/app_common';


function server_url(){
    console.log("server_url", window.server, window.server_settings)
    return window.server_settings.server.url
}

class _Api {
    constructor(){

    }

    _send(method, url, data, headers, success, error){
        var self = this;
        url = server_url()+url;
        window.$.ajax({
            method: method,
            url: url,
            headers: headers || {},
            data: data?JSON.stringify(data):null,
            success: this._success_wrapper(success),
            error: function (...args){self._error_wrapper(error, {
                method: method,
                url: url,
                headers: headers || {},
                data: data?JSON.stringify(data):null,
            }, ...args)}
        })
    }

    _post(url, data, headers, success, error){this._send("POST", url, data, headers, success, error)}
    _put(url, data, headers, success, error){this._send("PUT", url, data, headers, success, error)}
    _get(url, success, error){this._send("GET", url, "", null, success, error) }
    _delete(url, success, error, data){this._send("DELETE", url, data || "", null, success, error) }

    _success_wrapper(fct){
        return function(data){
            console.log("Success", data)
            fct && fct(data.data, data.code, data.message);
        }
    }

    _error_wrapper(fct, data,  response){

        console.log("Error", data)
        if(fct){
            return fct(data, response.responseJSON || null)
        }
        if(response.status==0){
            show_error("Erreur:", "Impossible de joindre le serveur")
        }else{
            var json = response.responseJSON
            if(json){
                show_error("Erreur "+json.code+" : "+json.message, json.data)
            }else{
                show_error_unescaped("Error", response.responseText)
            }
        }
    }

    _post_json(url, data, success, error){
        return this._post(url, data, {"Content-Type": "application/json"}, success, error)
    }

    _put_json(url, data, success, error){
        return this._put(url, data, {"Content-Type": "application/json"}, success, error)
    }

    _get_json(url, success, error){
        return this._get(url, success, error)
    }

    _delete_json(url, success, error, data){
        return this._delete(url, success, error, data)
    }

    _set_account(data){
        if(window.data.account){
            return Object.assign({
                account: window.data.account.id
            }, data)
        }
        return Object.assign({
            account: window.data.accounts.get_array()[0].id
        }, data)
    }

    _set_user(data){
        return Object.assign({
            user: window.user.name
        }, data)
    }

    _update_operations(fct){
        return function(data, code, message){
            //if( data && data.query){
                Context.data.operations.update(data.query.operation);
            //}
            return fct(data, code, message)
        }
    }

    _replace_operations(fct){
        return function(data, code, message){
            //if( data && data.query){
                Context.data.operations.set(data.query.operation);
            //}
            return fct(data, code, message)
        }
    }

    operation_query(data, success, error){
        this._post_json("/api", {
            "query" : {
                "operation" : this._set_account(data)
            }
        }, success, error);
    }

    edit_category(data, success, error){

        this._post_json("/api", {
            "update" : {
                "category": this._set_account(data)
            },
            "query" : {
                "operation" : this._set_account({q :Context.data.query})
            }

        }, this._update_operations(success), error)
    }

    create_category(data, success, error){
        this._post_json("/api", {
            "create" : {
                "category":  this._set_account(data)
            },
            "query" : {
                "operation" : this._set_account({q :Context.data.query})
            }
        }, this._update_operations(success), error)
    }

    edit_list(data, success, error){

        this._post_json("/api", {
            "update" : {
                "list": this._set_account(data)
            }

        }, success, error)
    }

    create_list(data, success, error){
        this._post_json("/api", {
            "create" : {
                "list":  this._set_account(data)
            }
        }, success, error)
    }

    add_pattern(catid, data, success, error){
        this._put_json("/category/"+catid+"/pattern", data, success, error)
    }

    update_pattern(catid, pat, success, error){
        this._post_json("/category/"+catid+"/pattern/"+pat.id, pat, success, error)
    }

    remove_pattern(catid, patid, success, error){
        this._delete_json("/category/"+catid+"/pattern/"+patid, success, error);
    }

    remove_category(data, success, error){
        this._post_json("/api", {
            "delete" : {
                "category":  this._set_account(data)
            },
            "query" : {
                "operation" : this._set_account({q :Context.data.query})
            }
        }, this._update_operations(success), error)
    }

    operation_edit(data, success, error){
        this._post_json("/api", {
            "update" : {
                "operation":  data
            }
        }, success, error)
    }

    operation_delete(id, success, error){
        this._post_json("/api", {
            "delete" : {
                "operation":  [{id: id}]
            }
        }, success, error)
    }

    remove_list(data, success, error){
        this._post_json("/api", {
            "delete" : {
                "list":  this._set_account(data)
            }
        }, success, error)
    }

    update_operations(success, error){
        this._get_json("/update?json=true", success, error);
    }

    test_pattern(data, success, error){
        this._post_json("/test/pattern", this._set_account(data), success, error);
    }

    operation_add_category(opid, catid, success, error){
        this._put_json("/operation/"+opid+"/category/"+catid, {}, success, error)
    }

    operation_remove_category(opid, success, error){
        this._delete_json("/operation/"+opid+"/category", success, error)
    }

    list_add_operations(listeid, operations, success, error){
        operations = (Array.isArray(operations)?operations:[operations])
        this._put_json("/list/"+listeid+"/add", operations, success, error)
    }

    list_remove_operations(listeid, operations, success, error){
        operations = (Array.isArray(operations)?operations:[operations])
        this._delete_json("/list/"+listeid+"/remove", success, error, operations)
    }

    update_preferences(profile, data, success, error){
        this._post_json("/preferences/"+profile, data, success, error)
    }
    
    filter_operation(data, success, error){
        this._post_json("/api", {
            "query" : {
                "operation" : this._set_account(data)
            }
        }, this._replace_operations(success), error)
    }

    edit_expression(data, success, error){

        this._post_json("/api", {
            "update" : {
                "expression": this._set_user(data)
            }

        }, success, error)
    }

    edit_or_create_provision(data, success, error){
        if(data.id){
            return this._post_json("/provision/"+data.id, data, success, error)
        }else{
            return this._post_json("/provision", data, success, error)
        }
    }

    remove_provision(id, success, error){
        return this._delete_json("/provision/"+id, success, error)
    }

    create_expression(data, success, error){
        this._post_json("/api", {
            "create" : {
                "expression":  this._set_user(data)
            }
        }, success, error)
    }

    remove_expression(data, success, error){
        this._post_json("/api", {
            "delete" : {
                "expression":  this._set_user(data)
            }
        }, success, error)
    }

    update_or_create_graph(data, success, error){
        var action = (data.id===undefined)?"create":"update";
        this._post_json("/api", {
            [action] : {
                "graph":  this._set_user(data)
            }
        }, success, error)
    }

    remove_graph(graphid, success, error){
        var graph = {id: graphid}
        this._post_json("/api", {
            "delete" : {
                "graph":  this._set_user(graph)
            }
        }, success, error)
    }


    graph_translate(graph, offset, success, error){
        var g = {}
        const fields = ["id", "account_id", "content", "name", "type", "multiple", "user_id", "options", "period"]
        for(var [k, v] of Object.entries(graph)){
            if(fields.indexOf(k)>=0) g[k] = v
        }
        this._post_json("/graph/translate/"+offset, g, success, error)
    }


    get_context(page, url_data, postparams){
        console.log(server_url()+"/context/"+page)
        var ret = $.ajax({
            method: "POST",
            url: server_url()+"/context/"+page,
            headers: {"Content-Type" : "application/json"},
            async: false,
            data: JSON.stringify({url_data: url_data || {}, post: postparams || {}}),
            dataType: "json"
        });
        return ret.responseJSON.data.context
    }

    get_context_async(success, error){
        this._post_json("/context/"+window.server_settings.page, {}, success, error)
    }


    dashboard_add(data, success, error){
        return this._post_json("/dashboard", data, success, error)
    }

    dashboard_update(data, success, error){
        return this._post_json("/dashboard/"+data.id+"/update", data, success, error)
    }

    dashboard_remove(id, success, error){
        return this._delete_json("/dashboard/"+id+"/remove", success, error)
    }

    dashboard_tile_add(did, data, success, error){
        return this._post_json("/dashboard/"+did+"/tile", data, success, error)
    }

    dashboard_tile_update(did, data, success, error){
        return this._post_json("/dashboard/"+did+"/tile/"+data.id+"/update", data, success, error)
    }

    dashboard_tile_remove(did, tid, success, error){
        return this._delete_json("/dashboard/"+did+"/tile/"+tid+"/remove", success, error)
    }

    dashboard_update_or_create(data, success, error){
        if(data.id) return this.dashboard_update(data, success, error)
        else return this.dashboard_add(data, success, error)
    }


    dashboard_tile_update_or_create(did, data, success, error){
        if(data.id) return this.dashboard_tile_update(did, data, success, error)
        else return this.dashboard_tile_add(did, data, success, error)
    }


}

var Api = new _Api();

export {
    Api
};
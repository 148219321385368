
import { pretty_str } from 'model/date';
import {Bar, Line, Doughnut, Pie} from 'react-chartjs-2';
import { CategoryIncludeExcludeField, SelectField } from 'widget/autorenderer';
import { OperationTypeIncludeExcludeField } from 'widget/autorenderer/autorenderer';

const { Component } = require("react");
const { DataEntry, Operation, QuerySet } = require("../queryset");
const { Graph, colors_alpha, Value, get_chart_type } = require("./base");
const { Context } = require('context');


class HistoricalBar extends Graph {
    init(){
        window.bar = this;
        this.options.periode = this.options.periode===undefined?"week":this.options.periode;
        this.options.produit = this.options.produit===undefined?"charges":this.options.produit; 
        this.options.synthese = this.options.synthese===undefined?"abs":this.options.synthese; 
    }

    on_click(graph, evt){
        var x = this.get_dataset_by_event(evt);
        if(x===null) return;
        graph.props.parent.show_operations(x.operations)
    }

    get_dataset(cat, values, i){
        cat = Context.data.categories.get(cat);
        var content = null;
        if(this.options.synthese=="abs"){
            var fct = x => Value.from_operations(x)
            if(this.options.produit=="produits"){
                fct = x => Value.from_abs_operations(x, a => a.montant>0)
            }else if(this.options.produit=="charges"){
                fct = x => Value.from_abs_operations(x, a => a.montant<0)
            }
            content = values.map(fct)
        }else{
            var self = this;
            function _fct(x){
                var sum = x.sum();
                if(self.options.produit=="produits"){
                    if(sum>0) return Value.from_abs_operations(x);
                }
                else if(self.options.produit=="charges"){
                    if(sum<0) return Value.from_abs_operations(x);
                }
                else{
                    return Value.from_operations(x);
                }
                return new Value();
            }
            content =  values.map(_fct)
        }

        return {
            label: cat?cat.name:"Sans catégorie",
            data: content,
            backgroundColor: colors_alpha[i % colors_alpha.length],
            fill: true,
            tension: 0.1
        }
    }

    compute_data(){
        var datasets = []
        var data = this.operations["group_by_"+this.options.periode]({get_label: x => x.category})
        var cats = {}
        var labels = [];
        var cat_name = {}
        for(var cat of Context.data.categories.get_array()){
            cats[cat.id] = [];
            cat_name[cat.id] = cat.name;
        }
        this.cats=cats
        cats[-1] = [];
        cat_name[-1] = "Sans catégorie";
        this.group = data;

        for(var [group, values] of Object.entries(data.data)){
            var scat = {}
            labels.push(pretty_str(group))
            for(var cat of Object.keys(cats)){
                var de = new DataEntry(cat_name[cat]);
                if(cat in values.categories){
                    for(var op of values.categories[cat])
                        if(this.filter_operation(op)) de.add(op);
                }   
                cats[cat].push(de)
            }
        }
        for(var [cat, values] of Object.entries(cats)){
            datasets.push(this.get_dataset(cat, values, datasets.length))
        }
        
        var data = {
            labels: labels,
            datasets: datasets
        }
        
        return data;
    }
}


class HistoricalBarGraph extends Component {
    constructor(props){
        super(props)
        window.graph = this;
    }
    render() {
        var Classe = get_chart_type(this.props.graph.options.affichage);

        return (
          <div>
            <Classe id={"graph-"+this.props.graph.id}
                options ={{
                responsive: true, 
                scales: {  x: { stacked: true }, y: { stacked: true}},
                onClick: this.props.graph.on_click.bind(this.props.graph, this),
                parsing: {yAxisKey: 'value', xAxisKey: 'value'}
              }}
              data={this.props.data}
            />
          </div>
        );
      }
}


Graph.register("historical_bar", {
    data: HistoricalBar,
    label: "Historique en bar",
    graph: HistoricalBarGraph,
    options: [
        new SelectField("affichage", "Affichage", {
            line: "Lignes",
            bar: "Bar"
        }, "bar"),
        new SelectField("periode", "Période", {
            week: "Semaines",
            month: "Mois",
            year: "Années"
        }, "month"),
        new SelectField("produit", "Produit/Charge", {
            produits: "Produits",
            charges: "Charges",
            both: "Les deux"
        }, "charges"),
        new SelectField("synthese", "Calcul", {
            sum: "Somme des categories",
            abs: "Valeur absolue",
        }, "abs"),
        new CategoryIncludeExcludeField("filter_categories", "Catégories", {
            categories: [], include: false
        }),
        new OperationTypeIncludeExcludeField("operation_types", "Types d'opération", {}),
    ]
})
import { OperationFilter } from "banque/widget/operation/filter";
import { OperationThumbnailList } from "banque/widget/operation/list";
import { ListManager } from "model/manager";
import { Operation } from "model/stats/operation";
import React from "react";
import  { Tile } from "../abs_tile";


class TileOperations extends Tile {
    init(){
        this.operations = React.createRef();
        this.filters = React.createRef();
        
        this.state.operations = new ListManager(this.props.content.content.operations, Operation);
        
        this.state.filters = this.props.content.content.q || {};
    }


    refresh(x){
        this.state.operations = new ListManager(x, Operation);
        this.operations.current.refresh(x);
        this.filters.current.setState(x);
        this.setState({})
    }

    render_content(){
        return <OperationThumbnailList operations={this.state.operations} 
                                max_libelle_length={32} 
                                disableFilter={true}
                                ref={this.operations}/>
    }

    render_options(){
        return (<OperationFilter
                            value={this.state.filters} 
                            headless={true}
                            onValidate={this.refresh.bind(this)} 
                            operations={this.state.operations}
                            ref={this.filters}/>);
            
    }

    static val(value){
        var v = Object.assign({}, value);
        v.graph = null;
        v.content = Object.assign({}, v.content || {});
        if(v.content.operations) v.content.operations=null;
        return v;
    }
    
    static editor_set_value(editor, k, v){
        if(!editor.state.value.content.q) editor.state.value.content.q={}
        editor.on_change(k, v, editor.state.value.content.q)
    }

    static render_editor_content(editor){
        
        return (
            <OperationFilter
                            value={editor.state.value.content.q || {}} 
                            headless={true}
                            onChange={ (k,v) => TileOperations.editor_set_value(editor, k, v)}  />
        )
    }

}


Tile.register_tile("operations", {
        classe: TileOperations,
        label: "Operations"
})


export {
    TileOperations
}
import React, { Component } from "react";
import {CustomButton} from "widget/base/button";
import {Entry, Password, Hidden} from "widget/base/entry";

import './operations.css'
import {OperationThumbnailList} from 'banque/widget/operation/list'
import { page_register } from "model/app/app_common";
import { Context } from "context";




class ListOperations extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    var error = window.data.error?(<p>{window.data.error}</p>):null;
    return (<OperationThumbnailList {...this.props}/>)
  }

}

page_register("operations",{
  page: (() => <ListOperations {...Context.data} />)
})

export { ListOperations };
import React, { Component } from "react";
import './css/select.css';



class Select extends Component {
    /*
        props:
             - className (str) : classe du bouton d'ouverture
             - selected (str): clé de l'élément sélectionné
             - cast (fct) : Permet de caster la clé qui sera transmi dans val()
             - options (dict, list) : clé valeur ou [[cle, valeur]]
    */
    constructor(props){
        super(props)
        this.state = {
            selected : props.selected || props.value || Object.keys(props.options)[0],
        }
    }

    cast(x){
        if(this.props.cast) return this.props.cast(x)
        return x
    }

    val(){
        return this.cast(this.state.selected);
    }

    on_change(evt){
        this.setState({selected: evt.target.value})
        this.props.onChange && this.props.onChange(this.cast(evt.target.value))
    }

    render(){
        var options=[];
        for (const [key, value] of Object.entries(this.props.options)) {
            var sel = this.state.selected == key
            options.push(<option value={key}  key={key}>{value}</option>)
        }
        var multiple = this.props.multiple?"multiple":null
        return (
            <select value={this.state.selected} className={"form-select "+this.props.className} onChange={this.on_change.bind(this)} >
                {options}
            </select>
        )
    }
}


var _MultipleSelect_counter=0;

class MultipleSelect extends Component {
    /*
        props:
             - containerClassName (str) : classe de l'ensemble
             - className (str) : classe du bouton d'ouverture
             - selected (list[str]): liste des clés selectionnés
             - label (str): Text du bouton d'ouverture
             - nb_show (int): nombre de résultat à afficher dans le bouton d'ouverture
    */
    constructor(props){
        super(props);
        var values = this.props.selected || this.props.value || []
        if(!Array.isArray(values)) values=[]

        var selected = {}
        Object.keys(this.props.options || {}).map(x => selected[x]=values.indexOf(x)>=0)

        this.state = {
            selected: selected,
        }
        this.ms_id = ++_MultipleSelect_counter;
    }

    _update_selecteds(){
        var values = this.props.selected || this.props.value || []
        if(!Array.isArray(values)) values=[]
        Object.keys(this.props.options || {}).map(x => this.state.selected[x]=(values).map(y => ""+y).indexOf(x)>=0)
    }

    val(){
        return Object.keys(this.state.selected).filter(x => this.state.selected[x] )
    }

    _on_change(key, evt){
        this.state.selected[key] = evt;
        if(this.props.onChange)
            this.props.onChange(this.val())

        this.setState({})
    }

    _select_all(){

    }

    _unselect_all(){

    }

    selected_text(){
        var ret = this.props.label?this.props.label:"";
        if(ret.length) ret += " : "

        var nb_max = this.props.nb_show==undefined?3:this.props.nb_show;
        var arr = this.val().map(x => this.props.options[x])
        var nb = arr.length;
        if(!nb_max){
            ret+=nb+" choix"
        }
        else if(nb>nb_max){
            ret+=nb+" choix : "+arr.slice(0, nb_max).join(",")+",..."
        }
        else{
            ret+=nb+" choix : "+arr.join(",")
        }
        return ret;
    }

    render(){
        var options=[];
        var self = this;
        this._update_selecteds()
        for (const [key, value] of Object.entries(this.props.options)) {
            var sel = this.state.selected[key];
            var fct = function(evt){self._on_change(key,evt.target.checked) }
            options.push(<li key={key}>
                <input className="form-check-input multiple-select-input" type="checkbox" checked={sel} onChange={fct} id={"_ms_"+this.ms_id+"_"+key} />
                <label className="form-check-label" htmlFor={"_ms_"+this.ms_id+"_"+key} >{value}</label>
            </li>)
        }

        return (<div className={"dropdown "+this.props.containerClassName}>
              <button className={"btn dropdown-toggle "+this.props.className} type="button" id={"dropdownMenuButton"+this.ms_id} data-bs-toggle="dropdown" aria-expanded="false">
                {this.selected_text()}
              </button>
              <ul className="dropdown-menu" aria-labelledby={"dropdownMenuButton"+this.ms_id}>
                    {options}
              </ul>
        </div>)
    }

}

export {
    Select, MultipleSelect
}
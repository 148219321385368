import { NavBar } from "banque/pages/nav";
import { Context } from "context";
import { Component } from "react";
import ReactModal from 'react-modal';
import {ModalTransparent, Error, Validation} from "widget/modal/modal"


import "./css/app_common.css"
var Modal = ReactModal;
var _before_init=[]

var chars = Array.from("abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPRQSTUVWXYZ01234567899");
function get_id(n=32){
    var ret =[]
    for(var i=0; i<n; i++) ret.push(chars[parseInt(Math.random()*(chars.length-1))])
    return ret.join("")
}


class ModalEntry {
    constructor(data, fct){
        this.data = data;
        this.id = get_id();
        this._callback = null;
        this._classe = null;
        this._callback = fct;
        this.is_open = true;
    }

    render(){
        return this._callback(this.data, this.id, this.close.bind(this));
    }

    show(data){
        if(data !== undefined){
            this.data = data
        }
        window.AppManager.show_modal(this.id);
    }

    close(){
        window.AppManager.close_modal(this.id);
    }
    
}


class AppCommon extends Component {
    constructor(props){
        super(props)
        this.state = {
            modal: {
                
            },
            pages : {

            },
            current_page: null
        }
        window.AppManager  = this;
        for(var entry of _before_init){
            if(entry.type == "modal"){
                this.register_modal(entry.data.name, entry.data.classe)
            }
            else if(entry.type == "function"){
                entry.data();
            }
            else if(entry.type == "page"){
                this.state.pages[entry.data.name] = entry.data.data;
            }
        }
        this.state.current_page = Context.page
        
    }
    
    show_modal(entry){
        entry.is_open = true;
        this.state.modal[entry.id] = entry
        this.setState({})
        return entry
    }

    close_modal(name){
        this.state.modal[name].is_open = false;
        delete this.state.modal[name]
        this.setState({})
    }

    call(name, data){
        return this["show_modal_"+name](data);
    }

    register_modal(name, classe){
        var fct = null;
        if(typeof classe === "function"){
            fct = ((data) => this.show_modal(new ModalEntry(data, classe)))
        }else{
            fct = ((data) => this.show_modal(new classe(data)))
        }
        this["show_modal_"+name]=fct;
        window["show_modal_"+name]=fct;
        return fct;
    }

    register_page(name, data){
        this.state.pages[name] = data;
    }


    
    render(){
        var data = [], content = null, options = null;
        var args = {}
        for(var [key, value] of Object.entries(this.state.modal)){  
            if(value.is_open){
                data.push(<div key={"____"+value.id}> {value.render(value.data)}</div>);
            }
        }
        if(Context.page in this.state.pages){
            var page =  this.state.pages[Context.page];
            if(typeof page.page === "function"){
                content = page.page();
            }else{
                var Elem = page.page;
                content = <Elem />
            }
            if(page.options){
                args.options = page.options;
            }
            options = this.state.pages[Context.page].options || null;
        } else {
            var pages = Object.keys(this.state.pages).map( x => (<li key={x}>{x}</li>));

            content = (<div>
                <h1>Erreur le context '{Context.page}' est inconnu</h1>
                <div>
                    {pages}
                </div>
            </div>)
        }
        return (<>
            <NavBar {...args} parent={this}/><div>
            {data}
            {content}
        </div>
        </>)
    }
}

function modal_register(name, classe){
    if(window.AppManager){
        window.AppManager.register_modal(name, classe)
    }
    else{
        _before_init.push({type: "modal", data: {name:name, classe:classe}})
    }
}

function page_register(name, data){
    if(window.AppManager){
        window.AppManager.register_page(name, data)
    }
    else{
        _before_init.push({type: "page", data: {name:name, data:data}})
    }
}



function on_load(fct){
    if(window.AppManager){
        fct()
    }
    else{
        _before_init.push({type: "function", data: fct})
    }
}

class ModalLoading extends ModalEntry {

    render(data){
        return (
            <ModalTransparent  isOpen={true} key={"loading"}>
                <div className="modal-header">
                    <h5>Chargement</h5>
                </div>
                <div className="modal-body">
                    <p>{data.text}</p>
                </div>
            </ModalTransparent>
        )
    }

}

var _ModalLoading = new ModalLoading();

function loading(text){
    if(text == false){
        _ModalLoading.close();
    }
    else{
        _ModalLoading.data = {txt: text}
        window.AppManager.show_modal(_ModalLoading)
    }
}

modal_register("error", function(data, id, close){
    var unescape = null;
    if(data.unescaped){
        unescape = <div dangerouslySetInnerHTML={{__html: data.unescaped}} ></div>
    }
    return (<Error title={data.title} isOpen={true} onClose={close} key={id}>
        {data.content || null}
        {unescape}
    </Error>)
})


modal_register("validation", function(data, id, close){
    var cancel = function(){close(); data.onCancel && data.onCancel()}
    var validate = function(){close(); data.onValidate && data.onValidate()}
    var unescape = null;
    if(data.unescaped){
        unescape = <div dangerouslySetInnerHTML={{__html: data.unescaped}} ></div>
    }
    return (<Validation title={data.title} 
                        isOpen={true} 
                        onClose={close} 
                        key={id}
                        onCancel={cancel}
                        onValidate={validate}>
        {data.content || null}
        {unescape}
    </Validation>)
})


window.loading = loading
var show_error =  function(title, content){
    window.show_modal_error({
        title: title,
        content: content
    })
}

var show_error_unescaped =  function(title, content){
    window.show_modal_error({
        title: title,
        unescaped: content
    })
}

var show_validation =  function(title, content, on_ok, on_cancel){
    window.show_modal_validation({
        title: title,
        content: content,
        onValidate: on_ok,
        onCancel: on_cancel
    })
}


var show_validation_unescaped =  function(title, content, on_ok, on_cancel){
    window.show_modal_validation({
        title: title,
        unescaped: content,
        onValidate: on_ok,
        onCancel: on_cancel
    })
}


window.print_error = show_error
window.validation = show_validation

export {AppCommon, modal_register, loading, show_error,
    show_error_unescaped, show_validation, show_validation_unescaped, on_load, page_register}
import React, { Component } from "react";
import './css/form.css';
import { Integer } from "./entry";




class Form extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var content = [];
    var arr = this.props.content || [];
    for(var i=0; i<arr.length; i++){
        var val = arr[i];
        var obj={};
        if(Array.isArray(val)){
            obj.name = val[0];
            obj.content = val[1];
            obj.label = (arr.length>2)?val[2]:obj.name;
            obj.visible = (arr.length>3)?val[3]:true;
        }else{
            obj.name = val.name;
            obj.content = val.field;
            obj.label = val.label || arr.name;
            obj.visible = val.visible==false?false:true;
        }
        content.push(<Field {...obj} key={i}/>)
    }

    return (<form className={this.props.className}> {content} {this.props.children} </form>)
  }
}


class Field extends Component {

  constructor(props) {
    super(props);
    /*
        props : {
            name: "description",
            label: "Description",
            content: ...
        }
    */
    this.state = {
        value: ""
    };
  }

  render() {
    var label = this.props.label || this.props.name;
    return (<div className={"mb-3   "+this.props.className+" "+(this.props.visible==false?"hidden ":"")}>
        <div className="col-auto field-label">
            <label htmlFor={this.props.name} className="col-form-label">{label}</label>
        </div>
        <div className="col-auto">
            {this.props.content}{this.props.children}
        </div>
     </div>);
  }
}


class ManagedForm extends Component {
  constructor(props){
    super(props);
    this.state = {
      value: props.value || {}
    }
    this.fields = {

    }
  }

  _on_change(key, value){
    this.state.value[key] = this.fields[key].cast(value);
    this.setState({})
  }

  _field(name, Type, data){
    data = Object.assign({
      onChange: (...args) => this._on_change(name, ...args), 
      onValidate: (...args) => this._on_change(name, ...args),
      cast: x => x,
      label : "",
      default: ""
    }, data)

    const {label, ...childProps} = data;
    return <div className="managed-field">
      <span className="managed-field-label">{label}</span>
      <span className="managed-field-content"><Type {...childProps} value={this.state}  /></span>
    </div>
  }

  on_change(x){
  }

  render(){
    this.props.children.my_parent = this
    return (<form>
      {this.props.children}
    </form>)
  }
}

class Test extends Component {
  constructor(props){
    super(props)
  }

  render(){
    return <ManagedForm>
      <Integer></Integer>
    </ManagedForm>
  }
}

export {Form, Field, Test};
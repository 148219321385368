import React, { Component } from "react";



class AccountThumbnail extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (<div className="account-thumbnail">
                <span className="account-thumbnail-name"><a href={"/account/"+this.props.id+"/operations"}>{this.props.name}</a></span>
                <span className="account-thumbnail-solde">{this.props.solde} €</span>
           </div>)
  }
}

export {
    AccountThumbnail
};

import { ListManager } from 'model/manager';




class ContextInfo {
    constructor(){
    }

    set(content){
        Object.assign(this, content);
        this.is_mobile = this.user.device.mobile;
        this.user_id = this.user.id;
        this.user_name = this.user.name;
        this.preferences = this.user.preferences;
        this.profile = this.user.profile;

        for(var k in this.data){
            if(Array.isArray(this.data[k])){
                this.data[k] = ListManager.get_manager(k, this.data[k])
            }
        }
        
    }
}
var Context = new ContextInfo(null);

export {Context};


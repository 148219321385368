import { Info } from "./info";
import { InfoRestant } from "./restant";
import { InfoObjectif } from "./objectif";


const numeric_infos = Info.get_info_labels()

const numeric_infos_default = Object.keys(Info.get_info_labels()).sort()[0];

export {
    numeric_infos,
    numeric_infos_default,
}
import { Component } from "react";
import "./options.css"
import ReactModal from 'react-modal';
import { AutoOptions } from "widget/autorenderer";
import { Context } from "context";
import { Api } from "banque/api/api";

var Modal = ReactModal;

class PageOption extends Component {
    constructor(props){
        super(props)
        this.state = {
            value: props.value || {},
            is_open: false
        }
        this.options = new AutoOptions(this.props.options);
    }

    _close(){
        this.state.is_open = false;
        this.setState({})
    }

    _open(){
        this.state.is_open = true;
        this.setState({})
    }

    _on_change(key, value){
        Object.assign(this.state.value, value);
        Context.user.preferences[key] = value;
        Api.update_preferences(Context.user.profile, {[key]: value})
        this.setState({})
        this.props.app.setState({})
    }

    _render_modal(){
        if(!this.state.is_open) return null;
        return (<Modal 
            isOpen={true}
            onClose={this._close.bind(this)} className="page-options-modal" >
                <div >
                    <div className="modal-header">
                        <h5 className="modal-title">Options</h5>
                        <button type="button" className="btn-close" onClick={this._close.bind(this)}></button>
                    </div>
                    <div className="page-options-autooptions-root">
                        {this.options.render(this.state.value, null, this._on_change.bind(this))}
                    </div>
                </div>
        </Modal>)
    }

    render(){
        return (<span>
            <a className="page-options-button" onClick={this._open.bind(this)}><i className="bi bi-gear"></i></a>
            {this._render_modal()}
        </span>)
    }   
}



export {
    PageOption
}